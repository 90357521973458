import React, { useEffect } from "react";
import { Button, Flex } from "theme-ui";

import {
  Decimal,
  Decimalish,
  LiquidLoansStoreState,
  LOANStake,
  LOANStakeChange,
} from "@liquidloans/lib-base";

import {
  LiquidLoansStoreUpdate,
  useLiquidLoansReducer,
  useLiquidLoansSelector,
} from "@liquidloans/lib-react";

import { GT, COIN } from "../../strings";

import { useStakingView } from "./context/StakingViewContext";
import { StakingEditor } from "./StakingEditor";
import { StakingManagerAction } from "./StakingManagerAction";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useMyTransactionState } from "../Transaction";
import { BeansUnlockButton } from "./NoStake";

const selector = ({ beansAllowance }: any) => ({
  beansAllowance,
});

const init = ({ loanStake }: LiquidLoansStoreState) => ({
  originalStake: loanStake,
  editedLOAN: loanStake.stakedLOAN,
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquidLoansStoreUpdate
  | { type: "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduce = (
  state: StakeManagerState,
  action: StakeManagerAction
): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedLOAN } = state;

  switch (action.type) {
    case "setStake":
      return { ...state, editedLOAN: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedLOAN: originalStake.stakedLOAN };

    case "updateStore": {
      const {
        stateChange: { loanStake: updatedStake },
      } = action;

      if (updatedStake) {
        return {
          originalStake: updatedStake,
          editedLOAN: updatedStake.apply(originalStake.whatChanged(editedLOAN)),
        };
      }
    }
  }

  return state;
};

const selectLOANBalance = ({
  loanBalance,
  loanStake,
}: LiquidLoansStoreState) => ({ loanBalance, loanStake });

type StakingManagerActionDescriptionProps = {
  originalStake: LOANStake;
  change: LOANStakeChange<Decimal>;
};

const StakingManagerActionDescription: React.FC<
  StakingManagerActionDescriptionProps
> = ({ originalStake, change }) => {
  const { loanBalance, loanStake } = useLiquidLoansSelector(selectLOANBalance);
  const stakeLOAN = change.stakeLOAN?.prettify().concat(" ", GT);
  const unstakeLOAN = change.unstakeLOAN?.prettify().concat(" ", GT);
  const collateralGain = loanStake.collateralGain.nonZero
    ?.prettify(2)
    .concat(" PLS");
  const beanGain = loanStake.beanGain.nonZero
    ?.prettify(2)
    .concat(" BEAN");
  const usdlGain = loanStake.usdlGain.nonZero?.prettify().concat(" ", COIN);

  if (loanStake.isEmpty && stakeLOAN) {
    return (
      <ActionDescription>
        You are staking <Amount>{stakeLOAN}</Amount>.
      </ActionDescription>
    );
  }

  return (
    <ActionDescription>
      {stakeLOAN && (
        <>
          You are adding <Amount>{stakeLOAN}</Amount> to your stake
        </>
      )}

      {unstakeLOAN && (
        <>
          You are withdrawing <Amount>{unstakeLOAN}</Amount> to your wallet
        </>
      )}
      {(collateralGain || usdlGain) && (
        <>
          {" "}
          and claiming{" "}
          {collateralGain && usdlGain ? (
            <>
              <Amount>{collateralGain}</Amount>, <Amount>{usdlGain}</Amount> and <Amount>{beanGain}</Amount>
            </>
          ) : (
            <>
              <Amount>{collateralGain ?? usdlGain}</Amount>
            </>
          )}
        </>
      )}
      .
    </ActionDescription>
  );
};

type StakingManagerProps = {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
};

export const StakingManager: React.FC<StakingManagerProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { dispatch: dispatchStakingViewAction } = useStakingView();
  const [{ originalStake, editedLOAN }, dispatch] = useLiquidLoansReducer(
    reduce,
    init
  );
  const { loanBalance, loanStake } = useLiquidLoansSelector(selectLOANBalance);
  const { beansAllowance } = useLiquidLoansSelector(selector);
  const beansAllowance1 = Number(beansAllowance) / 1e18;

  const change = loanStake.whatChanged(editedLOAN);
  const [validChange, description] = !change
    ? [undefined, undefined]
    : change.stakeLOAN?.gt(loanBalance)
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your balance by{" "}
          <Amount>
            {change.stakeLOAN.sub(loanBalance).prettify()} {GT}
          </Amount>
          .
        </ErrorDescription>,
      ]
    : change.stakeLOAN?.gt(beansAllowance1) // Add this condition
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your allowance by{" "}
          <Amount>
            {change.stakeLOAN.sub(beansAllowance1).prettify()} {GT} 
          </Amount>
          <BeansUnlockButton>
            {" Approve LOAN Token."}
          </BeansUnlockButton>
          .
        </ErrorDescription>,
      ]
    : [
        change,
        <StakingManagerActionDescription
          originalStake={loanStake}
          change={change}
        />,
      ];

  const makingNewStake = loanStake.isEmpty;

  return (
    <StakingEditor
      title={"Staking"}
      {...{ originalStake, editedLOAN, dispatch }}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      description={description}
    >
      {/* {description ??
        (makingNewStake ? (
          <ActionDescription>
            Enter the amount of {GT} you'd like to stake.
          </ActionDescription>
        ) : (
          <ActionDescription>
            Adjust the {GT} amount to stake or withdraw.
          </ActionDescription>
        ))} */}

      <Flex variant="layout.actions">
        {/* <Button
          variant="cancel"
          onClick={() => dispatchStakingViewAction({ type: "cancelAdjusting" })}
        >
          Cancel
        </Button> */}

        {validChange ? (
          <StakingManagerAction change={validChange}>
            Confirm
          </StakingManagerAction>
        ) : (
          <Button disabled>Confirm</Button>
        )}
      </Flex>
    </StakingEditor>
  );
};
