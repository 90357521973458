import React, { Fragment, useEffect, useState } from "react";
import { Text, Flex, Box, Heading, Image, useThemeUI } from "theme-ui";
import { ethers } from "ethers";

import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { COIN, GT } from "../strings";
import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import { shortenAddress } from "../utils/shortenAddress";
import LOANIcon from "../assets/images/loan-icon.svg";
import USDLIcon from "../assets/images/usdl-icon.svg";
import PLSIcon from "../assets/images/pls-icon.svg";
import BEANIcon from "../assets/images/bean-token.png";
import { LuArrowUpRight, LuArrowDownLeft } from "react-icons/lu";

import { Icon } from "./Icon";
import { fetchAllPriceData } from "../graphFunctions/graphFetcher";

const barista_colors = {
  barista_red: "#e80368",
  barista_blue: "#5578ff",
  barista_yellow: "#ffbb2c",
  barista_green: "#29cc61",
};

const getLogo = (token: string) => {
  switch (token) {
    case "LOAN":
      return LOANIcon;

    case "PLS":
      return PLSIcon;

    case "USDL":
      return USDLIcon;

    case "BEAN":
      return BEANIcon;

    default:
      return USDLIcon;
  }
};

const select = ({
  accountBalance,
  usdlBalance,
  loanBalance,
}: LiquidLoansStoreState) => ({
  accountBalance,
  usdlBalance,
  loanBalance,
});
const contractAddress = "0xd7407BD3E6aD1BAAE0ba9eaFD1Ec41bFE63907B2";

export const TokenBalance = () => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    // Replace with your contract address and ABI
    // const contractAddress = "0xd7407BD3E6aD1BAAE0ba9eaFD1Ec41bFE63907B2";
    const contractABI = [
      // ABI for ERC20 token balanceOf function
      {
        constant: true,
        inputs: [{ name: "owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        type: "function",
      },
    ];

    // Initialize ethers.js with the user's Ethereum provider (e.g., MetaMask)
    async function fetchTokenBalance() {
      try {
        // Check if Ethereum provider is available
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();

          // Create a contract instance
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );

          // Call the balanceOf function to get the token balance
          const result = await contract.balanceOf(userAddress);
          setBalance(result.toString());
        } else {
          console.log(
            "Please install MetaMask or another Ethereum wallet extension."
          );
        }
      } catch (error) {
        console.error("Error fetching token balance:", error);
      }
    }

    fetchTokenBalance();
  }, []);
};

export const UserTokenBalances: React.FC = () => {
  const { account } = useLiquidLoans();
  const { accountBalance, usdlBalance, loanBalance } =
    useLiquidLoansSelector(select);
  const theme = useThemeUI();
  const [priceData, setPriceData] = useState({
    plsPrice: 0,
    plsChange: 0,
    loanPrice: 0,
    usdlPrice: 0,
    loanChange: 0,
    usdlChange: 0,
  });

  useEffect(() => {
    fetchBalances();
  }, []);

  const fetchBalances = async () => {
    let response = await fetchAllPriceData();
    if (response) {
      setPriceData({ ...response });
    }
  };

  const [beanBalance, setBeanBalance] = useState(0);

  useEffect(() => {
    const contractABI = [
      {
        constant: true,
        inputs: [{ name: "owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        type: "function",
      },
    ];

    // Initialize ethers.js with the user's Ethereum provider (e.g., MetaMask)
    async function fetchTokenBalance() {
      try {
        // Check if Ethereum provider is available
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();

          // Create a contract instance
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );

          // Call the balanceOf function to get the token balance
          const result = await contract.balanceOf(userAddress);
          setBeanBalance(result.toString());
        } else {
          console.log(
            "Please install MetaMask or another Ethereum wallet extension."
          );
        }
      } catch (error) {
        console.error("Error fetching token balance:", error);
      }
    }

    fetchTokenBalance();
  });

  const [renderCount, setRenderCount] = useState(0);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderCount(prevCount => prevCount + 1);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: ["auto", "hidden", null, null],
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          gap: 3,
          width: ["fit-content", "100%", null, null],
          margin: ["auto", "auto", null, null],
          justifyContent: ["space-around", "center", "center", "space-around"],
          overflowX: ["auto", "hidden", null, null],
        }}
      >
        {/* <Icon name="wallet" size="lg" /> */}

        {(
          [
            [
              GT,
              loanBalance,
              priceData.loanPrice,
              priceData.loanChange,
              "0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B",
              "tokens/loan-icon.svg",
            ],
            [
              COIN,
              usdlBalance,
              priceData.usdlPrice,
              priceData.usdlChange,
              "0x0dEEd1486bc52aA0d3E6f8849cEC5adD6598A162",
              "tokens/usdl-icon.svg",
            ],
            [
              "PLS",
              accountBalance,
              priceData.plsPrice,
              priceData.plsChange,
              "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
              "tokens/pls-icon.svg",
            ],
            [
              "BEAN",
              Number(beanBalance) / 1e18,
              Number(beanBalance) / 1e18,
              0,
              contractAddress,
              "tokens/bean-token.png",
            ],
          ] as const
        ).map(([currency, balance, price, change, address, imageUrl], i) => (
          <Fragment key={i}>
            <Flex
              sx={{ cursor: "pointer", width: "130px", minWidth: "130px" }}
              onClick={() => {
                if (currency !== "PLS") {
                  addTokenToWallet(currency, address, imageUrl);
                }
              }}
            >
              <Image
                src={getLogo(currency)}
                alt={currency}
                height={30}
                width={35}
              />
              <Flex sx={{ ml: 1, flexDirection: "column" }}>
                <Flex sx={{ gap: "5px", alignItems: "center" }}>
                  <Heading sx={{ fontSize: "20px" }}>{currency}</Heading>
                  {currency !== "BEAN" && (
                    <Flex sx={{ alignItems: "center" }}>
                      <Text
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Titillium Web, sans-serif",
                          fontWeight: 600,
                          // color: barista_colors.barista_green,
                          color:
                            change < 0
                              ? barista_colors.barista_red
                              : change > 0
                              ? barista_colors.barista_green
                              : "grey",
                        }}
                      >
                        {` ${Math.abs(change * 100).toFixed(2)}%`}
                      </Text>
                      {change < 0 ? (
                        <LuArrowDownLeft
                          fontSize="12px"
                          color={barista_colors.barista_red}
                        />
                      ) : change > 0 ? (
                        <LuArrowUpRight
                          fontSize="12px"
                          color={barista_colors.barista_green}
                        />
                      ) : null}
                      {/* <LuArrowUpRight
                      fontSize="12px"
                      color={barista_colors.barista_green}
                    /> */}
                    </Flex>
                  )}
                </Flex>
                <Text
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Titillium Web, sans-serif",
                    color: "grey",
                    fontWeight: 600,
                  }}
                >
                  {currency != "BEAN" && `$`}
                  {currency != "BEAN" ? price.toFixed(6) : price.toFixed(0)}
                </Text>
              </Flex>
            </Flex>
            {i < 3 && (
              <Box
                sx={{
                  minHeight: "40px",
                  width: "1px",
                  background: "grey",
                  ml: 2,
                  display: ["none", "flex", null, null],
                }}
              />
            )}
          </Fragment>
        ))}
      </Flex>
    </Box>
  );
};

// add token to wallet
const addTokenToWallet = async (
  tokenSymbol: string,
  tokenAddress: string,
  tokenImage: string
) => {
  if (window.ethereum) {
    try {
      const { ethereum } = window as any;
      let url = "";

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        url = "http://localhost:3000/";
      } else {
        url = "https://baristabackend/";
      }

      if (tokenSymbol === 'BEAN') {
        tokenSymbol = "BEAN by Barista"
      }

      await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: 18,
            image: url + tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};
