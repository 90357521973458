import {
  Decimal,
  USDL_MINIMUM_DEBT,
  USDL_MINIMUM_NET_DEBT,
  Vault,
  VaultAdjustmentParams,
  VaultChange,
  Percent,
  MINIMUM_COLLATERAL_RATIO,
  CRITICAL_COLLATERAL_RATIO,
  LiquidLoansStoreState,
  VaultClosureParams,
  VaultCreationParams,
} from "@liquidloans/lib-base";

import { COIN } from "../../../strings";

import { ActionDescription, Amount } from "../../ActionDescription";
import { ErrorDescription } from "../../ErrorDescription";

const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);
const ccrPercent = new Percent(CRITICAL_COLLATERAL_RATIO).toString(0);

type VaultAdjustmentDescriptionParams = {
  params: VaultAdjustmentParams<Decimal>;
};

const VaultChangeDescription: React.FC<VaultAdjustmentDescriptionParams> = ({
  params,
}) => (
  <ActionDescription>
    {params.depositCollateral && params.borrowUSDL ? (
      <>
        You will deposit{" "}
        <Amount>{params.depositCollateral.prettify()} PLS</Amount> and receive{" "}
        <Amount>
          {params.borrowUSDL.prettify()} {COIN}
        </Amount>
      </>
    ) : params.repayUSDL && params.withdrawCollateral ? (
      <>
        You will pay{" "}
        <Amount>
          {params.repayUSDL.prettify()} {COIN}
        </Amount>{" "}
        and receive <Amount>{params.withdrawCollateral.prettify()} PLS</Amount>
      </>
    ) : params.depositCollateral && params.repayUSDL ? (
      <>
        You will deposit{" "}
        <Amount>{params.depositCollateral.prettify()} PLS</Amount> and pay{" "}
        <Amount>
          {params.repayUSDL.prettify()} {COIN}
        </Amount>
      </>
    ) : params.borrowUSDL && params.withdrawCollateral ? (
      <>
        You will receive{" "}
        <Amount>{params.withdrawCollateral.prettify()} PLS</Amount> and{" "}
        <Amount>
          {params.borrowUSDL.prettify()} {COIN}
        </Amount>
      </>
    ) : params.depositCollateral ? (
      <>
        You will deposit{" "}
        <Amount>{params.depositCollateral.prettify()} PLS</Amount>
      </>
    ) : params.withdrawCollateral ? (
      <>
        You will receive{" "}
        <Amount>{params.withdrawCollateral.prettify()} PLS</Amount>
      </>
    ) : params.borrowUSDL ? (
      <>
        You will receive{" "}
        <Amount>
          {params.borrowUSDL.prettify()} {COIN}
        </Amount>
      </>
    ) : (
      <>
        You will pay{" "}
        <Amount>
          {params.repayUSDL.prettify()} {COIN}
        </Amount>
      </>
    )}
    .
  </ActionDescription>
);

export const selectForVaultChangeValidation = ({
  price,
  total,
  accountBalance,
  usdlBalance,
  numberOfVaults,
}: LiquidLoansStoreState) => ({
  price,
  total,
  accountBalance,
  usdlBalance,
  numberOfVaults,
});

type VaultChangeValidationSelectedState = ReturnType<
  typeof selectForVaultChangeValidation
>;

interface VaultChangeValidationContext
  extends VaultChangeValidationSelectedState {
  originalVault: Vault;
  resultingVault: Vault;
  recoveryMode: boolean;
  wouldTriggerRecoveryMode: boolean;
}

export const validateVaultChange = (
  originalVault: Vault,
  adjustedVault: Vault,
  borrowingRate: Decimal,
  selectedState: VaultChangeValidationSelectedState
): [
  validChange:
    | Exclude<VaultChange<Decimal>, { type: "invalidCreation" }>
    | undefined,
  description: JSX.Element | undefined
] => {
  const { total, price } = selectedState;
  const change = originalVault.whatChanged(adjustedVault, borrowingRate);

  if (!change) {
    return [undefined, undefined];
  }

  // Reapply change to get the exact state the Vault will end up in (which could be slightly
  // different from `edited` due to imprecision).
  const resultingVault = originalVault.apply(change, borrowingRate);
  const recoveryMode = total.collateralRatioIsBelowCritical(price);
  const wouldTriggerRecoveryMode = total
    .subtract(originalVault)
    .add(resultingVault)
    .collateralRatioIsBelowCritical(price);

  const context: VaultChangeValidationContext = {
    ...selectedState,
    originalVault,
    resultingVault,
    recoveryMode,
    wouldTriggerRecoveryMode,
  };

  if (change.type === "invalidCreation") {
    // Trying to create a Vault with negative net debt
    return [
      undefined,
      <ErrorDescription>
        Total debt must be at least{" "}
        <Amount>
          {USDL_MINIMUM_DEBT.toString()} {COIN}
        </Amount>
        .
      </ErrorDescription>,
    ];
  }

  const errorDescription =
    change.type === "creation"
      ? validateVaultCreation(change.params, context)
      : change.type === "closure"
      ? validateVaultClosure(change.params, context)
      : validateVaultAdjustment(change.params, context);

  if (errorDescription) {
    return [undefined, errorDescription];
  }

  return [change, <VaultChangeDescription params={change.params} />];
};

const validateVaultCreation = (
  { depositCollateral, borrowUSDL }: VaultCreationParams<Decimal>,
  {
    resultingVault,
    recoveryMode,
    wouldTriggerRecoveryMode,
    accountBalance,
    price,
  }: VaultChangeValidationContext
): JSX.Element | null => {
  if (borrowUSDL.lt(USDL_MINIMUM_NET_DEBT)) {
    return (
      <ErrorDescription>
        You must borrow at least{" "}
        <Amount>
          {USDL_MINIMUM_NET_DEBT.toString()} {COIN}
        </Amount>
        .
      </ErrorDescription>
    );
  }

  if (recoveryMode) {
    if (!resultingVault.isOpenableInRecoveryMode(price)) {
      return (
        <ErrorDescription>
          You're not allowed to open a Vault with less than{" "}
          <Amount>{ccrPercent}</Amount> Collateral Ratio during recovery mode.
          Please increase your Vault's Collateral Ratio.
        </ErrorDescription>
      );
    }
  } else {
    if (resultingVault.collateralRatioIsBelowMinimum(price)) {
      return (
        <ErrorDescription>
          Collateral ratio must be at least <Amount>{mcrPercent}</Amount>.
        </ErrorDescription>
      );
    }

    if (wouldTriggerRecoveryMode) {
      return (
        <ErrorDescription>
          You're not allowed to open a Vault that would cause the Total
          Collateral Ratio to fall below <Amount>{ccrPercent}</Amount>. Please
          increase your Vault's Collateral Ratio.
        </ErrorDescription>
      );
    }
  }

  if (depositCollateral.gt(accountBalance)) {
    return (
      <ErrorDescription>
        The amount you're trying to deposit exceeds your balance by{" "}
        <Amount>{depositCollateral.sub(accountBalance).prettify()} PLS</Amount>.
      </ErrorDescription>
    );
  }

  return null;
};

const validateVaultAdjustment = (
  {
    depositCollateral,
    withdrawCollateral,
    borrowUSDL,
    repayUSDL,
  }: VaultAdjustmentParams<Decimal>,
  {
    originalVault,
    resultingVault,
    recoveryMode,
    wouldTriggerRecoveryMode,
    price,
    accountBalance,
    usdlBalance,
  }: VaultChangeValidationContext
): JSX.Element | null => {
  if (recoveryMode) {
    if (withdrawCollateral) {
      return (
        <ErrorDescription>
          You're not allowed to withdraw collateral during recovery mode.
        </ErrorDescription>
      );
    }

    if (borrowUSDL) {
      if (resultingVault.collateralRatioIsBelowCritical(price)) {
        return (
          <ErrorDescription>
            Your collateral ratio must be at least <Amount>{ccrPercent}</Amount>{" "}
            to borrow during recovery mode. Please improve your collateral
            ratio.
          </ErrorDescription>
        );
      }

      if (
        resultingVault
          .collateralRatio(price)
          .lt(originalVault.collateralRatio(price))
      ) {
        return (
          <ErrorDescription>
            You're not allowed to decrease your collateral ratio during recovery
            mode.
          </ErrorDescription>
        );
      }
    }
  } else {
    if (resultingVault.collateralRatioIsBelowMinimum(price)) {
      return (
        <ErrorDescription>
          Collateral ratio must be at least <Amount>{mcrPercent}</Amount>.
        </ErrorDescription>
      );
    }

    if (wouldTriggerRecoveryMode) {
      return (
        <ErrorDescription>
          The adjustment you're trying to make would cause the Total Collateral
          Ratio to fall below <Amount>{ccrPercent}</Amount>. Please increase
          your Vault's Collateral Ratio.
        </ErrorDescription>
      );
    }
  }

  if (repayUSDL) {
    if (resultingVault.debt.lt(USDL_MINIMUM_DEBT)) {
      return (
        <ErrorDescription>
          Total debt must be at least{" "}
          <Amount>
            {USDL_MINIMUM_DEBT.toString()} {COIN}
          </Amount>
          .
        </ErrorDescription>
      );
    }

    if (repayUSDL.gt(usdlBalance)) {
      return (
        <ErrorDescription>
          The amount you're trying to repay exceeds your balance by{" "}
          <Amount>
            {repayUSDL.sub(usdlBalance).prettify()} {COIN}
          </Amount>
          .
        </ErrorDescription>
      );
    }
  }

  if (depositCollateral?.gt(accountBalance)) {
    return (
      <ErrorDescription>
        The amount you're trying to deposit exceeds your balance by{" "}
        <Amount>{depositCollateral.sub(accountBalance).prettify()} PLS</Amount>.
      </ErrorDescription>
    );
  }

  return null;
};

const validateVaultClosure = (
  { repayUSDL }: VaultClosureParams<Decimal>,
  {
    recoveryMode,
    wouldTriggerRecoveryMode,
    numberOfVaults,
    usdlBalance,
  }: VaultChangeValidationContext
): JSX.Element | null => {
  if (numberOfVaults === 1) {
    return (
      <ErrorDescription>
        You're not allowed to close your Vault when there are no other Vaults in
        the system.
      </ErrorDescription>
    );
  }

  if (recoveryMode) {
    return (
      <ErrorDescription>
        You're not allowed to close your Vault during recovery mode.
      </ErrorDescription>
    );
  }

  if (repayUSDL?.gt(usdlBalance)) {
    return (
      <ErrorDescription>
        You need{" "}
        <Amount>
          {repayUSDL.sub(usdlBalance).prettify()} {COIN}
        </Amount>{" "}
        more to close your Vault.
      </ErrorDescription>
    );
  }

  if (wouldTriggerRecoveryMode) {
    return (
      <ErrorDescription>
        You're not allowed to close a Vault if it would cause the Total
        Collateralization Ratio to fall below <Amount>{ccrPercent}</Amount>.
        Please wait until the Total Collateral Ratio increases.
      </ErrorDescription>
    );
  }

  return null;
};
