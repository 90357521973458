import { Box, Container, Heading } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
// import bgImage from "../assets/images/bg/borrow-usdl.png";
import { Vault } from "../components/Vault/Vault";

export const BorrowUSDL: React.FC = () => (
  <Container
    variant="columns"
    sx={{
      justifyContent: "flex-start",
      backgroundImage: `url("/bg/borrow-usdl.png")`,
    }}
  >
    <Container variant="center">
      <Heading
        sx={{ fontSize: ["28px", null, null, "36px"], px: ["20px", 0, 0, 0] }}
      >
        0% Interest-Free DeFi Liquidity On PulseChain
      </Heading>
      <Box
        sx={{
          height: "2px",
          width: ["86px", "100px", "118px", "128px"],
          mx: ["20px", 0, 0, 0],
          mt: "8px",
          mb: "12px",
          background:
            "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
        }}
      />
      <Vault />
    </Container>

    <Container variant="bottom">
      <SystemStats />
    </Container>
  </Container>
);
