import { Decimal, LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

const selector = ({
  PLSXtokenBalance,
  PLSXtokenAllowance,
  liquidityMiningStake
}: LiquidLoansStoreState) => ({
  PLSXtokenBalance,
  PLSXtokenAllowance,
  liquidityMiningStake
});

type FarmStakeValidation = {
  isValid: boolean;
  hasApproved: boolean;
  hasEnoughPLSXtoken: boolean;
  isWithdrawing: boolean;
  amountChanged: Decimal;
  maximumStake: Decimal;
  hasSetMaximumStake: boolean;
};

export const useValidationState = (amount: Decimal): FarmStakeValidation => {
  const { PLSXtokenBalance, PLSXtokenAllowance, liquidityMiningStake } = useLiquidLoansSelector(selector);
  const isWithdrawing = liquidityMiningStake.gt(amount);
  const amountChanged = isWithdrawing
    ? liquidityMiningStake.sub(amount)
    : Decimal.from(amount).sub(liquidityMiningStake);
  const maximumStake = liquidityMiningStake.add(PLSXtokenBalance);
  const hasSetMaximumStake = amount.eq(maximumStake);

  if (isWithdrawing) {
    return {
      isValid: true,
      hasApproved: true,
      hasEnoughPLSXtoken: true,
      isWithdrawing,
      amountChanged,
      maximumStake,
      hasSetMaximumStake
    };
  }

  const hasApproved = !PLSXtokenAllowance.isZero && PLSXtokenAllowance.gte(amountChanged);
  const hasEnoughPLSXtoken = !PLSXtokenBalance.isZero && PLSXtokenBalance.gte(amountChanged);

  return {
    isValid: hasApproved && hasEnoughPLSXtoken,
    hasApproved,
    hasEnoughPLSXtoken,
    isWithdrawing,
    amountChanged,
    maximumStake,
    hasSetMaximumStake
  };
};
