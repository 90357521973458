import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { LiquidLoansStoreState, UserVaultStatus } from "@liquidloans/lib-base";
import { VaultViewContext } from "./VaultViewContext";
import type { VaultView, VaultEvent } from "./types";

type VaultEventTransitions = Record<
  VaultView,
  Partial<Record<VaultEvent, VaultView>>
>;

const transitions: VaultEventTransitions = {
  NONE: {
    OPEN_VAULT_PRESSED: "OPENING",
    VAULT_OPENED: "ACTIVE",
  },
  LIQUIDATED: {
    OPEN_VAULT_PRESSED: "OPENING",
    VAULT_SURPLUS_COLLATERAL_CLAIMED: "NONE",
    VAULT_OPENED: "ACTIVE",
  },
  REDEEMED: {
    OPEN_VAULT_PRESSED: "OPENING",
    VAULT_SURPLUS_COLLATERAL_CLAIMED: "NONE",
    VAULT_OPENED: "ACTIVE",
  },
  OPENING: {
    CANCEL_ADJUST_VAULT_PRESSED: "NONE",
    VAULT_OPENED: "ACTIVE",
  },
  ADJUSTING: {
    CANCEL_ADJUST_VAULT_PRESSED: "ACTIVE",
    VAULT_ADJUSTED: "ACTIVE",
    VAULT_CLOSED: "NONE",
    VAULT_LIQUIDATED: "LIQUIDATED",
    VAULT_REDEEMED: "REDEEMED",
  },
  CLOSING: {
    CANCEL_ADJUST_VAULT_PRESSED: "ACTIVE",
    VAULT_CLOSED: "NONE",
    VAULT_ADJUSTED: "ACTIVE",
    VAULT_LIQUIDATED: "LIQUIDATED",
    VAULT_REDEEMED: "REDEEMED",
  },
  ACTIVE: {
    ADJUST_VAULT_PRESSED: "ADJUSTING",
    CLOSE_VAULT_PRESSED: "CLOSING",
    VAULT_CLOSED: "NONE",
    VAULT_LIQUIDATED: "LIQUIDATED",
    VAULT_REDEEMED: "REDEEMED",
  },
};

type VaultStateEvents = Partial<Record<UserVaultStatus, VaultEvent>>;

const vaultStatusEvents: VaultStateEvents = {
  open: "VAULT_OPENED",
  closedByOwner: "VAULT_CLOSED",
  closedByLiquidation: "VAULT_LIQUIDATED",
  closedByRedemption: "VAULT_REDEEMED",
};

const transition = (view: VaultView, event: VaultEvent): VaultView => {
  const nextView = transitions[view][event] ?? view;
  return nextView;
};

const getInitialView = (vaultStatus: UserVaultStatus): VaultView => {
  if (vaultStatus === "closedByLiquidation") {
    return "LIQUIDATED";
  }
  if (vaultStatus === "closedByRedemption") {
    return "REDEEMED";
  }
  if (vaultStatus === "open") {
    return "ACTIVE";
  }
  return "NONE";
};

const select = ({ vault: { status } }: LiquidLoansStoreState) => status;

export const VaultViewProvider: React.FC = (props) => {
  const { children } = props;
  const vaultStatus = useLiquidLoansSelector(select);

  const [view, setView] = useState<VaultView>(getInitialView(vaultStatus));
  const viewRef = useRef<VaultView>(view);

  const dispatchEvent = useCallback((event: VaultEvent) => {
    const nextView = transition(viewRef.current, event);

    console.log(
      "dispatchEvent() [current-view, event, next-view]",
      viewRef.current,
      event,
      nextView
    );
    setView(nextView);
  }, []);

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    const event = vaultStatusEvents[vaultStatus] ?? null;
    if (event !== null) {
      dispatchEvent(event);
    }
  }, [vaultStatus, dispatchEvent]);

  const provider = {
    view,
    dispatchEvent,
  };
  return (
    <VaultViewContext.Provider value={provider}>
      {children}
    </VaultViewContext.Provider>
  );
};
