import React from "react";
import { Box, Card, Container, Heading, Link, Paragraph } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
import { Redemption } from "../components/Redemption/Redemption";
import { InfoMessage } from "../components/InfoMessage";
import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import { Icon } from "../components/Icon";
import bgImage from "../assets/images/bg/redemption.png";

const uniLink = (usdlAddress: string) =>
  `https://app.uniswap.org/#/swap?inputCurrency=${usdlAddress}&outputCurrency=PLS`;

export const RedemptionPage: React.FC = () => {
  const {
    liquidLoans: {
      connection: { addresses },
    },
  } = useLiquidLoans();

  return (
    <Container
      variant="columns"
      sx={{
        justifyContent: "flex-start",
        backgroundImage: `url("/bg/redemption.png")`,
      }}
    >
      <Container variant="center">
        <Heading
          sx={{ fontSize: ["28px", null, null, "36px"], px: ["20px", 0, 0, 0] }}
        >
          Exchange 1 USDL Stablecoin For 1 USD Worth Of PLS
        </Heading>
        <Box
          sx={{
            height: "2px",
            width: ["86px", "100px", "118px", "128px"],
            mx: ["20px", 0, 0, 0],
            mt: "8px",
            mb: "12px",
            background:
              "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
          }}
        />
        {/* <Card>
          <Card sx={{ p: [2, 3] }} variant="main_card">
            <InfoMessage title="Bot functionality">
              <Paragraph>
                Redemptions are expected to be carried out by bots when
                arbitrage opportunities emerge.
              </Paragraph>
              <Paragraph sx={{ mt: 2 }}>
                Most of the time you will get a better rate for converting USDL
                to PLS on{" "}
                <Link href={uniLink(addresses["usdlToken"])} target="_blank">
                  PLSX <Icon name="external-link-alt" size="xs" />
                </Link>{" "}
                or other exchanges.
              </Paragraph>
              <Paragraph sx={{ mt: 2 }}>
                <strong>Note</strong>: Redemption is not for repaying your loan.
                To repay your loan, adjust your Vault on the{" "}
                <Link href="#/">Dashboard</Link>.
              </Paragraph>
            </InfoMessage>
          </Card>
        </Card> */}
        <Redemption />
      </Container>

      <Container variant="bottom">
        <SystemStats />
      </Container>
    </Container>
  );
};
