import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";
import { LP, GT } from "../../../../strings";
import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { Icon } from "../../../Icon";
import { LoadingOverlay } from "../../../LoadingOverlay";
import { useMyTransactionState } from "../../../Transaction";
import { DisabledEditableRow, StaticRow } from "../../../Vault/Editor";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLOAN } from "../RemainingLOAN";
import { ClaimReward } from "./ClaimReward";
import { UnstakeAndClaim } from "../UnstakeAndClaim";

const selector = ({
  liquidityMiningStake,
  liquidityMiningLOANReward,
  totalStakedPLSXtokens,
}: LiquidLoansStoreState) => ({
  liquidityMiningStake,
  liquidityMiningLOANReward,
  totalStakedPLSXtokens,
});
const transactionId = /farm-/i;

export const Active: React.FC = () => {
  const { dispatchEvent } = useFarmView();
  const {
    liquidityMiningStake,
    liquidityMiningLOANReward,
    totalStakedPLSXtokens,
  } = useLiquidLoansSelector(selector);

  const handleAdjustPressed = useCallback(() => {
    dispatchEvent("ADJUST_PRESSED");
  }, [dispatchEvent]);

  const transactionState = useMyTransactionState(transactionId);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const poolShare = liquidityMiningStake.mulDiv(100, totalStakedPLSXtokens);
  const hasStakeAndRewards =
    !liquidityMiningStake.isZero && !liquidityMiningLOANReward.isZero;

  return (
    <Card>
      <Heading>
        PLSX Liquidity Farm
        {!isTransactionPending && (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <RemainingLOAN />
          </Flex>
        )}
      </Heading>
      <Card sx={{ p: [2, 3] }} variant="main_card">
        <Box>
          <DisabledEditableRow
            label="Stake"
            inputId="farm-stake"
            amount={liquidityMiningStake.prettify(2)}
            unit={LP}
          />
          {poolShare.infinite ? (
            <StaticRow label="Pool share" inputId="farm-share" amount="N/A" />
          ) : (
            <StaticRow
              label="Pool share"
              inputId="farm-share"
              amount={poolShare.prettify(2)}
              unit={"%"}
            />
          )}
          <Flex sx={{ alignItems: "center" }}>
            <StaticRow
              label="Reward"
              inputId="farm-reward"
              amount={liquidityMiningLOANReward.prettify(2)}
              color={liquidityMiningLOANReward.nonZero && "success"}
              unit={GT}
            />
          </Flex>
        </Box>

        <Flex variant="layout.actions">
          <Button
            variant={!liquidityMiningLOANReward.isZero ? "outline" : "primary"}
            onClick={handleAdjustPressed}
          >
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>
          {!liquidityMiningLOANReward.isZero && <ClaimReward />}
        </Flex>
        <Flex>{hasStakeAndRewards && <UnstakeAndClaim />}</Flex>
      </Card>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
