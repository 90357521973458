import React, { useState } from "react";
import {
  Text,
  Flex,
  Label,
  Input,
  SxProp,
  Button,
  ThemeUICSSProperties,
} from "theme-ui";

import { Icon } from "../Icon";

type RowProps = SxProp & {
  label: string;
  labelId?: string;
  labelFor?: string;
  infoIcon?: React.ReactNode;
  fontSize?: string;
};

export const Row: React.FC<RowProps> = ({
  sx,
  label,
  labelId,
  labelFor,
  children,
  infoIcon,
  fontSize = "18px",
}) => {
  return (
    <Flex sx={{ alignItems: "stretch", ...sx, flexDirection: "row" }}>
      <Label
        id={labelId}
        htmlFor={labelFor}
        sx={{
          p: 0,
          pl: 3,
          pt: "4px",
          position: "absolute",
          color: "#ABABAB",
          fontSize: fontSize,
          border: 1,
          fontFamily: "Titillium Web, sans-serif",
          borderColor: "transparent",
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          {label}
          {infoIcon && infoIcon}
        </Flex>
      </Label>

      {children}
    </Flex>
  );
};

type PendingAmountProps = {
  value: string;
};

const PendingAmount: React.FC<PendingAmountProps & SxProp> = ({
  sx,
  value,
}) => (
  <Text {...{ sx }}>
    (
    {value === "++" ? (
      <Icon name="angle-double-up" />
    ) : value === "--" ? (
      <Icon name="angle-double-down" />
    ) : value?.startsWith("+") ? (
      <>
        <Icon name="angle-up" /> {value.substr(1)}
      </>
    ) : value?.startsWith("-") ? (
      <>
        <Icon name="angle-down" /> {value.substr(1)}
      </>
    ) : (
      value
    )}
    )
  </Text>
);

type StaticAmountsProps = {
  inputId: string;
  labelledBy?: string;
  amount: string;
  unit?: string;
  color?: string;
  pendingAmount?: string;
  pendingColor?: string;
  onClick?: () => void;
};

export const StaticAmounts: React.FC<StaticAmountsProps & SxProp> = ({
  sx,
  inputId,
  labelledBy,
  amount,
  unit,
  color,
  pendingAmount,
  pendingColor,
  onClick,
  children,
}) => {
  return (
    <Flex
      id={inputId}
      aria-labelledby={labelledBy}
      {...{ onClick }}
      sx={{
        justifyContent: "flex-end",
        alignItems: "center",

        ...(onClick ? { cursor: "text" } : {}),
        // fontSize: "16px",
        ...staticStyle,
        ...sx,
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Text
          sx={{
            color,
            fontWeight: "medium",
            fontFamily: "Titillium Web, sans-serif",
            // fontSize: "16px",
          }}
        >
          {amount}
        </Text>

        {unit && (
          <>
            &nbsp;
            <Text sx={{ fontWeight: "light", opacity: 0.8 }}>{unit}</Text>
          </>
        )}

        {pendingAmount && (
          <>
            &nbsp;
            <PendingAmount
              sx={{ color: pendingColor, opacity: 0.8, fontSize: "0.666em" }}
              value={pendingAmount}
            />
          </>
        )}
      </Flex>

      {children}
    </Flex>
  );
};

const staticStyle: ThemeUICSSProperties = {
  flexGrow: 1,
  fontFamily: "Titillium Web, sans-serif",
  mb: 0,
  // pl: 3,
  pr: "11px",
  pt: "5px",
  pb: "5px",
  // pt: "28px",
  color: "#ABABAB",
  fontSize: "16px",

  border: 1,
  borderColor: "transparent",
};

const editableStyle: ThemeUICSSProperties = {
  // flexGrow: 1,

  // mb: [2, 3],
  mb: 0,
  pl: 3,

  // pl: 3,
  pr: "11px",
  pt: "5px",
  pb: "5px",
  color: "#ABABAB",
  fontFamily: "Titillium Web, sans-serif",
  fontSize: "18px",
  textAlign: "right",
  boxShadow: [1, 2],
  border: 1,
  borderRadius: "9px",
  borderColor: "#4F0E75",
};

type StaticRowProps = RowProps & StaticAmountsProps;

export const StaticRow: React.FC<StaticRowProps> = ({
  label,
  labelId,
  labelFor,
  infoIcon,
  ...props
}) => (
  <Row
    fontSize="16px"
    {...{ label, labelId, labelFor, infoIcon }}
    sx={
      {
        // mt: [-2, -3],
        // pb: [2, 3],
      }
    }
  >
    <StaticAmounts {...props} />
  </Row>
);

type DisabledEditableRowProps = Omit<
  StaticAmountsProps,
  "labelledBy" | "onClick"
> & {
  label: string;
};

export const DisabledEditableRow: React.FC<DisabledEditableRowProps> = ({
  inputId,
  label,
  unit,
  amount,
  color,
  pendingAmount,
  pendingColor,
}) => (
  <Row labelId={`${inputId}-label`} {...{ label, unit }}>
    <StaticAmounts
      sx={{ ...editableStyle, boxShadow: 0 }}
      labelledBy={`${inputId}-label`}
      {...{ inputId, amount, unit, color, pendingAmount, pendingColor }}
    />
  </Row>
);

type EditableRowProps = DisabledEditableRowProps & {
  editingState: [string | undefined, (editing: string | undefined) => void];
  editedAmount: string;
  setEditedAmount: (editedAmount: string) => void;
  maxAmount?: string;
  maxedOut?: boolean;
};

export const EditableRow: React.FC<EditableRowProps> = ({
  label,
  inputId,
  unit,
  amount,
  color,
  pendingAmount,
  pendingColor,
  editingState,
  editedAmount,
  setEditedAmount,
  maxAmount,
  maxedOut,
}) => {
  const [editing, setEditing] = editingState;
  const [invalid, setInvalid] = useState(false);

  return editing === inputId ? (
    <Row {...{ label, labelFor: inputId, unit }}>
      <Input
        autoFocus
        id={inputId}
        // type="number"
        // step="any"
        placeholder={editedAmount}
        defaultValue={editedAmount === "0.00" ? undefined : editedAmount}
        {...{ invalid }}
        onChange={(e) => {
          try {
            // console.log(e.target.value === "");
            setEditedAmount(e.target.value === "" ? "0" : e.target.value);
            setInvalid(false);
          } catch {
            setInvalid(true);
          }
        }}
        onBlur={() => {
          setEditing(undefined);
          setInvalid(false);
        }}
        variant="editor"
        sx={{
          ...editableStyle,
          fontWeight: "medium",
          // bg: invalid ? "invalid" : "background",
          bg: "#010001",
          outline: "solid 1px #4F0E75",
          "&:focus": {
            // boxShadow: "0 0 0 2px #4F0E75",
            boxShadow: "0px 0px 2px 2px #4F0E75 ",
          },
        }}
      />
    </Row>
  ) : (
    <Row labelId={`${inputId}-label`} {...{ label, unit }}>
      <StaticAmounts
        sx={{
          ...editableStyle,
          // bg: invalid ? "invalid" : "background",
          bg: "#010001",
        }}
        labelledBy={`${inputId}-label`}
        onClick={() => setEditing(inputId)}
        {...{
          inputId,
          amount,
          unit,
          color,
          pendingAmount,
          pendingColor,
          invalid,
        }}
      >
        {/* {maxAmount && (
          <Button
            sx={{ fontSize: 1, p: 1, px: 3 }}
            onClick={(event) => {
              setEditedAmount(maxAmount);
              event.stopPropagation();
            }}
            disabled={maxedOut}
          >
            max
          </Button>
        )} */}
      </StaticAmounts>
    </Row>
  );
};
