import React, { useState } from "react";
import { StabilityDepositManager } from "./StabilityDepositManager";
import { ActiveDeposit } from "./ActiveDeposit";
import { NoDeposit } from "./NoDeposit";
import { useStabilityView } from "./context/StabilityViewContext";

export const Stability: React.FC = (props) => {
  const { view } = useStabilityView();
  const [currentTab, setCurrentTab] = useState("Stake");
  const tabs = ["Stake", "Unstake"];
  switch (view) {
    case "NONE": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "DEPOSITING": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "ADJUSTING": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "ACTIVE": {
      return (
        <StabilityDepositManager
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
  }
};
