import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import {
  Flex,
  Spinner,
  Heading,
  ThemeProvider,
  Paragraph,
  Link,
  Button,
  Image,
  Text,
  Box,
} from "theme-ui";

import { BatchedWebSocketAugmentedWeb3Provider } from "@liquidloans/providers";
import { LiquidLoansProvider } from "./hooks/LiquidLoansContext";
import { WalletConnector } from "./components/WalletConnector";
import { TransactionProvider } from "./components/Transaction";
import { Icon } from "./components/Icon";
import { getConfig } from "./config";
import theme from "./theme";

import { DisposableWalletProvider } from "./testUtils/DisposableWalletProvider";
import { LiquidLoansFrontend } from "./LiquidLoansFrontend";
import BaristaLogo from "./assets/images/barista-logo.png";
import SafeLocker from "./assets/images/safe-locker.png";
import YieldLogo from "./assets/images/yield-farming.png";
import WeighingBalance from "./assets/images/weighing-balance.png";
import StaticHomePage from "./pages/StaticHomePage";

if (window.ethereum) {
  // Silence MetaMask warning in console
  Object.assign(window.ethereum, { autoRefreshOnNetworkChange: false });
}

if (process.env.REACT_APP_DEMO_MODE === "true") {
  const ethereum = new DisposableWalletProvider(
    `http://${window.location.hostname}:8545`,
    "0x4d5db4107d237df6a3d58ee5f70ae63d73d7658d4026f2eefd2f204c81682cb7"
  );

  Object.assign(window, { ethereum });
}

// Start pre-fetching the config
getConfig().then((config) => {
  // console.log("Frontend config:");
  // console.log(config);
  Object.assign(window, { config });
});

const PulsesWeb3ReactProvider: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider
      getLibrary={(provider) =>
        new BatchedWebSocketAugmentedWeb3Provider(provider)
      }
    >
      {children}
    </Web3ReactProvider>
  );
};

const UnsupportedMainnetFallback: React.FC = () => (
  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      textAlign: "center",
    }}
  >
    <Heading sx={{ mb: 3 }}>
      <Icon name="exclamation-triangle" /> This app is for testing purposes
      only.
    </Heading>

    <Paragraph sx={{ mb: 3 }}>
      Please change your network to Ropsten, Rinkeby, Kovan, Görli or Kiln.
    </Paragraph>

    <Paragraph>
      If you'd like to use the Liquid Loans Protocol on mainnet, please pick a
      frontend{" "}
      <Link href="https://www.liquidloans.io/frontend">
        here <Icon name="external-link-alt" size="xs" />
      </Link>
      .
    </Paragraph>
  </Flex>
);

const ChangeNetwork = async () => {
  try {
    if (!window.ethereum) throw new Error("No crypto wallet found");
    await (window as any).ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${Number(process.env.REACT_APP_CHAIN_ID || 369).toString(
            16
          )}`,
          chainName:
            process.env.REACT_APP_NETWORK_NAME || "PulseChain",
          nativeCurrency: {
            name: "Pulse",
            symbol: process.env.REACT_APP_CURRENCY_SYMBOL || "PLS",
            decimals: 18,
            image: "https://pulsechain.com/img/wordmarkShort.png",
          },
          rpcUrls: [
            process.env.REACT_APP_RPC ||
              "https://rpc.pulsechain.com",
          ],
          blockExplorerUrls: [
            process.env.REACT_APP_BLOCK_EXPLORER ||
              "https://scan.pulsechain.com",
          ],
        },
      ],
    });
  } catch (err) {
    return console.log(err);
  }
};

const App = () => {
  const loader = (
    <Flex
      sx={{ alignItems: "center", justifyContent: "center", height: "100vh" }}
    >
      <Spinner sx={{ m: 2, color: "text" }} size="32px" />
      <Heading>Loading...</Heading>
    </Flex>
  );

  const unsupportedNetworkFallback = (
    chainId: number,
    channel?: string | undefined
  ) => (
    <StaticHomePage>
      <Flex sx={{ flexDirection: "column", mt: "20px" }}>
        <Heading sx={{ mb: 3 }}>
          <Icon name="exclamation-triangle" />{" "}
          {channel === "local"
            ? 'dApp is set to run on "PulseChain LocalHost"'
            : "dApp is not yet deployed to"}{" "}
          {channel === "local"
            ? "network"
            : chainId === 369
            ? "mainnet"
            : "this network"}
          .
        </Heading>
        <Paragraph>Please switch to</Paragraph>
        <Button
          variant="connect_wallet"
          sx={{ p: "0.5em 1em", mt: 2 }}
          onClick={ChangeNetwork}
        >
          PulseChain{channel === "local" && " LocalHost"}
        </Button>
      </Flex>
    </StaticHomePage>
  );

  return (
    <PulsesWeb3ReactProvider>
      <ThemeProvider theme={theme}>
        <WalletConnector loader={loader}>
          <LiquidLoansProvider
            loader={loader}
            unsupportedNetworkFallback={unsupportedNetworkFallback}
            unsupportedMainnetFallback={<UnsupportedMainnetFallback />}
          >
            <TransactionProvider>
              <LiquidLoansFrontend loader={loader} />
            </TransactionProvider>
          </LiquidLoansProvider>
        </WalletConnector>
      </ThemeProvider>
    </PulsesWeb3ReactProvider>
  );
};

export default App;
