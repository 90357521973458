import React, { useCallback } from "react";
import { Card, Heading, Box, Button, Flex } from "theme-ui";
import { CollateralSurplusAction } from "../CollateralSurplusAction";
import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { useVaultView } from "./context/VaultViewContext";
import { InfoMessage } from "../InfoMessage";

const select = ({ collateralSurplusBalance }: LiquidLoansStoreState) => ({
  hasSurplusCollateral: !collateralSurplusBalance.isZero,
});

export const LiquidatedVault: React.FC = () => {
  const { hasSurplusCollateral } = useLiquidLoansSelector(select);
  const { dispatchEvent } = useVaultView();

  const handleOpenVault = useCallback(() => {
    dispatchEvent("OPEN_VAULT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Flex
      sx={{
        gap: "20px",
        flexDirection: ["column", "row"],
      }}
    >
      <Card sx={{ width: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Vault</Heading>
        <Card sx={{ p: [2, 3] }} variant="main_card">
          <InfoMessage title="Your Vault has been liquidated.">
            {hasSurplusCollateral
              ? "Please reclaim your remaining collateral before opening a new Vault."
              : "You can borrow USDL by opening a Vault."}
          </InfoMessage>

          <Flex variant="layout.actions">
            {hasSurplusCollateral && <CollateralSurplusAction />}
            {!hasSurplusCollateral && (
              <Button onClick={handleOpenVault}>Open Vault</Button>
            )}
          </Flex>
        </Card>
      </Card>
      <Flex sx={{ flexDirection: "column", width: "100%" }}>
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>About Liquidation</Heading>

          <Card
            sx={{ fontFamily: "Titillium Web, sans-serif", p: [2, 3] }}
            variant="second_card"
          >
            Think of redemptions as if somebody else just repaid your debt and
            retrieved an equivalent amount of your collateral.
            <br />
            At the time of redemption, you had the Vault with the lowest ratio,
            so you gave up some of your collateral, but your debt was reduced
            accordingly.
            <br />
            Redemptions improve the collateral ratio of the affected Vaults,
            making them less risky.
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
