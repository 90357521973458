import { useEffect } from "react";

import { LiquidLoansStoreState, LOANStake } from "@liquidloans/lib-base";
import {
  LiquidLoansStoreUpdate,
  useLiquidLoansReducer,
} from "@liquidloans/lib-react";

import { useMyTransactionState } from "../../Transaction";

import { StakingViewAction, StakingViewContext } from "./StakingViewContext";

type StakingViewProviderAction =
  | LiquidLoansStoreUpdate
  | StakingViewAction
  | { type: "startChange" | "abortChange" };

type StakingViewProviderState = {
  loanStake: LOANStake;
  changePending: boolean;
  adjusting: boolean;
};

const init = ({
  loanStake,
}: LiquidLoansStoreState): StakingViewProviderState => ({
  loanStake,
  changePending: false,
  adjusting: false,
});

const reduce = (
  state: StakingViewProviderState,
  action: StakingViewProviderAction
): StakingViewProviderState => {
  // console.log(state);
  // console.log(action);

  switch (action.type) {
    case "startAdjusting":
      return { ...state, adjusting: true };

    case "cancelAdjusting":
      return { ...state, adjusting: false };

    case "startChange":
      return { ...state, changePending: true };

    case "abortChange":
      return { ...state, changePending: false };

    case "updateStore": {
      const {
        oldState: { loanStake: oldStake },
        stateChange: { loanStake: updatedStake },
      } = action;

      if (updatedStake) {
        const changeCommitted =
          !updatedStake.stakedLOAN.eq(oldStake.stakedLOAN) ||
          updatedStake.collateralGain.lt(oldStake.collateralGain) ||
          updatedStake.usdlGain.lt(oldStake.usdlGain);
        console.log("*****************************************************");
        console.log(updatedStake.stakedLOAN.toString());
        console.log(oldStake.stakedLOAN.toString());
        console.log(changeCommitted);
        return {
          ...state,
          loanStake: updatedStake,
          adjusting: false,
          changePending: changeCommitted ? false : state.changePending,
        };
      }
    }
  }

  return state;
};

export const StakingViewProvider: React.FC = ({ children }) => {
  const stakingTransactionState = useMyTransactionState("stake");
  const [{ adjusting, changePending, loanStake }, dispatch] =
    useLiquidLoansReducer(reduce, init);

  useEffect(() => {
    if (
      stakingTransactionState.type === "waitingForApproval" ||
      stakingTransactionState.type === "waitingForConfirmation"
    ) {
      dispatch({ type: "startChange" });
    } else if (
      stakingTransactionState.type === "failed" ||
      stakingTransactionState.type === "cancelled"
    ) { 
      dispatch({ type: "abortChange" });
    } else if (
      stakingTransactionState.type === "confirmed"
    ) {
      dispatch({ type: "abortChange" });
      dispatch({ type: "cancelAdjusting" });
    }
  }, [stakingTransactionState.type, dispatch]);

  return (
    <StakingViewContext.Provider
      value={{
        view: adjusting ? "ADJUSTING" : loanStake.isEmpty ? "NONE" : "ACTIVE",
        changePending,
        dispatch,
      }}
    >
      {children}
    </StakingViewContext.Provider>
  );
};
