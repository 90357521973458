import React, { useState } from "react";
import { Flex, Box, Text, ThemeUIStyleObject } from "theme-ui";

type TabsProps = {
  tabs: string[];
  currentTab: string;
  disabledTab?: string;
  setCurrentTab: (value: string) => void;
};

const tabStyle: ThemeUIStyleObject = {
  width: "100%",
  cursor: "pointer",
  p: 2,
  transition: "border-color 0.3s",
  fontSize: ["14px", null, null, "20px"],
  mb: "20px",
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
  disabledTab,
}) => {
  const handleClick = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <Flex sx={{ width: "100%", gap: 2, mb: 0 }}>
      {tabs.map((tab, index) => (
        <Box
          key={index}
          sx={{
            ...tabStyle,
            fontFamily: "Titillium Web, sans-serif",
            color:
              currentTab === tab
                ? "white"
                : disabledTab !== tab
                ? "gray"
                : "rgba(128,128,128,0.2)",
            textDecoration: "none",
            borderBottom: `2px solid ${
              currentTab === tab ? "#C22EFF" : "transparent"
              // : disabledTab !== tab
              // ? "gray"
              // : "rgba(128,128,128,0.2)"
            }`,
            ":hover": {
              color:
                disabledTab !== tab
                  ? "white"
                  : disabledTab !== tab
                  ? "gray"
                  : "rgba(128,128,128,0.2)",
              borderBottomColor:
                disabledTab !== tab ? "#C22EFF" : "transparent",
              // "rgba(128,128,128,0.2)"
            },
          }}
          onClick={() => {
            if (disabledTab !== tab) {
              handleClick(tab);
            }
          }}
        >
          <Text>{tab}</Text>
        </Box>
      ))}
    </Flex>
  );
};

export default Tabs;
