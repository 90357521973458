import React from "react";
import { Flex, Card } from "theme-ui";
import { InfoIcon } from "./InfoIcon";

type StatisticProps = {
  name: React.ReactNode;
  tooltip?: React.ReactNode;
};

export const Statistic: React.FC<StatisticProps> = ({
  name,
  tooltip,
  children,
}) => {
  return (
    <Flex
      sx={{
        borderBottom: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        maxWidth: [null, null, null, "200px"],
        // fontFamily: "Titillium Web, sans-serif",
      }}
    >
      <Flex
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          // flex: 1.2,
          fontWeight: 200,
          fontSize: "13px",
          color: "grey",
          fontFamily: "Titillium Web, sans-serif",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
      >
        <Flex>{name}</Flex>
        {tooltip ? (
          <InfoIcon
            size="xs"
            tooltip={
              <Card
                variant="tooltip"
                sx={{
                  wordWrap: "break-word",
                  maxWidth: "240px",
                  display: "inline-block",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {tooltip}
              </Card>
            }
          />
        ) : null}
      </Flex>
      <Flex
        sx={{
          justifyContent: "flex-start",
          // flex: 0.8,
          alignItems: "center",
          fontSize: "20px",
          flexDirection: "column",
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
