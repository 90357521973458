import React, { useCallback, useEffect, useState } from "react";
import { Flex, Button, Box, Card, Heading, Spinner, Text } from "theme-ui";
import {
  LiquidLoansStoreState,
  Decimal,
  Vault,
  USDL_LIQUIDATION_RESERVE,
  USDL_MINIMUM_NET_DEBT,
  Percent,
} from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { useStableVaultChange } from "../../hooks/useStableVaultChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { VaultAction } from "./VaultAction";
import { useVaultView } from "./context/VaultViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import {
  ExpensiveVaultChangeWarning,
  GasEstimationState,
} from "./ExpensiveVaultChangeWarning";
import {
  selectForVaultChangeValidation,
  validateVaultChange,
} from "./validation/validateVaultChange";
import Tabs from "../Tabs";
import PercentageSelector from "../PercentageSelector";

const selector = (state: LiquidLoansStoreState) => {
  const { fees, price, accountBalance, usdlBalance } = state;
  return {
    fees,
    price,
    accountBalance,
    usdlBalance,
    validationContext: selectForVaultChangeValidation(state),
  };
};

const EMPTY_VAULT = new Vault(Decimal.ZERO, Decimal.ZERO);
const TRANSACTION_ID = "vault-creation";
const GAS_ROOM_PLS = Decimal.from(0.1);

type OpeningProps = {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
};

export const Opening: React.FC<OpeningProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { dispatchEvent } = useVaultView();
  const { fees, price, accountBalance, usdlBalance, validationContext } =
    useLiquidLoansSelector(selector);
  const borrowingRate = fees.borrowingRate();
  const editingState = useState<string>();

  const [collateral, setCollateral] = useState<Decimal>(Decimal.ZERO);
  const [borrowAmount, setBorrowAmount] = useState<Decimal>(Decimal.ZERO);
  const dummyvalue = 10000;
  const [dummyPerc, setDummyPerc] = useState(25);
  const maxBorrowingRate = borrowingRate.add(0.005);
  const fee = borrowAmount.mul(borrowingRate);
  const feePct = new Percent(borrowingRate);
  const liquidationReserve = borrowAmount.gt(0) ? USDL_LIQUIDATION_RESERVE : 0

  const percentDeci = borrowingRate.div(1e18);

  const totalDebt = borrowAmount.add(liquidationReserve).add(fee);
  const isDirty = !collateral.isZero || !borrowAmount.isZero;
  const vault = isDirty ? new Vault(collateral, totalDebt) : EMPTY_VAULT;
  const maxCollateral = accountBalance.gt(GAS_ROOM_PLS)
    ? accountBalance.sub(GAS_ROOM_PLS)
    : Decimal.ZERO;
  const collateralMaxedOut = collateral.eq(maxCollateral);
  const collateralRatio =
    !collateral.isZero && !borrowAmount.isZero
      ? vault.collateralRatio(price)
      : undefined;

  const [vaultChange, description] = validateVaultChange(
    EMPTY_VAULT,
    vault,
    borrowingRate,
    validationContext
  );

  const stableVaultChange = useStableVaultChange(vaultChange);
  const [gasEstimationState, setGasEstimationState] =
    useState<GasEstimationState>({ type: "idle" });

  const transactionState = useMyTransactionState(TRANSACTION_ID);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_VAULT_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(Decimal.ZERO);
    setBorrowAmount(Decimal.ZERO);
  }, []);

  // useEffect(() => {
  //   if (!collateral.isZero) {
  //     // borrowUSDL = (depositPLS * price)/1.5 - (reserves - Borrowing_Fee)
  //     let temp = collateral.mul(price).div(Decimal.from(1.5));
  //     let temp2 = USDL_LIQUIDATION_RESERVE.gt(fee)
  //       ? USDL_LIQUIDATION_RESERVE.sub(fee)
  //       : Decimal.from(0);
  //     let temp3 = temp.gt(temp2) ? temp.sub(temp2) : Decimal.from(0);
  //     setBorrowAmount(temp3);
  //   }
  // }, [collateral]);

  return (
    <Flex
      sx={{
        gap: "20px",
        flexDirection: ["column", "row"],
      }}
    >
      <Card sx={{ width: "100%", minHeight: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Vault</Heading>

        <Card sx={{ p: [2, 3] }} variant="main_card">
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            disabledTab="Repay"
          />
          <EditableRow
            label="DEPOSIT PLS"
            inputId="vault-collateral"
            amount={collateral.prettify(2)}
            maxAmount={maxCollateral.toString()}
            maxedOut={collateralMaxedOut}
            editingState={editingState}
            unit=""
            editedAmount={collateral.toString(2)}
            setEditedAmount={(amount: string) => {
              setCollateral(Decimal.from(amount));
              if (!Decimal.from(amount).isZero) {
                // borrowUSDL = (depositPLS * price)/1.5 - (reserves - Borrowing_Fee)
                let temp = Decimal.from(amount)
                  .mul(price)
                  .div(Decimal.from(1.5));
                let temp2 = USDL_LIQUIDATION_RESERVE.gt(fee)
                  ? USDL_LIQUIDATION_RESERVE.sub(fee)
                  : Decimal.from(0);
                let temp3 = temp.gt(temp2) ? temp.sub(temp2) : Decimal.from(0);
                const borrowAmount = Number(temp3.sub(temp3.mul(borrowingRate)))
                setBorrowAmount( Decimal.from(borrowAmount) );
              }
            }}
          />
          {(
            [
              ["PLS", accountBalance],
              // [COIN, usdlBalance],
              // [GT, loanBalance],
            ] as const
          ).map(([currency, balance], i) => (
            <Flex
              key={i}
              sx={{ my: 2, flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  fontFamily: "Titillium Web, sans-serif",
                }}
              >
                Wallet : {balance.prettify()} {currency}
              </Text>
            </Flex>
          ))}
          <PercentageSelector
            value={accountBalance}
            setFinalValue={(amount: Decimal) => {
              setCollateral(amount);
              if (!amount.isZero) {
                // borrowUSDL = (depositPLS * price)/1.5 - (reserves - Borrowing_Fee)
                let temp = amount.mul(price).div(Decimal.from(1.5));
                let temp2 = USDL_LIQUIDATION_RESERVE.gt(fee)
                  ? USDL_LIQUIDATION_RESERVE.sub(fee)
                  : Decimal.from(0);
                let temp3 = temp.gt(temp2) ? temp.sub(temp2) : Decimal.from(0);
                const borrowAmount = Number(temp3.sub(temp3.mul(borrowingRate)))
                setBorrowAmount( Decimal.from(borrowAmount) );
              }
            }}
          />
          <EditableRow
            label={`BORROW ${COIN}`}
            inputId="vault-borrow-amount"
            amount={borrowAmount.prettify()}
            unit=""
            editingState={editingState}
            editedAmount={borrowAmount.toString(2)}
            setEditedAmount={(amount: string) =>
              setBorrowAmount(Decimal.from(amount))
            }
          />
          {(
            [
              // ["PLS", accountBalance],
              [COIN, usdlBalance],
              // [GT, loanBalance],
            ] as const
          ).map(([currency, balance], i) => (
            <Flex
              key={i}
              sx={{ my: 2, flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  fontFamily: "Titillium Web, sans-serif",
                }}
              >
                Wallet : {balance.prettify()} {currency}
              </Text>
            </Flex>
          ))}

          {description ?? (
            <ActionDescription>
              Start by entering the amount of PLS you'd like to deposit as
              collateral.
            </ActionDescription>
          )}

          <Flex sx={{ alignItems: "center" }}>
            {collateralRatio?.lt(1.5) && (
              <ActionDescription>
                Keeping your CR above 150% can help avoid liquidation under
                Recovery Mode.
              </ActionDescription>
            )}
            {isDirty && !isTransactionPending && (
              <Button
                variant="titleIcon"
                sx={{ ":enabled:hover": { color: "danger" } }}
                onClick={reset}
              >
                <Icon name="history" size="lg" />
              </Button>
            )}
          </Flex>

          <ExpensiveVaultChangeWarning
            vaultChange={stableVaultChange}
            maxBorrowingRate={maxBorrowingRate}
            borrowingFeeDecayToleranceMinutes={60}
            gasEstimationState={gasEstimationState}
            setGasEstimationState={setGasEstimationState}
          />

          <Flex variant="layout.actions">
            {/* <Button variant="cancel" onClick={handleCancelPressed}>
            Cancel
          </Button> */}

            {gasEstimationState.type === "inProgress" ? (
              <Button disabled>
                <Spinner size="24px" sx={{ color: "background" }} />
              </Button>
            ) : stableVaultChange ? (
              <VaultAction
                transactionId={TRANSACTION_ID}
                change={stableVaultChange}
                maxBorrowingRate={maxBorrowingRate}
                borrowingFeeDecayToleranceMinutes={60}
              >
                Confirm
              </VaultAction>
            ) : (
              <Button disabled>Confirm</Button>
            )}
          </Flex>
          {isTransactionPending && <LoadingOverlay />}
        </Card>
      </Card>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
      >
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>
            Your Transaction Stats
          </Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label="Deposit Amount"
              inputId="vault-liquidation-reserve"
              amount={`${collateral.prettify(2)}`}
              unit="PLS"
            />
            <StaticRow
              label="Borrowing Fee"
              inputId="vault-borrowing-fee"
              amount={fee.prettify(2)}
              pendingAmount={feePct.toString(2)}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      This amount is deducted from the borrowed amount as a
                      one-time fee. There are no recurring fees for borrowing,
                      which is thus interest-free.
                    </Card>
                  }
                />
              }
            />

            <StaticRow
              label="Liquidation Reserve"
              inputId="vault-liquidation-reserve"
              amount={`${liquidationReserve}`}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "200px" }}>
                      An amount set aside to cover the liquidator’s gas costs if
                      your Vault needs to be liquidated. The amount increases
                      your debt and is refunded if you close your Vault by fully
                      paying off its net debt.
                    </Card>
                  }
                />
              }
            />

            <StaticRow
              label="Borrow Amount"
              inputId="vault-liquidation-reserve"
              amount={`${borrowAmount.prettify()}`}
              unit={COIN}
            />

            <StaticRow
              label="Total debt"
              inputId="vault-total-debt"
              amount={totalDebt.prettify(2)}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      The total amount of USDL your Vault will hold.{" "}
                      {isDirty && (
                        <>
                          You will need to repay{" "}
                          {totalDebt.sub(liquidationReserve).prettify(2)}{" "}
                          USDL to reclaim your collateral (
                          {liquidationReserve.toString()} USDL Liquidation
                          Reserve excluded).
                        </>
                      )}
                    </Card>
                  }
                />
              }
            />
          </Card>
        </Card>
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Vault Stats</Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label="Total Balance (PLS)"
              inputId="vault-liquidation-reserve"
              amount={`${collateral.prettify(2)}`}
              unit="PLS"
            />
            <StaticRow
              label="Total Debt (USDL)"
              inputId="vault-borrowing-fee"
              amount={totalDebt.prettify(2)}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      Total USDL Borrowed
                    </Card>
                  }
                />
              }
            />

            <CollateralRatio value={collateralRatio} />

            <StaticRow
              label="Liquidation Price (Normal Mode)"
              inputId="vault-liquidation-reserve"
              amount={
                !collateral.isZero
                  ? totalDebt
                    .mul(Decimal.from(110))
                    .div(collateral.mul(Decimal.from(100)))
                    .toString(8)
                  : "0.00"
              }
              unit="USD"
            />

            <StaticRow
              label="Liquidation Price (Recovery Mode)"
              inputId="vault-total-debt"
              amount={
                !collateral.isZero
                  ? totalDebt
                    .mul(Decimal.from(150))
                    .div(collateral.mul(Decimal.from(100)))
                    .toString(8)
                  : "0.00"
              }
              unit="USD"
            />
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
