import React from "react";
import { Flex } from "theme-ui";

import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

const selector = ({ remainingLiquidityMiningLOANReward }: LiquidLoansStoreState) => ({
  remainingLiquidityMiningLOANReward
});

export const RemainingLOAN: React.FC = () => {
  const { remainingLiquidityMiningLOANReward } = useLiquidLoansSelector(selector);

  return (
    <Flex sx={{ mr: 2, fontSize: 2, fontWeight: "medium" }}>
      {remainingLiquidityMiningLOANReward.prettify(0)} LOAN remaining
    </Flex>
  );
};
