import React from "react";
import { Card, Heading, Box, Flex } from "theme-ui";
import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { InfoMessage } from "../../../InfoMessage";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { RemainingLOAN } from "../RemainingLOAN";
import { StaticRow } from "../../../Vault/Editor";
import { GT, LP } from "../../../../strings";

const selector = ({
  liquidityMiningStake,
  liquidityMiningLOANReward,
}: LiquidLoansStoreState) => ({
  liquidityMiningStake,
  liquidityMiningLOANReward,
});

export const Disabled: React.FC = () => {
  const { liquidityMiningStake, liquidityMiningLOANReward } =
    useLiquidLoansSelector(selector);
  const hasStake = !liquidityMiningStake.isZero;

  return (
    <Card>
      <Heading>
        PLSX Liquidity Farm
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLOAN />
        </Flex>
      </Heading>
      <Card sx={{ p: [2, 3] }} variant="main_card">
        <InfoMessage title="Liquidity farming period has finished">
          <Flex>There are no more LOAN rewards left to farm</Flex>
        </InfoMessage>
        {hasStake && (
          <>
            <Box sx={{ border: 1, pt: 3, borderRadius: 3 }}>
              <StaticRow
                label="Stake"
                inputId="farm-deposit"
                amount={liquidityMiningStake.prettify(2)}
                unit={LP}
              />
              <StaticRow
                label="Reward"
                inputId="farm-reward"
                amount={liquidityMiningLOANReward.prettify(2)}
                color={liquidityMiningLOANReward.nonZero && "success"}
                unit={GT}
              />
            </Box>
            <UnstakeAndClaim />
          </>
        )}
      </Card>
    </Card>
  );
};
