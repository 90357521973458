import { Button } from "theme-ui";
import { Decimal, LiquidLoansStoreState, StabilityDepositChange } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useTransactionFunction } from "../Transaction";

type StabilityDepositActionProps = {
  transactionId: string;
  change: StabilityDepositChange<Decimal>;
};

const selectFrontendRegistered = ({ frontend }: LiquidLoansStoreState) =>
  frontend.status === "registered";

export const StabilityDepositAction: React.FC<StabilityDepositActionProps> = ({
  children,
  transactionId,
  change
}) => {
  const { config, liquidLoans } = useLiquidLoans();
  const frontendRegistered = useLiquidLoansSelector(selectFrontendRegistered);

  const frontendTag = frontendRegistered ? config.frontendTag : undefined;

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    change.depositUSDL
      ? liquidLoans.send.depositUSDLInStabilityPool.bind(liquidLoans.send, change.depositUSDL, frontendTag)
      : liquidLoans.send.withdrawUSDLFromStabilityPool.bind(liquidLoans.send, change.withdrawUSDL)
  );

  return <Button onClick={sendTransaction}>{children}</Button>;
};
