import React, { useState } from "react";
import { Flex, Container, Box, Button } from "theme-ui";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Wallet } from "@ethersproject/wallet";

import { Decimal, Difference, Vault } from "@liquidloans/lib-base";
import { LiquidLoansStoreProvider } from "@liquidloans/lib-react";

import { useLiquidLoans } from "./hooks/LiquidLoansContext";
import { TransactionMonitor } from "./components/Transaction";
import { UserAccount } from "./components/UserAccount";
import { SystemStatsPopup } from "./components/SystemStatsPopup";
import { Header } from "./components/Header";

import { PageSwitcher } from "./pages/PageSwitcher";
import { Farm } from "./pages/Farm";
import { RiskyVaultsPage } from "./pages/RiskyVaultsPage";
import { RedemptionPage } from "./pages/RedemptionPage";

import { VaultViewProvider } from "./components/Vault/context/VaultViewProvider";
import { StabilityViewProvider } from "./components/Stability/context/StabilityViewProvider";
import { StakingViewProvider } from "./components/Staking/context/StakingViewProvider";
import { FarmViewProvider } from "./components/Farm/context/FarmViewProvider";
import { Icon } from "./components/Icon";
import SideBar from "./components/SideBar";
import MenuButton from "./components/MenuButton";
import { UserTokenBalances } from "./components/UserTokenBalances";
import { BorrowUSDL } from "./pages/BorrowUSDL";
import { StabilityPool } from "./pages/StabilityPool";
import { StakingPool } from "./pages/StakingPool";

type LiquidLoansFrontendProps = {
  loader?: React.ReactNode;
};
export const LiquidLoansFrontend: React.FC<LiquidLoansFrontendProps> = ({
  loader,
}) => {
  const { account, provider, liquidLoans } = useLiquidLoans();
  const [isVisible, setIsVisible] = useState(true);
  // For console tinkering ;-)
  Object.assign(window, {
    account,
    provider,
    liquidLoans,
    Vault,
    Decimal,
    Difference,
    Wallet,
  });

  const openNewWindow = () => {
    window.open('https://docs.mcr369.io/barista');
    // window.location.href = 'https://docs.mcr369.io/barista';

  };

  return (
    <LiquidLoansStoreProvider {...{ loader }} store={liquidLoans.store}>
      <Router>
        <VaultViewProvider>
          <StabilityViewProvider>
            <StakingViewProvider>
              <FarmViewProvider>
                <Flex sx={{ flexDirection: "row", minHeight: "100%" }}>
                  <SideBar />

                  <Flex
                    sx={{
                      flexDirection: "column",
                      minHeight: "100%",
                      width: ["100%", null, null, "100%"],
                    }}
                  >
                    <Header
                      isVisible={isVisible}
                      setIsVisible={setIsVisible}
                    ></Header>

                    <Container
                      variant="main"
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Switch>
                        <Route path="/" exact>
                          <BorrowUSDL />
                        </Route>
                        {/* <Route path="/borrow-usdl">
                          <BorrowUSDL />
                        </Route> */}
                        <Route path="/stability-pool">
                          <StabilityPool />
                        </Route>
                        <Route path="/staking-pool">
                          <StakingPool />
                        </Route>
                        <Route path="/farm">
                          <Farm />
                        </Route>
                        <Route path="/liquidation">
                          <RiskyVaultsPage />
                        </Route>
                        <Route path="/redemptions">
                          <RedemptionPage />
                        </Route>
                        <Route path="/knowledge" exact>
                          {() => {
                            openNewWindow(); // Call the function to open the new window
                            return null; // Return null to satisfy the ReactNode requirement
                          }}
                        </Route>
                      </Switch>
                    </Container>
                  </Flex>
                </Flex>
              </FarmViewProvider>
            </StakingViewProvider>
          </StabilityViewProvider>
        </VaultViewProvider>
      </Router>
      <TransactionMonitor />
    </LiquidLoansStoreProvider>
  );
};
