import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import { Flex, Button, Box, Card, Heading, Text } from "theme-ui";
import {
  LiquidLoansStoreState,
  Decimal,
  Vault,
  USDL_LIQUIDATION_RESERVE,
  Percent,
  Difference,
  USDL_MINIMUM_NET_DEBT,
} from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { useStableVaultChange } from "../../hooks/useStableVaultChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { VaultAction } from "./VaultAction";
import { useVaultView } from "./context/VaultViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import {
  ExpensiveVaultChangeWarning,
  GasEstimationState,
} from "./ExpensiveVaultChangeWarning";
import {
  selectForVaultChangeValidation,
  validateVaultChange,
} from "./validation/validateVaultChange";
import Tabs from "../Tabs";
import PercentageSelector from "../PercentageSelector";
import { InfoMessage } from "../InfoMessage";
import { CollateralSurplusAction } from "../CollateralSurplusAction";
import { VaultManager } from "./VaultManager";

const selector = (state: LiquidLoansStoreState) => {
  const {
    vault,
    fees,
    price,
    accountBalance,
    usdlBalance,
    collateralSurplusBalance,
  } = state;
  return {
    vault,
    fees,
    price,
    accountBalance,
    usdlBalance,
    hasSurplusCollateral: !collateralSurplusBalance.isZero,
    validationContext: selectForVaultChangeValidation(state),
  };
};

const TRANSACTION_ID = "vault-adjustment";
const GAS_ROOM_PLS = Decimal.from(0.1);

const feeFrom = (
  original: Vault,
  edited: Vault,
  borrowingRate: Decimal
): Decimal => {
  const change = original.whatChanged(edited, borrowingRate);

  if (change && change.type !== "invalidCreation" && change.params.borrowUSDL) {
    return change.params.borrowUSDL.mul(borrowingRate);
  } else {
    return Decimal.ZERO;
  }
};

const applyUnsavedCollateralChanges = (
  unsavedChanges: Difference,
  vault: Vault
) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return vault.collateral.add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(vault.collateral)) {
        return vault.collateral.sub(unsavedChanges.absoluteValue);
      }
    }
    return vault.collateral;
  }
  return vault.collateral;
};

const applyUnsavedNetDebtChanges = (
  unsavedChanges: Difference,
  vault: Vault
) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return vault.netDebt.add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(vault.netDebt)) {
        return vault.netDebt.sub(unsavedChanges.absoluteValue);
      }
    }
    return vault.netDebt;
  }
  return vault.netDebt;
};
type AdjustingProps = {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
  view: string;
};

export const Adjusting: React.FC<AdjustingProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
  view,
}) => {
  const { dispatchEvent } = useVaultView();
  const {
    vault,
    fees,
    price,
    accountBalance,
    usdlBalance,
    validationContext,
    hasSurplusCollateral,
  } = useLiquidLoansSelector(selector);
  const editingState = useState<string>();
  const previousVault = useRef<Vault>(vault);
  const [collateral, setCollateral] = useState<Decimal>(vault.collateral);
  const [collateralEntered, setCollateralEntered] = useState<Decimal>(
    Decimal.from(0)
  );
  const [netDebt, setNetDebt] = useState<Decimal>(vault.netDebt);
  const [netDebtEntered, setNetDebtEntered] = useState<Decimal>(
    Decimal.from(0)
  );
  const transactionState = useMyTransactionState(TRANSACTION_ID);
  const borrowingRate = fees.borrowingRate();

  useEffect(() => {
    // console.log(transactionState.type);
    // alert(collateral.toString());
    if (transactionState.type === "confirmedOneShot") {
      dispatchEvent("VAULT_ADJUSTED");
      // const [, rerender] = useReducer(() => ({}), {});
      // rerender();

      // reset();
    }
    if (transactionState.type === "idle") {
      // dispatchEvent("VAULT_ADJUSTED");
      // const [, rerender] = useReducer(() => ({}), {});
      // rerender();

      reset();
    }
  }, [transactionState.type, dispatchEvent]);

  useEffect(() => {
    reset();
  }, [currentTab]);

  // useEffect(() => {
  //   if (!previousVault.current.collateral.eq(vault.collateral)) {
  //     const unsavedChanges = Difference.between(
  //       collateral,
  //       previousVault.current.collateral
  //     );
  //     const nextCollateral = applyUnsavedCollateralChanges(
  //       unsavedChanges,
  //       vault
  //     );
  //     setCollateral(nextCollateral);
  //     setCollateralEntered(nextCollateral.add(collateralEntered));
  //   }
  //   if (!previousVault.current.netDebt.eq(vault.netDebt)) {
  //     const unsavedChanges = Difference.between(
  //       netDebt,
  //       previousVault.current.netDebt
  //     );
  //     const nextNetDebt = applyUnsavedNetDebtChanges(unsavedChanges, vault);
  //     setNetDebt(nextNetDebt);
  //     setNetDebtEntered(nextNetDebt.add(netDebtEntered));
  //   }
  // }, [vault, collateral, netDebt]);

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_VAULT_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(vault.collateral);
    setCollateralEntered(Decimal.from(0));
    setNetDebt(vault.netDebt);
    setNetDebtEntered(Decimal.from(0));
  }, [vault.collateral, vault.netDebt]);

  const isDirty =
    !collateral.eq(vault.collateral) || !netDebt.eq(vault.netDebt);
  const isDebtIncrease = netDebt.gt(vault.netDebt);
  const debtIncreaseAmount = isDebtIncrease
    ? netDebt.sub(vault.netDebt)
    : Decimal.ZERO;

  const fee = isDebtIncrease
    ? feeFrom(
        vault,
        new Vault(vault.collateral, vault.debt.add(debtIncreaseAmount)),
        borrowingRate
      )
    : Decimal.ZERO;
  const totalDebt = netDebt.add(USDL_LIQUIDATION_RESERVE).add(fee);
  const totalDebtAcutal = vault.netDebt.add(USDL_LIQUIDATION_RESERVE).add(fee);

  const maxBorrowingRate = borrowingRate.add(0.005);
  const updatedVault = isDirty ? new Vault(collateral, totalDebt) : vault;
  const feePct = new Percent(borrowingRate);
  const availableEth = accountBalance.gt(GAS_ROOM_PLS)
    ? accountBalance.sub(GAS_ROOM_PLS)
    : Decimal.ZERO;
  const maxCollateral = vault.collateral.add(availableEth);

  const collateralMaxedOut = collateral.eq(maxCollateral);
  const collateralRatio =
    !collateral.isZero && !netDebt.isZero
      ? updatedVault.collateralRatio(price)
      : undefined;
  const collateralRatioChange = Difference.between(
    collateralRatio,
    vault.collateralRatio(price)
  );

  const [vaultChange, description] = validateVaultChange(
    vault,
    updatedVault,
    borrowingRate,
    validationContext
  );

  const stableVaultChange = useStableVaultChange(vaultChange);
  const [gasEstimationState, setGasEstimationState] =
    useState<GasEstimationState>({ type: "idle" });

  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  if (vault.status !== "open") {
    return null;
  }

  return (
    <Flex
      sx={{
        gap: "20px",
        flexDirection: ["column", "row"],
      }}
    >
      <Card sx={{ width: "100%", minHeight: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Vault</Heading>

        <Card sx={{ p: [2, 3] }} variant="main_card">
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            disabledTab={
              view === "LIQUIDATED" || view == "REDEEMED" ? "Repay" : undefined
            }
          />
          {currentTab === "Borrow" ? (
            <EditableRow
              label="DEPOSIT PLS"
              inputId="vault-collateral"
              amount={collateralEntered.prettify(2)}
              maxAmount={maxCollateral.toString()}
              maxedOut={collateralMaxedOut}
              editingState={editingState}
              unit=""
              editedAmount={collateralEntered.toString(2)}
              setEditedAmount={(amount: string) => {
                setCollateral(vault.collateral.add(Decimal.from(amount)));
                setCollateralEntered(Decimal.from(amount));
                setNetDebtEntered(
                  Decimal.from(amount)
                    .mul(price)
                    .div(
                      !collateral.isZero && !netDebt.isZero
                        ? vault.collateralRatio(price)
                        : Decimal.from(1.1546)
                    )
                );
                setNetDebt(
                  vault.netDebt.add(
                    Decimal.from(amount)
                      .mul(price)
                      .div(
                        !collateral.isZero && !netDebt.isZero
                          ? vault.collateralRatio(price)
                          : Decimal.from(1.1546)
                      )
                  )
                );

                console.log("entered", collateralEntered);
                console.log(
                  "vault.collateralRatio(price)",
                  vault.collateralRatio(price)
                );
              }}
            />
          ) : (
            <EditableRow
              label="REPAY USDL"
              inputId="vault-net-debt-amount"
              amount={netDebtEntered.prettify()}
              unit={COIN}
              editingState={editingState}
              editedAmount={netDebtEntered.toString(2)}
              setEditedAmount={(amount: string) => {
                setNetDebt(vault.netDebt.sub(Decimal.from(amount)));
                setNetDebtEntered(Decimal.from(amount).gt(vault.netDebt) ? Decimal.from(vault.netDebt): Decimal.from(amount));
                setCollateralEntered(vault.netDebt.toString(2) == amount ? Decimal.from(vault.collateral) : Decimal.from(0));
              }}
            />
          )}

          {currentTab === "Borrow"
            ? (
                [
                  ["PLS", accountBalance],
                  // [COIN, usdlBalance],
                  // [GT, loanBalance],
                ] as const
              ).map(([currency, balance], i) => (
                <Flex
                  key={i}
                  sx={{
                    my: 2,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Titillium Web, sans-serif",
                    }}
                  >
                    Wallet : {balance.prettify()} {currency}
                  </Text>
                </Flex>
              ))
            : (
                [
                  // ["PLS", accountBalance],
                  [COIN, usdlBalance],
                  // [GT, loanBalance],
                ] as const
              ).map(([currency, balance], i) => (
                <Flex
                  key={i}
                  sx={{
                    my: 2,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Titillium Web, sans-serif",
                    }}
                  >
                    Wallet : {balance.prettify()} {currency}
                  </Text>
                </Flex>
              ))}
          <PercentageSelector
            value={
              currentTab === "Borrow"
                ? accountBalance
                : usdlBalance.gt(vault.netDebt)
                ? vault.netDebt
                : usdlBalance
            }
            setFinalValue={(amount: Decimal) => {
              if (currentTab === "Borrow") {
                setCollateral(vault.collateral.add(amount));
                setCollateralEntered(Decimal.from(amount));
                setNetDebtEntered(
                  amount
                    .mul(price)
                    .div(
                      !collateral.isZero && !netDebt.isZero
                        ? vault.collateralRatio(price)
                        : Decimal.from(1.1546)
                    )
                );
                setNetDebt(
                  vault.netDebt.add(
                    amount
                      .mul(price)
                      .div(
                        !collateral.isZero && !netDebt.isZero
                          ? vault.collateralRatio(price)
                          : Decimal.from(1.1546)
                      )
                  )
                );
              } else {
                setNetDebt(vault.netDebt.sub(amount));
                setNetDebtEntered(amount);
                setCollateralEntered(vault.netDebt.eq(amount) ? Decimal.from(vault.collateral) : Decimal.from(0));            
              }
            }}
          />

          {/* <EditableRow
            label="Net debt"
            inputId="vault-net-debt-amount"
            amount={netDebt.prettify()}
            unit={COIN}
            editingState={editingState}
            editedAmount={netDebt.toString(2)}
            setEditedAmount={(amount: string) =>
              setNetDebt(Decimal.from(amount))
            }
          /> */}
          {currentTab === "Borrow" ? (
            <EditableRow
              label="BORROW USDL"
              inputId="vault-net-debt-amount"
              amount={netDebtEntered.prettify(2)}
              unit={COIN}
              editingState={editingState}
              editedAmount={netDebtEntered.toString(2)}
              setEditedAmount={(amount: string) => {
                setNetDebt(vault.netDebt.add(Decimal.from(amount)));
                setNetDebtEntered(Decimal.from(amount));
              }}
            />
          ) : (
            <EditableRow
              label="WITHDRAW PLS"
              inputId="vault-collateral"
              amount={collateralEntered.prettify(2)}
              maxAmount={maxCollateral.toString()}
              maxedOut={collateralMaxedOut}
              editingState={editingState}
              unit=""
              editedAmount={collateralEntered.toString(2)}
              setEditedAmount={(amount: string) => {
                setCollateral(vault.collateral.sub(Decimal.from(amount)));
                setCollateralEntered(Decimal.from(amount));
                console.log("entered", collateralEntered);
              }}
            />
          )}

          {currentTab === "Borrow"
            ? (
                [
                  // ["PLS", accountBalance],
                  [COIN, usdlBalance],
                  // [GT, loanBalance],
                ] as const
              ).map(([currency, balance], i) => (
                <Flex
                  key={i}
                  sx={{
                    my: 2,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Titillium Web, sans-serif",
                    }}
                  >
                    Wallet : {balance.prettify()} {currency}
                  </Text>
                </Flex>
              ))
            : (
                [
                  ["PLS", accountBalance],
                  // [COIN, usdlBalance],
                  // [GT, loanBalance],
                ] as const
              ).map(([currency, balance], i) => (
                <Flex
                  key={i}
                  sx={{
                    my: 2,
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Titillium Web, sans-serif",
                    }}
                  >
                    Wallet : {balance.prettify()} {currency}
                  </Text>
                </Flex>
              ))}
          {view === "LIQUIDATED" || view == "REDEEMED" ? (
            view === "LIQUIDATED" ? (
              <InfoMessage title="Your Vault has been liquidated.">
                {hasSurplusCollateral
                  ? "Please reclaim your remaining collateral before opening a new Vault."
                  : "You can borrow USDL by opening a Vault."}
              </InfoMessage>
            ) : (
              <InfoMessage title="Your Vault has been redeemed.">
                {hasSurplusCollateral
                  ? "Please reclaim your remaining collateral before opening a new Vault."
                  : "You can borrow USDL by opening a Vault."}
              </InfoMessage>
            )
          ) : // Below is the Case Checking where The entered Repay Amount is equal to Total Debt. The Case for Closing Vault

          currentTab === "Repay" &&
            ((vault.netDebt.lt(netDebtEntered.add(Decimal.from(4))) &&
              netDebtEntered.gt(Decimal.from(4)) &&
              vault.netDebt.gt(netDebtEntered.sub(Decimal.from(4)))) ||
              (netDebt.isZero && !netDebtEntered.isZero)) ? (
            <VaultManager
              //  {...props}
              collateral={Decimal.ZERO}
              debt={Decimal.ZERO}
            />
          ) : (
            <>
              {description ?? (
                <ActionDescription>
                  Adjust your Vault by modifying its collateral, debt, or both.
                </ActionDescription>
              )}

              <Flex sx={{ alignItems: "center" }}>
                {collateralRatio?.lt(1.5) && (
                  <ActionDescription>
                    Keeping your CR above 150% can help avoid liquidation under
                    Recovery Mode.
                  </ActionDescription>
                )}
                {isDirty && !isTransactionPending && (
                  <Button
                    variant="titleIcon"
                    sx={{ ":enabled:hover": { color: "danger" } }}
                    onClick={reset}
                  >
                    <Icon name="history" size="lg" />
                  </Button>
                )}
              </Flex>

              <ExpensiveVaultChangeWarning
                vaultChange={stableVaultChange}
                maxBorrowingRate={maxBorrowingRate}
                borrowingFeeDecayToleranceMinutes={60}
                gasEstimationState={gasEstimationState}
                setGasEstimationState={setGasEstimationState}
              />

              <Flex variant="layout.actions">
                {/* <Button variant="cancel" onClick={handleCancelPressed}>
              Cancel
            </Button> */}

                {stableVaultChange ? (
                  <VaultAction
                    transactionId={TRANSACTION_ID}
                    change={stableVaultChange}
                    maxBorrowingRate={maxBorrowingRate}
                    borrowingFeeDecayToleranceMinutes={60}
                  >
                    Confirm
                  </VaultAction>
                ) : (
                  <Button disabled>Confirm</Button>
                )}
              </Flex>
            </>
          )}
          {isTransactionPending && <LoadingOverlay />}
        </Card>
      </Card>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
      >
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>
            Your Transaction Stats
          </Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label={
                currentTab === "Borrow" ? "Deposit Amount" : "Withdraw Amount"
              }
              inputId="vault-liquidation-reserve"
              amount={
                currentTab === "Borrow"
                  ? `${collateralEntered.prettify(2)}`
                  : `${collateralEntered.prettify(2)}`
              }
              unit="PLS"
            />
            <StaticRow
              label={currentTab === "Borrow" ? "Borrowing Fee" : "Withdraw Fee"}
              inputId="vault-borrowing-fee"
              amount={fee.prettify(2)}
              pendingAmount={feePct.toString(2)}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      This amount is deducted from the borrowed amount as a
                      one-time fee. There are no recurring fees for borrowing,
                      which is thus interest-free.
                    </Card>
                  }
                />
              }
            />

            <StaticRow
              label="Liquidation Reserve"
              inputId="vault-liquidation-reserve"
              amount={`0`}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "200px" }}>
                      An amount set aside to cover the liquidator’s gas costs if
                      your Vault needs to be liquidated. The amount increases
                      your debt and is refunded if you close your Vault by fully
                      paying off its net debt.
                    </Card>
                  }
                />
              }
            />

            <StaticRow
              label={currentTab === "Borrow" ? "Borrow Amount" : "Repay Amount"}
              inputId="vault-liquidation-reserve"
              amount={`${netDebtEntered.prettify()}`}
              unit={COIN}
            />

            <StaticRow
              label={
                currentTab === "Borrow"
                  ? "Total Transaction debt"
                  : "Total Transaction Amount"
              }
              inputId="vault-total-debt"
              amount={netDebtEntered.prettify(2)}
              unit={COIN}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      The total amount of USDL your Vault will hold.{" "}
                      {isDirty && (
                        <>
                          You will need to repay{" "}
                          {totalDebt.sub(USDL_LIQUIDATION_RESERVE).prettify(2)}{" "}
                          USDL to reclaim your collateral (
                          {USDL_LIQUIDATION_RESERVE.toString()} USDL Liquidation
                          Reserve excluded).
                        </>
                      )}
                    </Card>
                  }
                />
              }
            />
          </Card>
        </Card>
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Vault Stats</Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label="Total Balance (PLS)"
              inputId="vault-liquidation-reserve"
              amount={`${collateral.prettify(2)}`}
              unit="PLS"
            />
            <StaticRow
              label="Total Debt (USDL)"
              inputId="vault-borrowing-fee"
              amount={
                currentTab === "Repay" &&
                netDebt.isZero &&
                !netDebtEntered.isZero
                  ? "0.00"
                  : totalDebt.prettify(2)
              }
              // pendingAmount={
              //   currentTab === "Repay" &&
              //   netDebt.isZero &&
              //   !netDebtEntered.isZero
              //     ? undefined
              //     : totalDebt.toString(2)
              // }
              unit={COIN}
              // infoIcon={
              //   <InfoIcon
              //     tooltip={
              //       <Card variant="tooltip" sx={{ width: "240px" }}>
              //         Total USDL Borrowed
              //       </Card>
              //     }
              //   />
              // }
            />

            <CollateralRatio value={collateralRatio} />

            <StaticRow
              label="Liquidation Price (Normal Mode)"
              inputId="vault-liquidation-reserve"
              amount={
                !collateral.isZero
                  ? totalDebt
                    .mul(Decimal.from(110))
                    .div(collateral.mul(Decimal.from(100)))
                    .toString(8)
                  : "0.00"
              }
              unit="USD"
            />

            <StaticRow
              label="Liquidation Price (Recovery Mode)"
              inputId="vault-total-debt"
              amount={
                !collateral.isZero
                  ? totalDebt
                    .mul(Decimal.from(150))
                    .div(collateral.mul(Decimal.from(100)))
                    .toString(8)
                  : "0.00"
              }
              unit="USD"
            />
            {(view === "LIQUIDATED" || view == "REDEEMED") &&
              hasSurplusCollateral && <CollateralSurplusAction />}
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
