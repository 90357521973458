import React, { useEffect, useRef, useState } from "react";
import { Text, Flex, Box, Heading, Button, Image, Container } from "theme-ui";

import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { COIN, GT } from "../strings";
import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import { shortenAddress } from "../utils/shortenAddress";
import walletImage from "../assets/images/wallet-icon.svg";

import { Icon } from "./Icon";
import { Modal } from "./Modal";
import { AiOutlineClose } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { useWeb3React } from "@web3-react/core";

const select = ({
  accountBalance,
  usdlBalance,
  loanBalance,
}: LiquidLoansStoreState) => ({
  accountBalance,
  usdlBalance,
  loanBalance,
});

export const UserAccount: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { account } = useLiquidLoans();
  const { accountBalance, usdlBalance, loanBalance } =
    useLiquidLoansSelector(select);
  const modalRef = useRef<HTMLDivElement>(null);

  const { deactivate } = useWeb3React<unknown>();

  // Handle click outside the modal
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      handleModalToggle();
    }
  };

  const handleModalToggle = () => {
    setModalOpen((prev) => !prev);
  };

  useEffect(() => {
    // Add event listener when the modal is opened
    if (modalOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    // Remove event listener when the modal is closed
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [modalOpen]);

  return (
    <>
      <Button
        sx={{ fontFamily: "Iceland , Sans", borderRadius: "10px" }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <Flex sx={{ gap: 3 }}>
          <Image src={walletImage} />
          {shortenAddress(account)}
        </Flex>
      </Button>
      {modalOpen && (
        <Container
          variant="modalOverlay"

          // onClick={() => {
          //   setModalOpen(false);
          // }}
        >
          <Container variant="modal" ref={modalRef} sx={{ zIndex: 20 }}>
            <Flex sx={{ flexDirection: "column", gap: 2 }}>
              <Button
                sx={{ mr: 0, ml: "auto" }}
                variant="icon"
                onClick={() => {
                  handleModalToggle();
                }}
              >
                <AiOutlineClose />
              </Button>
              <Text sx={{ textAlign: "center", fontSize: 4 }}>
                Your Account
              </Text>
              <Flex
                onClick={() => {
                  navigator.clipboard.writeText(account);
                }}
                sx={{
                  margin: "auto",
                  cursor: "pointer",
                  ":hover": { color: "violet" },
                }}
              >
                <Text sx={{ textAlign: "center" }}>{account}</Text>
                <BiCopy />
              </Flex>

              <Button
                sx={{ mr: 0, ml: "auto", mt: 4 }}
                onClick={() => {
                  deactivate();
                }}
              >
                Disconnect
              </Button>
            </Flex>
          </Container>
        </Container>
      )}
    </>
  );
};
