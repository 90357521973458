import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Provider } from "@ethersproject/abstract-provider";
import { getNetwork } from "@ethersproject/networks";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

import {
  isBatchedProvider,
  isWebSocketAugmentedProvider,
} from "@liquidloans/providers";
import {
  BlockPolledLiquidLoansStore,
  EthersLiquidLoans,
  EthersLiquidLoansWithStore,
  _connectByChainId,
} from "@liquidloans/lib-ethers";

import { LiquidLoansFrontendConfig, getConfig } from "../config";

type LiquidLoansContextValue = {
  config: LiquidLoansFrontendConfig;
  account: string;
  provider: Provider;
  liquidLoans: EthersLiquidLoansWithStore<BlockPolledLiquidLoansStore>;
};

const LiquidLoansContext = createContext<LiquidLoansContextValue | undefined>(
  undefined
);

type LiquidLoansProviderProps = {
  loader?: React.ReactNode;
  unsupportedNetworkFallback?: (
    chainId: number,
    channel?: string
  ) => React.ReactNode;
  unsupportedMainnetFallback?: React.ReactNode;
};

const wsParams = (network: string, infuraApiKey: string): [string, string] => {
  
  return [
    `wss://${
      network === "homestead" ? "mainnet" : network
    }.infura.io/ws/v3/${infuraApiKey}`,
    network,
  ]
};

const webSocketSupportedNetworks = [
  "homestead",
  "kovan",
  "rinkeby",
  "ropsten",
  "goerli",
  "mumbai",
  "pulsechain"
];

export const LiquidLoansProvider: React.FC<LiquidLoansProviderProps> = ({
  children,
  loader,
  unsupportedNetworkFallback,
  unsupportedMainnetFallback,
}) => {
  const { library: provider, account, chainId } = useWeb3React<Web3Provider>();
  const [config, setConfig] = useState<LiquidLoansFrontendConfig>();

  const connection = useMemo(() => {
    if (config && provider && account && chainId) {
      try {
        return _connectByChainId(
          provider,
          provider.getSigner(account),
          chainId,
          {
            userAddress: account,
            frontendTag: config.frontendTag,
            useStore: "blockPolled",
            channel: process.env.REACT_APP_ENVIRONMENT || "prod",
          }
        );
      } catch (error) {
        console.error("Error creating connection:", error);
        // throw error;
      }
    }
  }, [config, provider, account, chainId]);

  useEffect(() => {
    getConfig().then(setConfig);
  }, []);

  useEffect(() => {
    if (config && connection) {
      const { provider, chainId } = connection;

      if (isBatchedProvider(provider) && provider.chainId !== chainId) {
        provider.chainId = chainId;
      }

      if (isWebSocketAugmentedProvider(provider)) {
        const network = getNetwork(chainId);

        if (
          network.name &&
          webSocketSupportedNetworks.includes(network.name) &&
          config.infuraApiKey
        ) {
          provider.openWebSocket(
            ...wsParams(network.name, config.infuraApiKey)
          );
        } else if (connection._isDev) {
          provider.openWebSocket(
            `ws://${window.location.hostname}:8546`,
            chainId
          );
        }

        return () => {
          provider.closeWebSocket();
        };
      }
    }
  }, [config, connection]);

  if (!config || !provider || !account || !chainId) {
    console.warn("Config, provider, account, or chainId is missing.");
    return <>{loader}</>;
  }

  if (config.testnetOnly && chainId === 369) {
    console.warn("Unsupported mainnet. Displaying mainnet fallback.");
    return <>{unsupportedMainnetFallback}</>;
  }

  if (!connection || (connection?.channel === "local" && connection?.chainId !== 369)) {
    if (unsupportedNetworkFallback) {
      console.warn(
        "Unsupported network. Displaying unsupported network fallback.",
        chainId,
        connection?.channel
      );
      return <>{unsupportedNetworkFallback(chainId, connection?.channel)}</>;
    }
    return null;
  }

  const liquidLoans = EthersLiquidLoans._from(connection);
  liquidLoans.store.logging = true;

  return (
    <LiquidLoansContext.Provider
      value={{ config, account, provider, liquidLoans }}
    >
      {children}
    </LiquidLoansContext.Provider>
  );
};


export const useLiquidLoans = () => {
  const liquidLoansContext = useContext(LiquidLoansContext);

  if (!liquidLoansContext) {
    throw new Error(
      "You must provide a LiquidLoansContext via LiquidLoansProvider"
    );
  }

  return liquidLoansContext;
};
