import React, { useState } from "react";
import { VaultManager } from "./VaultManager";
import { ReadOnlyVault } from "./ReadOnlyVault";
import { NoVault } from "./NoVault";
import { Opening } from "./Opening";
import { Adjusting } from "./Adjusting";
import { RedeemedVault } from "./RedeemedVault";
import { useVaultView } from "./context/VaultViewContext";
import { LiquidatedVault } from "./LiquidatedVault";
import { Decimal } from "@liquidloans/lib-base";

export const Vault: React.FC = (props) => {
  const { view } = useVaultView();
  console.log(view);
  const [currentTab, setCurrentTab] = useState("Borrow");
  const tabs = ["Borrow", "Repay"];
  switch (view) {
    // loading state not needed, as main app has a loading spinner that blocks render until the liquidLoans backend data is available
    // Here for NONE & OPENING view states, OPENING Component will be rendered and for Remaining, Adjusting Component will be rendered except for liquidated & redeemed.
    case "ACTIVE": {
      return (
        <Adjusting
          {...props}
          view={"ACTIVE"}
          // view={"REDEEMED"}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "ADJUSTING": {
      return (
        <Adjusting
          {...props}
          tabs={tabs}
          view={"ADJUSTING"}
          // view={"LIQUIDATED"}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "CLOSING": {
      return (
        <Adjusting
          {...props}
          tabs={tabs}
          view={"ADJUSTING"}
          // view={"LIQUIDATED"}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "OPENING": {
      return (
        <Opening
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
    case "LIQUIDATED": {
      return <LiquidatedVault {...props} />;
    }
    case "REDEEMED": {
      return <RedeemedVault {...props} />;
    }
    case "NONE": {
      return (
        <Opening
          {...props}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
    }
  }
};
