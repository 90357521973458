import React, { useEffect, useState } from "react";
import { Heading, Box, Card, Button, Flex, Text } from "theme-ui";
import { ActionDescription } from "../ActionDescription";

import {
  selectForStabilityDepositChangeValidation,
  validateStabilityDepositChange,
} from "./validation/validateStabilityDepositChange";

import { useMyTransactionState } from "../Transaction";
import {
  Decimal,
  Decimalish,
  StabilityDeposit,
  LiquidLoansStoreState,
  Difference,
} from "@liquidloans/lib-base";

import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { COIN, GT } from "../../strings";

import { Icon } from "../Icon";
import { EditableRow, StaticRow } from "../Vault/Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { InfoIcon } from "../InfoIcon";
import Tabs from "../Tabs";
import PercentageSelector from "../PercentageSelector";
import { ClaimRewards } from "./actions/ClaimRewards";
import { fetchStabilityAPR } from "../../graphFunctions/graphFetcher";

const select = ({
  usdlBalance,
  usdlInStabilityPool,
  stabilityDeposit,
  vault,
}: LiquidLoansStoreState) => ({
  usdlBalance,
  usdlInStabilityPool,
  stabilityDeposit,
  vault,
});

type StabilityDepositEditorProps = {
  originalDeposit: StabilityDeposit;
  editedUSD: Decimal;
  changePending: boolean;
  dispatch: (
    action: { type: "setDeposit"; newValue: Decimalish } | { type: "revert" }
  ) => void;
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
};

const selectPrice = ({ price }: LiquidLoansStoreState) => price;

export const StabilityDepositEditor: React.FC<StabilityDepositEditorProps> = ({
  originalDeposit,
  editedUSD,
  changePending,
  dispatch,
  children,
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { usdlBalance, usdlInStabilityPool, stabilityDeposit, vault } =
    useLiquidLoansSelector(select);
  const editingState = useState<string>();
  const price = useLiquidLoansSelector(selectPrice);
  const validationContext = useLiquidLoansSelector(
    selectForStabilityDepositChangeValidation
  );
  const [enteredUSD, setEnteredUSD] = useState<Decimal>(Decimal.from(0));
  const edited = !editedUSD.eq(stabilityDeposit.currentUSDL);

  const maxAmount = stabilityDeposit.currentUSDL.add(usdlBalance);
  const maxedOut = editedUSD.eq(maxAmount);

  // const ethInUsd = originalDeposit.currentUSDL.sub(
  //   stabilityDeposit.currentUSDL
  // );

  const originalPoolShare = stabilityDeposit.currentUSDL.mulDiv(
    100,
    usdlInStabilityPool
  );

  const { bammPoolShare, collateralGain } = stabilityDeposit;

  const userTotalUsdInBamm = stabilityDeposit.currentUSDL;
  const totalUsdInBamm = userTotalUsdInBamm.mulDiv(100, bammPoolShare);
  const editedUserUsd = userTotalUsdInBamm
    .sub(stabilityDeposit.currentUSDL)
    .add(editedUSD);
  const editedTotalUsdInBamm = totalUsdInBamm
    .sub(stabilityDeposit.currentUSDL)
    .add(editedUSD);
  const editedBammPoolShare = !stabilityDeposit.currentUSD.isZero
    ? editedUserUsd.mulDiv(100, editedTotalUsdInBamm)
    : enteredUSD.mulDiv(100, usdlInStabilityPool);

  /* USD balance
  ====================================================================*/
  const usdDiff = Difference.between(editedUSD, stabilityDeposit.currentUSDL);

  const bammPoolShareChange =
    stabilityDeposit.currentUSDL.nonZero &&
    Difference.between(editedBammPoolShare, bammPoolShare).nonZero;

  let newTotalLusd, newTotalEth;
  if (
    (bammPoolShareChange && !bammPoolShareChange?.nonZero) ||
    bammPoolShareChange?.positive
  ) {
    newTotalLusd = stabilityDeposit.totalLusdInBamm.add(
      Decimal.from(usdDiff.absoluteValue || 0)
    );
    newTotalEth = stabilityDeposit.totalEthInBamm;
  } else {
    newTotalLusd = stabilityDeposit.totalLusdInBamm.mul(
      editedTotalUsdInBamm.div(totalUsdInBamm)
    );
    newTotalEth = stabilityDeposit.totalEthInBamm.mul(
      editedTotalUsdInBamm.div(totalUsdInBamm)
    );
  }

  const allowanceTxState = useMyTransactionState("bamm-unlock");
  const waitingForTransaction =
    allowanceTxState.type === "waitingForApproval" ||
    allowanceTxState.type === "waitingForConfirmation";

  /* ETH balance
  ====================================================================*/
  const newEthBalance = editedBammPoolShare.mul(newTotalEth).div(100);
  const ethDiff = Difference.between(
    newEthBalance,
    stabilityDeposit.collateralGain
  ).nonZero;

  /* LUSD balance
  ====================================================================*/
  const newLusdBalance = editedBammPoolShare.mul(newTotalLusd).div(100);
  const usdlDiff = Difference.between(
    newLusdBalance,
    stabilityDeposit.currentUSDL
  ).nonZero;

  const [, description] = validateStabilityDepositChange(
    stabilityDeposit,
    editedUSD,
    validationContext,
    usdlDiff,
    ethDiff
  );
  const makingNewDeposit = stabilityDeposit.isEmpty;

  const hasReward = !stabilityDeposit.loanReward.isZero;
  const hasGain = !stabilityDeposit.collateralGain.isZero;
  const hasVault = !vault.isEmpty;

  // console.log("Original Deposit", originalDeposit);

  /* pool share
  ====================================================================*/
  const usdlInStabilityPoolAfterChange = usdlInStabilityPool
    .add(newTotalLusd)
    .sub(stabilityDeposit.totalLusdInBamm);

  const newPoolShare = newTotalLusd
    .mulDiv(editedBammPoolShare, 100)
    .mulDiv(100, usdlInStabilityPoolAfterChange);
  const poolShareChange =
    stabilityDeposit.currentUSDL.nonZero &&
    Difference.between(newPoolShare, originalPoolShare).nonZero;

  // const ethDiffInUsd = stabilityDeposit.currentUSDL.sub(
  //   stabilityDeposit.currentUSDL
  // );
  // const ethIsImportant = ethDiffInUsd
  //   .div(stabilityDeposit.currentUSDL)
  //   .gt(1 / 1000);

  const showOverlay = changePending || waitingForTransaction;
  const showResetButton = edited && !showOverlay;

  const transactionState = useMyTransactionState("stability-deposit");

  const [apr, setAPR] = useState(0);

  useEffect(() => {
    getStabilityAPR();
  }, []);

  const getStabilityAPR = async () => {
    let apr = await fetchStabilityAPR();
    setAPR(apr.stabilityAPR);
  };

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      // dispatch({ type: "revert" });
      dispatch({ type: "setDeposit", newValue: stabilityDeposit.currentUSDL });
      setEnteredUSD(Decimal.from(0));
    }
  }, [transactionState.type, dispatch]);

  useEffect(() => {
    dispatch({ type: "setDeposit", newValue: stabilityDeposit.currentUSDL });
  }, [stabilityDeposit.currentUSDL]);

  useEffect(() => {
    // dispatch({ type: "revert" });
    dispatch({ type: "setDeposit", newValue: stabilityDeposit.currentUSDL });

    setEnteredUSD(Decimal.from(0));
  }, [currentTab]);

  const [renderCount, setRenderCount] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderCount(prevCount => prevCount + 1);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Flex sx={{ gap: "20px", flexDirection: ["column", "row"] }}>
      <Card sx={{ width: "100%", minHeight: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>Stake USDL</Heading>

        <Card sx={{ p: [2, 3] }} variant="main_card">
          {showOverlay && <LoadingOverlay />}
          <Flex sx={{ gap: "30px" }}>
            <Tabs
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              disabledTab={makingNewDeposit ? "Unstake" : undefined}
            />
            <Flex sx={{ flexDirection: "column", minWidth: "fit-content" }}>
              <Text sx={{ color: "grey", whiteSpace: "nowrap" }}>
                Current APR
              </Text>
              <Text sx={{ color: "white" }}>{apr.toFixed(2)}%</Text>
            </Flex>
          </Flex>

          <EditableRow
            label={currentTab === "Stake" ? "STAKE USDL" : "UNSTAKE USDL"}
            inputId="deposit-usdl"
            amount={enteredUSD.prettify()}
            maxAmount={maxAmount.toString()}
            maxedOut={maxedOut}
            unit={COIN}
            {...{ editingState }}
            editedAmount={enteredUSD.toString(2)}
            setEditedAmount={(newValue) => {
              setEnteredUSD(Decimal.from(newValue));
              dispatch({
                type: "setDeposit",
                newValue: makingNewDeposit
                  ? Decimal.from(newValue)
                  : currentTab === "Stake"
                  ? Decimal.from(newValue).add(stabilityDeposit.currentUSDL)
                  : Decimal.from(newValue).gte(stabilityDeposit.currentUSDL)
                  ? Decimal.from(0)
                  : stabilityDeposit.currentUSDL.sub(Decimal.from(newValue)),
              });
            }}
          />
          {(
            [
              // ["PLS", accountBalance],
              [COIN, usdlBalance],
              // [GT, loanBalance],
            ] as const
          ).map(([currency, balance], i) => (
            <Flex
              key={i}
              sx={{ my: 2, flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  fontFamily: "Titillium Web, sans-serif",
                }}
              >
                {currentTab === "Stake" ? "Wallet" : "Staked"} :{" "}
                {currentTab === "Stake"
                  ? balance.prettify()
                  : stabilityDeposit.currentUSDL.prettify()}{" "}
                {currency}
              </Text>
            </Flex>
          ))}

          <PercentageSelector
            value={
              currentTab === "Stake"
                ? usdlBalance
                : stabilityDeposit.currentUSDL
            }
            setFinalValue={(amount: Decimal) => {
              setEnteredUSD(Decimal.from(amount));
              dispatch({
                type: "setDeposit",
                newValue:
                  currentTab === "Stake"
                    ? Decimal.from(amount).add(stabilityDeposit.currentUSDL)
                    : Decimal.from(amount).gte(stabilityDeposit.currentUSDL)
                    ? Decimal.from(0)
                    : stabilityDeposit.currentUSDL.sub(Decimal.from(amount)),
              });
            }}
          />
          <Flex>
            {description ??
              (makingNewDeposit ? (
                <ActionDescription>
                  Enter the amount of {COIN} you'd like to deposit.
                </ActionDescription>
              ) : (
                <ActionDescription>
                  Adjust the {COIN} amount to deposit or withdraw.
                </ActionDescription>
              ))}
            {showResetButton && (
              <Button
                variant="titleIcon"
                sx={{ ":enabled:hover": { color: "danger" } }}
                onClick={() => {
                  // dispatch({ type: "revert" });
                  dispatch({
                    type: "setDeposit",
                    newValue: stabilityDeposit.currentUSDL,
                  });

                  setEnteredUSD(Decimal.from(0));
                }}
              >
                <Icon name="history" size="lg" />
              </Button>
            )}
          </Flex>
          {children}
        </Card>
      </Card>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>
            Auto Rebalancing Stability Pool
          </Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label={currentTab === "Stake" ? "Your New Stake" : "Your Unstake"}
              inputId="deposit-gain"
              amount={enteredUSD.prettify(2)}
              unit="USDL"
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />

            <StaticRow
              label={"Your Existing Stakes"}
              inputId="deposit-gain"
              amount={stabilityDeposit.currentUSDL.prettify()}
              unit={COIN}
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />

            <StaticRow
              label={"Your Total Staking Balance"}
              inputId="deposit-gain"
              amount={
                currentTab === "Stake"
                  ? stabilityDeposit.currentUSDL.add(enteredUSD).prettify()
                  : // : stabilityDeposit.currentUSDL.sub(enteredUSD).prettify()
                  Decimal.from(enteredUSD).gte(stabilityDeposit.currentUSDL)
                  ? Decimal.from(0).prettify()
                  : stabilityDeposit.currentUSDL
                      .sub(Decimal.from(enteredUSD))
                      .prettify()
              }
              unit={COIN}
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />

            {/* <StaticRow
              label="USDL balance"
              inputId="deposit-gain"
              amount={newLusdBalance.prettify(2)}
              unit="LUSD"
              pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              pendingColor={usdlDiff?.positive ? "success" : "danger"}
            /> */}

            {/* {ethIsImportant && (
              <StaticRow
                label="ETH balance"
                inputId="deposit-gain"
                amount={newEthBalance.prettify(2)}
                unit="ETH"
                pendingAmount={ethDiff?.prettify(2).concat("ETH")}
                pendingColor={ethDiff?.positive ? "success" : "danger"}
                infoIcon={
                  <InfoIcon
                    tooltip={
                      <Card variant="tooltip" sx={{ width: "240px" }}>
                        Temporary ETH balance until rebalance takes place
                      </Card>
                    }
                  />
                }
              />
            )} */}

            {/* {newPoolShare.infinite ? (
              <StaticRow
                label="Your Pool share"
                inputId="deposit-share"
                amount="N/A"
              />
            ) : (
              <StaticRow
                label="Your Pool share"
                inputId="deposit-share"
                amount={newPoolShare.prettify(2)}
                pendingAmount={poolShareChange?.prettify(2).concat("%")}
                pendingColor={poolShareChange?.positive ? "success" : "danger"}
                unit="%"
              />
            )} */}

            {
              <div className="hide">
                {bammPoolShare.infinite ? (
                  <StaticRow
                    label="Barista Pool share"
                    inputId="deposit-share"
                    amount="N/A"
                  />
                ) : (
                  <StaticRow
                    label="Barista Pool share"
                    inputId="deposit-share"
                    amount={editedBammPoolShare.prettify(2)}
                    pendingAmount={bammPoolShareChange?.prettify(2).concat("%")}
                    pendingColor={
                      bammPoolShareChange?.positive ? "success" : "danger"
                    }
                    unit="%"
                  />
                )}
              </div>
            }
          </Card>
        </Card>
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Rewards</Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label="Liquidation Gains"
              inputId="deposit-gain"
              amount={stabilityDeposit.collateralGain.prettify(2)}
              color={stabilityDeposit.collateralGain.nonZero && "success"}
              unit="PLS"
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      There are existing PLS and Loan gains in our system.
                      Whenever a new deposit happens in the protocol we
                      calculate the relative share and assign the corresponding
                      amount of PLS and Loan gains. So when someone deposits the
                      USDL they should use the below calculation to check their
                      claims. <br />
                      <br />
                      (Pls * priceOfPlsGain) + (USDL * $1) + (price of Loan *
                      LoanGains) = initalUSDLDeposit * 1$
                      <br />
                      <br />
                      For an example, if your deposit is 100 USDL your deposit
                      becomes 82.95 and 10.91 PLS = 82.95 + (10.91 * 1.561334) +
                      (0.025615 * 0.1891791) = $100 equivalent to your deposit.
                    </Card>
                  }
                />
              }
            />
            <StaticRow
              label="LOAN Rewards"
              inputId="deposit-reward"
              amount={stabilityDeposit.loanReward.prettify()}
              color={stabilityDeposit.loanReward.nonZero && "success"}
              unit={GT}
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      Although the LOAN rewards accrue every minute, the value
                      on the UI only updates when a user transacts with the
                      Stability Pool. Therefore you may receive more rewards
                      than is displayed when you claim or adjust your deposit.
                    </Card>
                  }
                />
              }
            />
            <Flex variant="layout.actions">
              <ClaimRewards disabled={!hasGain && !hasReward}>
                Claim LOAN
              </ClaimRewards>
            </Flex>
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
