import { Card, Heading, Box, Flex, Button, Text } from "theme-ui";

import { GT } from "../../strings";

import { InfoMessage } from "../InfoMessage";
import { useStakingView } from "./context/StakingViewContext";
import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useTransactionFunction } from "../Transaction";

export const BeansUnlockButton: React.FC = (props) => {
  const { liquidLoans } = useLiquidLoans();
  const [sendTransaction, transactionState] = useTransactionFunction(
    "beans-unlock",
    liquidLoans.send.beansUnlock.bind(liquidLoans.send)
  );

  return (
    <Text
      onClick={sendTransaction}
      sx={{
        fontWeight: "bold",
        whiteSpace: "nowrap",
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      {props.children}
    </Text>
  );
};

export const NoStake: React.FC = () => {
  const { dispatch } = useStakingView();

  return (
    <Card>
      <Heading>Staking</Heading>
      <Card sx={{ p: [2, 3] }} variant="main_card">
        <InfoMessage title={`You haven't staked ${GT} yet.`}>
          Stake {GT} to earn a share of borrowing and redemption fees.
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>
            Start staking
          </Button>
        </Flex>
      </Card>
    </Card>
  );
};
