import React from "react";
import { Container, Spinner } from "theme-ui";

export const LoadingOverlay: React.FC = () => (
  <Container
    variant="disabledOverlay"
    sx={{
      // m: "14px",
      // p: "24px",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spinner size="28px" sx={{ color: "text" }} />
  </Container>
);
