import React from "react";
import { Box, Flex, Image } from "theme-ui";
import { Link } from "./Link";
import { Icon } from "./Icon";
import { ReactComponent as CustomIcon } from "../assets/images/nav-icons/start-here.svg";
import BaristaLogo from "../assets/images/barista-logo.png";

type Props = {};

const link_style = {
  borderRadius: "10px",
  border: "2px solid transparent",
  color: "grey",

  "&.active , :hover": {
    color: "white",
    background: [
      "#4F0E7585",
      null,
      null,
      "linear-gradient(black, black) padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box",
    ],
    borderRadius: "10px",
    border: "2px solid transparent",
    ".rainbow-svg": {
      fill: "transparent",
      stroke: "url(#gradient)",
    },
  },
};

function MenuNavLinks({}: Props) {
  const navLinks = [
    // { title: "Start Here", link: "/", icon: <StartHere /> },
    { title: "Borrow USDL", link: "/", icon: <BorrowUSDL /> },
    {
      title: "Stability Pool",
      link: "/stability-pool",
      icon: <StabilityPool />,
    },
    { title: "Staking Pool", link: "/staking-pool", icon: <StakingPool /> },
    { title: "Redemptions", link: "/redemptions", icon: <Redemption /> },
    { title: "Liquidation", link: "/liquidation", icon: <Liqiuidation /> },
    { title: "Knowledge Base", link: "/knowledge", icon: <Tutorials /> },
  ];
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: ["30px", "10px", "5px", "20px"],
        py: 2,
        gap: [2, null, null, 2],
      }}
    >
      {navLinks.map((item, i) => (
        <Link to={item.link} sx={link_style} key={i}>
          <Flex
            sx={{
              alignItems: "center",
              gap: "2",
              width: "170px",
              mx: "auto",
              fontSize: ["16px", null, null, "18px"],
            }}
          >
            {item.icon}
            <p style={{ margin: 0 }}>{item.title}</p>
          </Flex>
        </Link>
      ))}
    </Flex>
  );
}

export default MenuNavLinks;

const StartHere = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        // className='rainbow-svg'
        d="M9.5 22V12H15.5V22M3.5 9L12.5 2L21.5 9V20C21.5 20.5304 21.2893 21.0391 20.9142 21.4142C20.5391 21.7893 20.0304 22 19.5 22H5.5C4.96957 22 4.46086 21.7893 4.08579 21.4142C3.71071 21.0391 3.5 20.5304 3.5 20V9Z"
        stroke="#747474"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const BorrowUSDL = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        d="M17.5 1L21.5 5M21.5 5L17.5 9M21.5 5H7.5C6.43913 5 5.42172 5.42143 4.67157 6.17157C3.92143 6.92172 3.5 7.93913 3.5 9V11M7.5 23L3.5 19M3.5 19L7.5 15M3.5 19H17.5C18.5609 19 19.5783 18.5786 20.3284 17.8284C21.0786 17.0783 21.5 16.0609 21.5 15V13"
        stroke="#747474"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Liqiuidation = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        d="M9.13074 16.0609C8.33146 16.403 7.19008 16.2334 6.7087 15.852C6.58195 15.7621 6.4255 15.7244 6.27172 15.7466C6.11794 15.7689 5.97859 15.8494 5.88253 15.9715C5.78646 16.0936 5.74102 16.248 5.75561 16.4027C5.77019 16.5574 5.84369 16.7006 5.96089 16.8026C6.50932 17.1931 7.15927 17.4161 7.83192 17.4444V18.5011C7.83192 18.6617 7.89571 18.8157 8.00927 18.9292C8.12282 19.0428 8.27684 19.1066 8.43743 19.1066C8.59802 19.1066 8.75203 19.0428 8.86559 18.9292C8.97914 18.8157 9.04294 18.6617 9.04294 18.5011V17.3506C9.23587 17.3104 9.42446 17.2516 9.60606 17.175C10.0252 17.0071 10.3891 16.7253 10.6565 16.3615C10.924 15.9977 11.0845 15.5664 11.1198 15.1163C11.2924 13.3573 9.74836 12.785 8.62211 12.3642C7.44742 11.9283 6.93576 11.6739 6.95393 11.0442C6.96359 10.8523 7.02497 10.6666 7.13156 10.5067C7.23816 10.3468 7.386 10.2187 7.55944 10.1359C7.93513 9.96548 8.34813 9.8938 8.75929 9.92771C9.17045 9.96162 9.56613 10.1 9.90882 10.3297C9.9772 10.3722 10.0533 10.4009 10.1328 10.414C10.2122 10.4272 10.2935 10.4245 10.3719 10.4063C10.4504 10.388 10.5244 10.3544 10.5899 10.3075C10.6554 10.2606 10.711 10.2013 10.7535 10.1329C10.796 10.0645 10.8247 9.98844 10.8378 9.90898C10.851 9.82952 10.8483 9.74825 10.83 9.66981C10.8118 9.59138 10.7782 9.51731 10.7313 9.45184C10.6844 9.38636 10.6251 9.33077 10.5567 9.28823C10.0957 9.00109 9.58035 8.81245 9.04294 8.73419V7.6261C9.04294 7.46551 8.97914 7.3115 8.86559 7.19794C8.75203 7.08439 8.59802 7.02059 8.43743 7.02059C8.27684 7.02059 8.12282 7.08439 8.00927 7.19794C7.89571 7.3115 7.83192 7.46551 7.83192 7.6261V8.77052C7.54209 8.83035 7.2626 8.93235 7.00237 9.07327C6.63821 9.25719 6.33041 9.53595 6.11141 9.88016C5.89242 10.2244 5.77034 10.6213 5.75805 11.0291C5.70961 12.5913 7.09925 13.106 8.21642 13.5207C9.46377 13.984 10.0027 14.2655 9.93001 15.0345C9.90823 15.2639 9.82146 15.4823 9.67988 15.6641C9.5383 15.8459 9.3478 15.9835 9.13074 16.0609Z"
        fill="#747474"
      />
      <path
        className="rainbow-svg"
        d="M8.43778 23C10.6752 22.9976 12.8203 22.1079 14.4026 20.5262C15.985 18.9444 16.8754 16.7996 16.8786 14.5622C16.8786 9.28217 9.21889 0.56283 8.89191 0.193469C8.83315 0.132297 8.76263 0.0836284 8.68459 0.0503827C8.60656 0.0171371 8.5226 0 8.43778 0C8.35296 0 8.26901 0.0171371 8.19097 0.0503827C8.11293 0.0836284 8.04241 0.132297 7.98365 0.193469C7.65667 0.56283 0 9.27612 0 14.5622C0.00240419 16.7993 0.892154 18.9441 2.47402 20.526C4.05589 22.1078 6.20068 22.9976 8.43778 23ZM8.43778 1.52862C10.0878 3.49047 15.6676 10.4326 15.6676 14.5622C15.6289 16.4537 14.8503 18.2546 13.499 19.5786C12.1476 20.9025 10.3311 21.6441 8.43929 21.6441C6.54746 21.6441 4.73099 20.9025 3.37964 19.5786C2.02829 18.2546 1.24973 16.4537 1.21102 14.5622C1.21102 10.4326 6.78777 3.49047 8.43778 1.52862Z"
        fill="#747474"
      />
    </svg>
  );
};

const StabilityPool = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        d="M12.5 1V23M17.5 5H10C9.07174 5 8.1815 5.36875 7.52513 6.02513C6.86875 6.6815 6.5 7.57174 6.5 8.5C6.5 9.42826 6.86875 10.3185 7.52513 10.9749C8.1815 11.6313 9.07174 12 10 12H15C15.9283 12 16.8185 12.3687 17.4749 13.0251C18.1313 13.6815 18.5 14.5717 18.5 15.5C18.5 16.4283 18.1313 17.3185 17.4749 17.9749C16.8185 18.6313 15.9283 19 15 19H6.5"
        stroke="#747474"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const StakingPool = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <g clip-path="url(#clip0_168_1226)">
        <path
          className="rainbow-svg"
          d="M23.918 16.5438C23.2814 15.4953 22.0197 15.1318 20.975 15.6906L17.0693 17.3954C16.8741 16.9141 16.4719 16.5357 15.9568 16.4099L13.4835 15.8061C12.5861 15.5872 11.7474 15.1859 10.9909 14.6138C9.46195 13.4573 7.40053 13.56 5.97793 14.8628C5.80365 15.0223 5.58507 15.1102 5.36246 15.1102H4.83173C4.80579 15.1102 4.78099 15.1144 4.75586 15.1178V14.3757C4.75586 14.0644 4.50346 13.812 4.1921 13.812H0.563758C0.252402 13.812 0 14.0644 0 14.3757V22.4056C0 22.7169 0.252402 22.9693 0.563758 22.9693H4.19194C4.5033 22.9693 4.7557 22.7169 4.7557 22.4056V21.1725C4.78067 21.1759 4.80547 21.1801 4.83157 21.1801H6.60096C6.99881 21.1801 7.39683 21.2318 7.78421 21.3338L13.2488 22.7738C13.2688 22.7789 13.2892 22.7833 13.3097 22.7862C13.5031 22.815 13.6966 22.8293 13.8895 22.8293C14.2713 22.8293 14.6508 22.7731 15.023 22.6612C15.0699 22.6472 15.1147 22.6269 15.1564 22.6013L23.7319 17.3161C23.9958 17.1536 24.0789 16.8086 23.918 16.5438ZM3.62818 21.8416H1.12752V14.9395H3.62818V21.8416ZM14.6305 21.601C14.261 21.7025 13.8826 21.7274 13.5046 21.6753L8.0714 20.2433C7.59044 20.1167 7.09562 20.0524 6.60112 20.0524H4.83157C4.80563 20.0524 4.78083 20.0566 4.7557 20.06V16.2297C4.78067 16.2331 4.80547 16.2372 4.83157 16.2372H5.3623C5.86759 16.2372 6.35645 16.0444 6.73916 15.6939C7.75425 14.7646 9.22276 14.69 10.3108 15.5127C11.1906 16.1781 12.168 16.6454 13.2159 16.9012L15.6892 17.5051C15.9431 17.5671 16.1107 17.8625 16.0627 18.1635C16.0433 18.2859 15.9614 18.4027 15.9105 18.4448L11.4021 17.777C11.0927 17.7294 10.8074 17.944 10.7618 18.252C10.7162 18.5599 10.9288 18.8465 11.2368 18.8922L15.7931 19.5673C15.8421 19.5745 15.8904 19.5781 15.9379 19.5781C16.4761 19.5781 16.9259 19.1311 17.1073 18.6086L21.4478 16.714C21.4635 16.7071 21.4787 16.6995 21.4938 16.6912C21.8434 16.4996 22.2353 16.5199 22.5537 16.7174L14.6305 21.601Z"
          fill="#747474"
        />
        <path
          className="rainbow-svg"
          d="M7.02631 13.1024C10.0366 13.1024 12.4859 10.6527 12.4859 7.64139C12.4859 7.33003 12.2335 7.07763 11.9221 7.07763H7.59007V2.74555C7.59007 2.4342 7.33767 2.18179 7.02631 2.18179C4.0152 2.18179 1.56543 4.63092 1.56543 7.64139C1.56527 10.6527 4.01504 13.1024 7.02631 13.1024ZM6.46255 3.34587V7.64139C6.46255 7.95274 6.71495 8.20514 7.02631 8.20514H11.3218C11.0446 10.3294 9.224 11.9749 7.02631 11.9749C4.63678 11.9749 2.69295 10.0309 2.69295 7.64139C2.69278 5.4437 4.33847 3.62292 6.46255 3.34587Z"
          fill="#747474"
        />
        <path
          className="rainbow-svg"
          d="M16.0695 6.75323H16.9246V11.6706C16.9246 11.982 17.177 12.2344 17.4884 12.2344H20.4514C20.7627 12.2344 21.0151 11.982 21.0151 11.6706V6.75339H21.8704C22.0755 6.75339 22.2644 6.64209 22.3638 6.46249C22.463 6.28306 22.457 6.06383 22.3482 5.89004L19.4475 1.26432C19.3445 1.09987 19.1641 1 18.97 1C18.7759 1 18.5955 1.09987 18.4922 1.26432L15.5919 5.89004C15.483 6.06383 15.4771 6.28306 15.5763 6.46249C15.6755 6.64193 15.8645 6.75323 16.0695 6.75323ZM18.9698 2.62507L20.8515 5.62588H20.4514C20.14 5.62588 19.8876 5.87828 19.8876 6.18963V11.1071H18.0522V6.18947C18.0522 5.87812 17.7998 5.62571 17.4884 5.62571H17.0885L18.9698 2.62507Z"
          fill="#747474"
        />
      </g>
      <defs>
        <clipPath id="clip0_168_1226">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Tutorials = () => {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 2.56429V15.625C23 16.9861 21.8968 18.0893 20.5357 18.0893H2.46429C1.10318 18.0893 0 16.9861 0 15.625V2.56429C0 1.20318 1.10318 0.100006 2.46429 0.100006H20.5357C21.8968 0.100006 23 1.20318 23 2.56429ZM21.3571 2.56429C21.3571 2.11086 20.9891 1.74286 20.5357 1.74286H2.46429C2.01086 1.74286 1.64286 2.11086 1.64286 2.56429V15.625C1.64286 16.0784 2.01086 16.4464 2.46429 16.4464H20.5357C20.9891 16.4464 21.3571 16.0784 21.3571 15.625V2.56429ZM15.8421 7.57994C16.3555 7.87154 16.6717 8.41697 16.6717 9.00676C16.6717 9.59736 16.3555 10.1428 15.8421 10.4344L9.58853 13.9961C9.08007 14.2861 8.45579 14.2836 7.94979 13.9895C7.44379 13.6955 7.13246 13.1541 7.13246 12.5693V5.44504C7.13246 4.86018 7.44379 4.31886 7.94979 4.02479C8.45579 3.73072 9.08007 3.72826 9.58853 4.0174L15.8421 7.57994ZM8.77532 5.44504V12.5693L15.0289 9.00676L8.77532 5.44504Z"
        fill="#747474"
      />
    </svg>
  );
};

const Redemption = () => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <defs>
        <linearGradient
          id="gradient"
          gradientTransform="rotate(90.21)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="2.18%" stopColor="#3E59A1" />
          <stop offset="20.74%" stopColor="#5E227D" />
          <stop offset="38.5%" stopColor="#C20C6E" />
          <stop offset="58.68%" stopColor="#EDC730" />
          <stop offset="79.67%" stopColor="#95C156" />
        </linearGradient>
      </defs>
      <path
        className="rainbow-svg"
        d="M23.918 15.5438C23.2814 14.4953 22.0197 14.1318 20.975 14.6906L17.0693 16.3954C16.8741 15.9141 16.4719 15.5357 15.9568 15.4099L13.4835 14.8061C12.5861 14.5872 11.7474 14.1859 10.9909 13.6138C9.46195 12.4573 7.40053 12.56 5.97793 13.8628C5.80365 14.0223 5.58507 14.1102 5.36246 14.1102H4.83173C4.80579 14.1102 4.78099 14.1144 4.75586 14.1178V13.3757C4.75586 13.0644 4.50346 12.812 4.1921 12.812H0.563758C0.252402 12.812 0 13.0644 0 13.3757V21.4056C0 21.7169 0.252402 21.9693 0.563758 21.9693H4.19194C4.5033 21.9693 4.7557 21.7169 4.7557 21.4056V20.1725C4.78067 20.1759 4.80547 20.1801 4.83157 20.1801H6.60096C6.99881 20.1801 7.39682 20.2318 7.78421 20.3338L13.2488 21.7738C13.2688 21.7789 13.2892 21.7833 13.3097 21.7862C13.5031 21.815 13.6966 21.8293 13.8895 21.8293C14.2713 21.8293 14.6508 21.7731 15.023 21.6612C15.0699 21.6472 15.1147 21.6269 15.1564 21.6013L23.732 16.3161C23.9958 16.1536 24.0789 15.8086 23.918 15.5438ZM3.62818 20.8416H1.12752V13.9395H3.62818V20.8416ZM14.6305 20.601C14.261 20.7025 13.8826 20.7274 13.5046 20.6753L8.0714 19.2433C7.59044 19.1167 7.09562 19.0524 6.60112 19.0524H4.83157C4.80563 19.0524 4.78083 19.0566 4.7557 19.06V15.2297C4.78067 15.2331 4.80547 15.2372 4.83157 15.2372H5.3623C5.86759 15.2372 6.35645 15.0444 6.73916 14.6939C7.75425 13.7646 9.22276 13.69 10.3108 14.5127C11.1906 15.1781 12.168 15.6454 13.2159 15.9012L15.6892 16.5051C15.9431 16.5671 16.1108 16.8625 16.0627 17.1635C16.0433 17.2859 15.9614 17.4027 15.9105 17.4448L11.4021 16.777C11.0927 16.7294 10.8074 16.944 10.7618 17.252C10.7162 17.5599 10.9288 17.8465 11.2368 17.8922L15.7931 18.5673C15.8421 18.5745 15.8904 18.5781 15.9379 18.5781C16.4761 18.5781 16.9259 18.1311 17.1073 17.6086L21.4478 15.714C21.4635 15.7071 21.4787 15.6995 21.4938 15.6912C21.8434 15.4996 22.2353 15.5199 22.5537 15.7174L14.6305 20.601Z"
        fill="#747474"
      />
      <path
        className="rainbow-svg"
        d="M15 1V13M17.5 3.18182H13.75C13.2859 3.18182 12.8408 3.38295 12.5126 3.74098C12.1844 4.099 12 4.58459 12 5.09091C12 5.59723 12.1844 6.08282 12.5126 6.44084C12.8408 6.79886 13.2859 7 13.75 7H16.25C16.7141 7 17.1592 7.20114 17.4874 7.55916C17.8156 7.91718 18 8.40277 18 8.90909C18 9.41541 17.8156 9.901 17.4874 10.259C17.1592 10.617 16.7141 10.8182 16.25 10.8182H12"
        stroke="#747474"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
