import React, { useEffect, useState } from "react";
import { Button, Box, Flex, Card, Heading, Text } from "theme-ui";

import {
  Decimal,
  Percent,
  LiquidLoansStoreState,
  MINIMUM_COLLATERAL_RATIO,
} from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { COIN } from "../../strings";

import { Icon } from "../Icon";
import { LoadingOverlay } from "../LoadingOverlay";
import { EditableRow, StaticRow } from "../Vault/Editor";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useMyTransactionState } from "../Transaction";

import { RedemptionAction } from "./RedemptionAction";
import { InfoIcon } from "../InfoIcon";
import PercentageSelector from "../PercentageSelector";

const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);

const select = ({
  price,
  fees,
  total,
  usdlBalance,
}: LiquidLoansStoreState) => ({
  price,
  fees,
  total,
  usdlBalance,
});

const transactionId = "redemption";

export const RedemptionManager: React.FC = () => {
  const { price, fees, total, usdlBalance } = useLiquidLoansSelector(select);
  const [usdlAmount, setUSDLAmount] = useState(Decimal.ZERO);
  const [changePending, setChangePending] = useState(false);
  const editingState = useState<string>();

  const dirty = !usdlAmount.isZero;
  const ethAmount = usdlAmount.div(price);
  const redemptionRate = fees.redemptionRate(usdlAmount.div(total.debt));
  const feePct = new Percent(redemptionRate);
  const ethFee = ethAmount.mul(redemptionRate);
  const maxRedemptionRate = redemptionRate.add(0.001); // TODO slippage tolerance

  const myTransactionState = useMyTransactionState(transactionId);

  useEffect(() => {
    if (
      myTransactionState.type === "waitingForApproval" ||
      myTransactionState.type === "waitingForConfirmation"
    ) {
      setChangePending(true);
    } else if (
      myTransactionState.type === "failed" ||
      myTransactionState.type === "cancelled"
    ) {
      setChangePending(false);
    } else if (myTransactionState.type === "confirmed") {
      setUSDLAmount(Decimal.ZERO);
      setChangePending(false);
    }
  }, [myTransactionState.type, setChangePending, setUSDLAmount]);

  const [canRedeem, description] = total.collateralRatioIsBelowMinimum(price)
    ? [
        false,
        <ErrorDescription>
          You can't redeem USDL when the total collateral ratio is less than{" "}
          <Amount>{mcrPercent}</Amount>. Please try again later.
        </ErrorDescription>,
      ]
    : usdlAmount.gt(usdlBalance)
    ? [
        false,
        <ErrorDescription>
          The amount you're trying to redeem exceeds your balance by{" "}
          <Amount>
            {usdlAmount.sub(usdlBalance).prettify()} {COIN}
          </Amount>
          .
        </ErrorDescription>,
      ]
    : [
        true,
        <ActionDescription>
          You will receive{" "}
          <Amount>{ethAmount.sub(ethFee).prettify(2)} PLS</Amount> in exchange
          for{" "}
          <Amount>
            {usdlAmount.prettify()} {COIN}
          </Amount>
          .
        </ActionDescription>,
      ];

  return (
    <Flex sx={{ gap: "20px", flexDirection: ["column", "row"] }}>
      <Card sx={{ width: "100%", minHeight: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>Redeem PLS</Heading>

        <Card sx={{ px: [2, 3], py: [3, 4] }} variant="main_card">
          <EditableRow
            label="Redeem"
            inputId="redeem-usdl"
            amount={usdlAmount.prettify()}
            maxAmount={usdlBalance.toString()}
            maxedOut={usdlAmount.eq(usdlBalance)}
            unit={COIN}
            {...{ editingState }}
            editedAmount={usdlAmount.toString(2)}
            setEditedAmount={(amount) => setUSDLAmount(Decimal.from(amount))}
          />

          {(
            [
              // ["PLS", accountBalance],
              [COIN, usdlBalance],
              // [GT, loanBalance],
            ] as const
          ).map(([currency, balance], i) => (
            <Flex
              key={i}
              sx={{ my: 2, flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  fontFamily: "Titillium Web, sans-serif",
                }}
              >
                {usdlBalance.prettify()} {currency}
              </Text>
            </Flex>
          ))}

          <PercentageSelector
            value={usdlBalance}
            setFinalValue={(amount: Decimal) => {
              setUSDLAmount(Decimal.from(amount));
            }}
          />
          <Flex sx={{ mt: "20px", width: "100%", height: "20px" }}></Flex>
          <StaticRow
            label="Redemption Fee"
            inputId="redeem-fee"
            amount={ethFee.toString(2)}
            pendingAmount={feePct.toString(2)}
            unit="PLS"
            infoIcon={
              <InfoIcon
                tooltip={
                  <Card variant="tooltip" sx={{ minWidth: "240px" }}>
                    The Redemption Fee is charged as a percentage of the
                    redeemed Pulse. The Redemption Fee depends on USDL
                    redemption volumes and is 0.5% at minimum.
                  </Card>
                }
              />
            }
          />
          <Flex>
            {((dirty || !canRedeem) && description) || (
              <ActionDescription>
                Enter the amount of {COIN} you'd like to redeem.
              </ActionDescription>
            )}
            {dirty && !changePending && (
              <Button
                variant="titleIcon"
                sx={{ ":enabled:hover": { color: "danger" } }}
                onClick={() => setUSDLAmount(Decimal.ZERO)}
              >
                <Icon name="history" size="lg" />
              </Button>
            )}
          </Flex>
          <Flex variant="layout.actions">
            <RedemptionAction
              transactionId={transactionId}
              disabled={!dirty || !canRedeem}
              usdlAmount={usdlAmount}
              maxRedemptionRate={maxRedemptionRate}
            />
          </Flex>
          {changePending && <LoadingOverlay />}
        </Card>
      </Card>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
      >
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>About Redemptions</Heading>

          <Card
            sx={{ fontFamily: "Titillium Web, sans-serif", p: [2, 3] }}
            variant="second_card"
          >
            Redemptions are one of Liquid Loans most unique and important
            protocol features. The redemption mechanism gives USDL holders the
            ability to redeem the underlying PLS collateral at face value at any
            time.
            <br />
            <br /> Redemptions pay off the debt of the riskiest Vaults, in
            return for their collateral.
          </Card>
        </Card>
        <Card>
          <Card
            sx={{
              fontFamily: "Titillium Web, sans-serif",
              borderRadius: "8.256px",
              background: "#370B51",
              p: [2, 3],
            }}
            // variant="second_card"
          >
            <Flex sx={{ alignItems: "flex-start", gap: 3 }}>
              <Flex
                sx={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  // width: "100%",
                  minHeight: "100%",
                }}
              >
                {/* <div>a</div> */}
                {/* <InfoIcon tooltip={<></>} /> */}
                <Text>
                  <Icon
                    name="question-circle"
                    size="1x"
                    style={{ marginTop: "2px" }}
                  />
                </Text>
              </Flex>

              <Text>
                IMPORTANT : Redemptions are not the same as paying back your
                Vault’s debt. To repay your loan, adjust your Vault on the Repay
                tab of the Borrow USDL page.
              </Text>
            </Flex>
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
