import React from "react";
import { LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { Container, Flex, Box, Image } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import BaristaLogo from "../assets/images/barista-logo.png";

import { LiquidLoansLogo } from "./LiquidLoansLogo";
import { Nav } from "./Nav";
import { SideNav } from "./SideNav";
import Tcr from "./Tcr";
import { UserTokenBalances } from "./UserTokenBalances";
import { UserAccount } from "./UserAccount";
import MenuButton from "./MenuButton";

const logoHeight = "32px";

const select = ({ frontend }: LiquidLoansStoreState) => ({
  frontend,
});

export const Header: React.FC<{
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isVisible, setIsVisible, children }) => {
  const {
    config: { frontendTag },
  } = useLiquidLoans();
  const { frontend } = useLiquidLoansSelector(select);
  const isFrontendRegistered =
    frontendTag === AddressZero || frontend.status === "registered";

  return (
    <Container variant="header">
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
          mb: ["15px", null, null, 0],
        }}
      >
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Image
            src={BaristaLogo}
            sx={{
              width: "100px",
              height: "auto",
              display: ["flex", null, null, "none"],
            }}
          />
          <Box sx={{ display: ["none", null, null, "flex"] }}>
            <Tcr />
          </Box>

          {/* <Box
          sx={{
            mx: [2, 3],
            width: "0px",
            height: "100%",
            borderLeft: ["none", "1px solid lightgrey"]
          }}
        /> */}
          {isFrontendRegistered && (
            <>
              <SideNav isVisible={isVisible} setIsVisible={setIsVisible} />
            </>
          )}
          {/* {children} */}
          <Box sx={{ display: ["none", null, null, "flex"] }}>
            <UserTokenBalances />
          </Box>
          <Box sx={{ display: ["none", null, null, "flex"] }}>
            <UserAccount />
          </Box>

          <MenuButton isVisible={isVisible} setIsVisible={setIsVisible} />
        </Flex>
        <Box sx={{ display: ["flex", null, null, "none"], width: "100%" }}>
          <UserTokenBalances />
        </Box>
        <Box sx={{ display: ["flex", null, null, "none"] }}>
          <UserAccount />
        </Box>
      </Flex>
    </Container>
  );
};
