import React, { useState, useRef } from "react";
import { Box, Button, Container, Flex, Image } from "theme-ui";
import { Icon } from "./Icon";
import { LiquidLoansLogo } from "./LiquidLoansLogo";
import { Link } from "./Link";
import MenuNavLinks from "./MenuNavLinks";
import BaristaLogo from "../assets/images/barista-logo.png";

const logoHeight = "32px";

export const SideNav: React.FC<{
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isVisible, setIsVisible }) => {
  // const [isVisible, setIsVisible] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);

  // if (!isVisible) {
  //   return (
  //     <Button sx={{ display: ["flex", "none"] }} variant="icon" onClick={() => setIsVisible(true)}>
  //       <Icon name="bars" size="lg" />
  //     </Button>
  //   );
  // }
  return (
    <Container
      variant="infoOverlay"
      ref={overlay}
      onClick={(e) => {
        if (e.target === overlay.current) {
          setIsVisible(true);
        }
      }}
      sx={{
        // display: isVisible ? 'block' : 'none',
        opacity: isVisible ? "0" : "100%",
        // width: isVisible ? "0" : "100%",
        transition: "all 0.55s",
        pointerEvents: isVisible ? "none" : "auto",
      }}
    >
      <Flex
        variant="layout.sidenav"
        sx={{
          width: isVisible ? "0" : "60%",

          // opacity: isVisible ? '10%' : '100%',
          // display: isVisible ? 'flex' : 'none',
          transition: "width 0.35s",
        }}
      >
        <Image
          src={BaristaLogo}
          sx={{
            width: "auto",
            height: "66px",
            mx: "auto",
            transition: "width 0.35s",
          }}
        />
        <MenuNavLinks />
        {/* <LiquidLoansLogo height={logoHeight} p={2} />
        <Flex  as="nav" sx={{ m: 3, mt: 1, p: 0 ,flexDirection:'column' , width:'100%'}} onClick={() => setIsVisible(false)}>
          <Link to="/">Dashboard</Link>
          <Link to="/farm">Farm</Link>
          <Link to="/risky-vaults">Risky Vaults</Link>
          <Link to="/redemption">Redemption</Link>
        </Flex> */}
      </Flex>
    </Container>
  );
};
