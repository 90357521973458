import React from "react";

import {
  Decimal,
  StabilityDeposit,
  StabilityDepositChange,
} from "@liquidloans/lib-base";

import { COIN, GT } from "../../strings";
import { ActionDescription, Amount } from "../ActionDescription";
import { InfoIcon } from "../InfoIcon";
import { Card } from "theme-ui";

type StabilityActionDescriptionProps = {
  originalDeposit: StabilityDeposit;
  change: StabilityDepositChange<Decimal>;
};

export const StabilityActionDescription: React.FC<
  StabilityActionDescriptionProps
> = ({ originalDeposit, change }) => {
  const collateralGain = originalDeposit.collateralGain.nonZero
    ?.prettify(2)
    .concat(" PLS");
  const loanReward = originalDeposit.loanReward.nonZero
    ?.prettify()
    .concat(" ", GT);

  return (
    <ActionDescription>
      {change.depositUSDL ? (
        <>
          {" "} You are depositing{" "}
          <Amount>
            {change.depositUSDL.prettify()} {COIN}
          </Amount>{" "}
          in the Stability Pool
        </>
      ) : (
        <>
          You are withdrawing{" "}
          <Amount>
            {change.withdrawUSDL.prettify()} {COIN}
          </Amount>{" "}
          to your wallet
        </>
      )}
      {(collateralGain || loanReward) && (
        <>
          {" "}
          and claiming {" "}
          {collateralGain && loanReward ? (
            <>
              <Amount>{"a proportional amount of PLS "}</Amount> and at least{" "}
              <Amount>{loanReward}</Amount>
            </>
          ) : (
            <Amount>{collateralGain ?? loanReward}</Amount>
          )}
        </>
      )}
      {change.depositUSDL ? (
       <InfoIcon
          tooltip={
            <Card variant="tooltip" sx={{ width: "240px" }}>
              There are existing PLS and Loan gains in our system. Whenever a new deposit happens in the protocol we calculate the relative share and assign the corresponding amount of PLS and Loan gains. So when someone deposits the USDL they should use the below calculation to check their claims. <br /><br />

              (Pls * priceOfPlsGain) + (USDL * $1) + (price of Loan * LoanGains) = initalUSDLDeposit * 1$
              <br /><br />
              For an example, if your deposit is 100 USDL your deposit becomes 82.95 and 10.91 PLS = 82.95 + (10.91 * 1.561334) + (0.025615 * 0.1891791) = $100 equivalent to your deposit.
            </Card>
          }
        /> ): <></>}
    </ActionDescription>
  );
};
