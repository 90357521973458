import { Button } from "theme-ui";

import { Decimal, LOANStakeChange } from "@liquidloans/lib-base";

import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useTransactionFunction } from "../Transaction";

type StakingActionProps = {
  change: LOANStakeChange<Decimal>;
};

export const StakingManagerAction: React.FC<StakingActionProps> = ({ change, children }) => {
  const { liquidLoans } = useLiquidLoans();

  const [sendTransaction] = useTransactionFunction(
    "stake",
    change.stakeLOAN
      ? liquidLoans.send.stakeLOAN.bind(liquidLoans.send, change.stakeLOAN)
      : liquidLoans.send.unstakeLOAN.bind(liquidLoans.send, change.unstakeLOAN)
  );

  return <Button onClick={sendTransaction}>{children}</Button>;
};
