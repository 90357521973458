import { Box, Flex, Text, useThemeUI } from "theme-ui";

import { Icon } from "./Icon";

export const ErrorDescription: React.FC = ({ children }) => {
  const theme = useThemeUI();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        fontFamily: "Titillium Web, sans-serif",
        my: [2, 3],
        // p: 3,
        // my: 2,
        color: theme.theme.colors?.barista_red,
        // border: 1,
        borderRadius: "8px",
        borderColor: "danger",
        // boxShadow: 2,
        // bg: "rgba(220, 44, 16, 0.05)",
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="exclamation-triangle" size="lg" />
        <Text sx={{ ml: 2 }}>{children}</Text>
      </Flex>
    </Box>
  );
};
