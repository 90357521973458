import React, { useCallback, useEffect, useState } from "react";
import { Heading, Box, Card, Button, Flex, Text } from "theme-ui";

import {
  Decimal,
  Decimalish,
  Difference,
  LiquidLoansStoreState,
  LOANStake,
} from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { COIN, GT } from "../../strings";

import { Icon } from "../Icon";
import { EditableRow, StaticRow } from "../Vault/Editor";
import { LoadingOverlay } from "../LoadingOverlay";

import {
  StakingViewContext,
  useStakingView,
} from "./context/StakingViewContext";
import Tabs from "../Tabs";
import { ActionDescription } from "../ActionDescription";
import PercentageSelector from "../PercentageSelector";
import { useMyTransactionState, useTransactionFunction } from "../Transaction";
import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useHistory } from "react-router-dom";
import { fetchStakingAPR } from "../../graphFunctions/graphFetcher";
import { InfoIcon } from "../InfoIcon";

const select = ({
  loanBalance,
  totalStakedLOAN,
  loanStake,
}: LiquidLoansStoreState) => ({
  loanBalance,
  totalStakedLOAN,
  loanStake,
});

type StakingEditorProps = {
  title: string;
  originalStake: LOANStake;
  editedLOAN: Decimal;

  dispatch: (
    action: { type: "setStake"; newValue: Decimalish } | { type: "revert" }
  ) => void;
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
  description: JSX.Element | undefined;
};

export const StakingEditor: React.FC<StakingEditorProps> = ({
  children,
  title,
  originalStake,
  editedLOAN,
  dispatch,
  tabs,
  currentTab,
  setCurrentTab,
  description,
}) => {
  const history = useHistory();
  const { loanBalance, totalStakedLOAN, loanStake } =
    useLiquidLoansSelector(select);
  const { collateralGain, usdlGain, beanGain } = loanStake;
  let { changePending } = useStakingView();

  const { liquidLoans } = useLiquidLoans();
  // const { collateralGain, usdlGain } = useLiquidLoansSelector(selectLOANStake);
  const editingState = useState<string>();
  const [enteredLoan, setEnteredLoan] = useState<Decimal>(Decimal.from(0));
  const edited = !editedLOAN.eq(loanStake.stakedLOAN);
  const makingNewStake = loanStake.isEmpty;

  const maxAmount = loanStake.stakedLOAN.add(loanBalance);
  const maxedOut = editedLOAN.eq(maxAmount);

  const totalStakedLOANAfterChange = totalStakedLOAN
    .sub(loanStake.stakedLOAN)
    .add(editedLOAN);

  const originalPoolShare = loanStake.stakedLOAN.mulDiv(100, totalStakedLOAN);
  const newPoolShare = editedLOAN.mulDiv(100, totalStakedLOANAfterChange);
  const poolShareChange =
    loanStake.stakedLOAN.nonZero &&
    Difference.between(newPoolShare, originalPoolShare).nonZero;

  const [sendTransaction] = useTransactionFunction(
    "stake",
    liquidLoans.send.withdrawGainsFromStaking.bind(liquidLoans.send)
  );

  const transactionState = useMyTransactionState("stake");
  const [ apr, setAPR] = useState(0);

  useEffect(() => {
    getStakingAPR();
  }, []);

  const getStakingAPR = async () => {
    let apr = await fetchStakingAPR();
    setAPR(apr.stakingAPR);
  };

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      setEnteredLoan(Decimal.from(0));
      // dispatch({ type: "revert" });
      dispatch({ type: "setStake", newValue: loanStake.stakedLOAN });
    }
  }, [transactionState.type, dispatch]);

  useEffect(() => {
    dispatch({ type: "setStake", newValue: loanStake.stakedLOAN });
  }, [loanStake.stakedLOAN]);

  useEffect(() => {
    setEnteredLoan(Decimal.from(0));
    // dispatch({ type: "revert" });
    dispatch({ type: "setStake", newValue: loanStake.stakedLOAN });
  }, [currentTab]);

  const [renderCount, setRenderCount] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderCount(prevCount => prevCount + 1);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Flex sx={{ gap: "20px", flexDirection: ["column", "row"] }}>
      <Card sx={{ width: "100%", minHeight: "100%" }}>
        <Heading sx={{ px: ["20px", 0, 0, 0] }}>{title}</Heading>

        <Card sx={{ p: [2, 3] }} variant="main_card">
          <Flex sx={{ gap: "30px" }}>
            <Tabs
              tabs={tabs}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              disabledTab={makingNewStake ? "Unstake" : undefined}
            />
            <Flex sx={{ flexDirection: "column", minWidth: "fit-content" }}>
              <Text sx={{ color: "grey", whiteSpace: "nowrap" }}>
                Current APR
              </Text>
              <Text sx={{ color: "white" }}> { apr.toFixed(2) }%</Text>
            </Flex>
          </Flex>
          <EditableRow
            // label="loan"
            label={currentTab === "Stake" ? "STAKE LOAN" : "UNSTAKE LOAN"}
            inputId="stake-loan"
            amount={enteredLoan.prettify()}
            maxAmount={maxAmount.toString()}
            maxedOut={maxedOut}
            unit={""}
            {...{ editingState }}
            editedAmount={enteredLoan.toString(2)}
            setEditedAmount={
              (newValue) => {
                setEnteredLoan(Decimal.from(newValue));
                dispatch({
                  type: "setStake",
                  newValue: makingNewStake
                    ? Decimal.from(newValue)
                    : currentTab === "Stake"
                    ? Decimal.from(newValue).add(loanStake.stakedLOAN)
                    : Decimal.from(newValue).gte(loanStake.stakedLOAN)
                    ? Decimal.from(0)
                    : loanStake.stakedLOAN.sub(Decimal.from(newValue)),
                });
              }
              // dispatch({ type: "setStake", newValue })
            }
          />
          {(
            [
              // ["PLS", accountBalance],
              // [COIN, usdlBalance],
              [GT, loanBalance],
            ] as const
          ).map(([currency, balance], i) => (
            <Flex
              key={i}
              sx={{
                my: 2,
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Text
                sx={{
                  fontSize: "14px",
                  fontFamily: "Titillium Web, sans-serif",
                }}
              >
                {currentTab === "Stake" ? "Wallet" : "Staked"} :{" "}
                {currentTab === "Stake"
                  ? balance.prettify()
                  : loanStake.stakedLOAN.prettify()}{" "}
                {currency}
              </Text>
            </Flex>
          ))}
          <PercentageSelector
            value={currentTab === "Stake" ? loanBalance : loanStake.stakedLOAN}
            setFinalValue={(amount: Decimal) => {
              setEnteredLoan(Decimal.from(amount));

              dispatch({
                type: "setStake",
                newValue:
                  //makingNewStake
                  //   ? Decimal.from(amount)
                  //   :
                  currentTab === "Stake"
                    ? Decimal.from(amount).add(loanStake.stakedLOAN)
                    : Decimal.from(amount).gte(loanStake.stakedLOAN)
                    ? Decimal.from(0)
                    : loanStake.stakedLOAN.sub(Decimal.from(amount)),
              });
              // alert(amount);
            }}
          />
          <Flex>
            {description ??
              (makingNewStake ? (
                <ActionDescription>
                  Enter the amount of {GT} you'd like to stake.
                </ActionDescription>
              ) : (
                <ActionDescription>
                  Adjust the {GT} amount to stake or withdraw.
                </ActionDescription>
              ))}
            {edited && !changePending && (
              <Button
                variant="titleIcon"
                sx={{ ":enabled:hover": { color: "danger" } }}
                onClick={() => {
                  setEnteredLoan(Decimal.from(0));
                  dispatch({
                    type: "setStake",
                    newValue: loanStake.stakedLOAN,
                  });

                  // dispatch({ type: "revert" });
                }}
              >
                <Icon name="history" size="lg" />
              </Button>
            )}
          </Flex>
          <br/>
          {children}
          {changePending && <LoadingOverlay />}
        </Card>
      </Card>
      <Flex
        sx={{
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          justifyContent: "space-between",
        }}
      >
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>Staking Pool</Heading>

          <Card sx={{}} variant="second_card">
            <StaticRow
              label={currentTab === "Stake" ? "Your New Stake" : "Your Unstake"}
              inputId="deposit-gain"
              amount={enteredLoan.prettify(2)}
              unit="LOAN"
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />

            <StaticRow
              label={"Your Existing Stakes"}
              inputId="deposit-gain"
              amount={loanStake.stakedLOAN.prettify()}
              // amount={originalStake.stakedLOAN.prettify()}
              unit="LOAN"
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />

            <StaticRow
              label={"Your Total Staking Balance"}
              inputId="deposit-gain"
              amount={
                currentTab === "Stake"
                  ? loanStake.stakedLOAN.add(enteredLoan).prettify()
                  : // : stabilityDeposit.currentUSDL.sub(enteredUSD).prettify()
                  Decimal.from(enteredLoan).gte(loanStake.stakedLOAN)
                  ? Decimal.from(0).prettify()
                  : loanStake.stakedLOAN
                      .sub(Decimal.from(enteredLoan))
                      .prettify()
              }
              unit="LOAN"
              // pendingAmount={usdlDiff?.prettify(2).concat("LUSD")}
              // pendingColor={editedUSD?.positive ? "success" : "danger"}
            />
            {newPoolShare.infinite ? (
              <StaticRow
                label="Your Pool share"
                inputId="stake-share"
                amount="N/A"
              />
            ) : (
              <StaticRow
                label="Your LL Pool share"
                inputId="stake-share"
                amount={newPoolShare.prettify(2)}
                pendingAmount={poolShareChange?.prettify(2).concat("%")}
                pendingColor={poolShareChange?.positive ? "success" : "danger"}
                unit="%"
              />
            )}
          </Card>
        </Card>
        <Card>
          <Heading sx={{ px: ["20px", 0, 0, 0] }}>Your Rewards</Heading>

          <Card sx={{}} variant="second_card">
            {/* {!loanStake.isEmpty && (
              <> */}
                <StaticRow
                  label="Redemption Fee Rewards"
                  inputId="stake-gain-eth"
                  amount={loanStake.collateralGain.prettify(2)}
                  color={loanStake.collateralGain.nonZero && "success"}
                  unit="PLS"
                />
              {/* </>
            )} */}
            <StaticRow
              label="Issuance Fee Rewards"
              inputId="stake-gain-usdl"
              amount={loanStake.usdlGain.prettify()}
              color={loanStake.usdlGain.nonZero && "success"}
              unit={COIN}
            />
            <StaticRow
              label="BEAN Rewards"
              inputId="stake-gain-bean"
              amount={loanStake.beanGain.prettify()}
              color={loanStake.beanGain.nonZero && "success"}
              unit="BEAN"
              infoIcon={
                <InfoIcon
                  tooltip={
                    <Card variant="tooltip" sx={{ width: "240px" }}>
                      Although the BEAN rewards accrue every minute, the value on the UI only updates when a user transacts with the Staking Pool. Therefore you may receive more rewards than is displayed when you claim or adjust your deposit.
                    </Card>
                  }
                />
              }
            />
            <Flex variant="layout.actions">
              <Button
                onClick={sendTransaction}
                disabled={collateralGain.isZero && usdlGain.isZero && beanGain.isZero}
              >
                Claim
              </Button>
            </Flex>
          </Card>
        </Card>
      </Flex>
    </Flex>
  );
};
