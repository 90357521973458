import React from "react";
import { Text } from "theme-ui";
import { useLiquidLoans } from "../../../hooks/LiquidLoansContext";
import { LP } from "../../../strings";
import { Transaction } from "../../Transaction";
import { Decimal } from "@liquidloans/lib-base";
import { ActionDescription } from "../../ActionDescription";
import { useValidationState } from "../context/useValidationState";

type DescriptionProps = {
  amount: Decimal;
};

const transactionId = "farm-stake";

export const Description: React.FC<DescriptionProps> = ({ amount }) => {
  const {
    liquidLoans: { send: liquidLoans },
  } = useLiquidLoans();
  const { isValid, hasApproved, isWithdrawing, amountChanged } =
    useValidationState(amount);

  if (!hasApproved) {
    return (
      <ActionDescription>
        <Text>
          To stake your {LP} tokens you need to allow Liquid Loans to stake them
          for you
        </Text>
      </ActionDescription>
    );
  }

  if (!isValid || amountChanged.isZero) {
    return null;
  }

  return (
    <ActionDescription>
      {isWithdrawing && (
        <Transaction
          id={transactionId}
          send={liquidLoans.unstakePLSXtokens.bind(liquidLoans, amountChanged)}
        >
          <Text>
            You are unstaking {amountChanged.prettify(2)} {LP}
          </Text>
        </Transaction>
      )}
      {!isWithdrawing && (
        <Transaction
          id={transactionId}
          send={liquidLoans.stakePLSXtokens.bind(liquidLoans, amountChanged)}
        >
          <Text>
            You are staking {amountChanged.prettify(2)} {LP}
          </Text>
        </Transaction>
      )}
    </ActionDescription>
  );
};
