import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Text } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStabilityView } from "./context/StabilityViewContext";
import { RemainingLOAN } from "./RemainingLOAN";
import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useTransactionFunction } from "../Transaction";

export const UnlockButton: React.FC = (props) => {
  const { liquidLoans } = useLiquidLoans();
  const [sendTransaction, transactionState] = useTransactionFunction(
    "bamm-unlock",
    liquidLoans.send.bammUnlock.bind(liquidLoans.send)
  );

  return (
    <Text
      onClick={sendTransaction}
      sx={{
        fontWeight: "bold",
        whiteSpace: "nowrap",
        cursor: "pointer",
        textDecoration: "underline",
      }}
    >
      {props.children}
    </Text>
  );
};

export const NoDeposit: React.FC = (props) => {
  const { dispatchEvent } = useStabilityView();

  const handleOpenVault = useCallback(() => {
    dispatchEvent("DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        Stability Pool
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLOAN />
        </Flex>
      </Heading>
      <Card sx={{ p: [2, 3] }} variant="main_card">
        <InfoMessage title="You have no USDL in the Stability Pool.">
          You can earn PLS and LOAN rewards by depositing USDL.
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleOpenVault}>Deposit</Button>
        </Flex>
      </Card>
    </Card>
  );
};
