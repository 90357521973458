import React, { useState, useEffect, useCallback, Fragment } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Card,
  Button,
  Text,
  Box,
  Heading,
  Flex,
  Divider,
  Select,
} from "theme-ui";
// import cardBg from "../assets/images/bg/card-bg.png";

import {
  Percent,
  MINIMUM_COLLATERAL_RATIO,
  CRITICAL_COLLATERAL_RATIO,
  UserVault,
  Decimal,
} from "@liquidloans/lib-base";
import { BlockPolledLiquidLoansStoreState } from "@liquidloans/lib-ethers";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { shortenAddress } from "../utils/shortenAddress";
import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import { COIN } from "../strings";

import { Icon } from "./Icon";
import { LoadingOverlay } from "./LoadingOverlay";
import { Transaction, useMyTransactionState } from "./Transaction";
import { Tooltip } from "./Tooltip";
import { Abbreviation } from "./Abbreviation";
import { LiquidationManager } from "./LiquidationManager";

const rowHeight = "40px";

const liquidatableInNormalMode = (vault: UserVault, price: Decimal) =>
  [
    vault.collateralRatioIsBelowMinimum(price),
    "Collateral ratio not low enough",
  ] as const;

const liquidatableInRecoveryMode = (
  vault: UserVault,
  price: Decimal,
  totalCollateralRatio: Decimal,
  usdlInStabilityPool: Decimal
) => {
  const collateralRatio = vault.collateralRatio(price);

  if (
    collateralRatio.gte(MINIMUM_COLLATERAL_RATIO) &&
    collateralRatio.lt(totalCollateralRatio)
  ) {
    return [
      vault.debt.lte(usdlInStabilityPool),
      "There's not enough USDL in the Stability pool to cover the debt",
    ] as const;
  } else {
    return liquidatableInNormalMode(vault, price);
  }
};

type RiskyVaultsProps = {
  // pageSize: number;
};

const select = ({
  numberOfVaults,
  price,
  total,
  usdlInStabilityPool,
  blockTag,
}: BlockPolledLiquidLoansStoreState) => ({
  numberOfVaults,
  price,
  recoveryMode: total.collateralRatioIsBelowCritical(price),
  totalCollateralRatio: total.collateralRatio(price),
  usdlInStabilityPool,
  blockTag,
});

export const RiskyVaults: React.FC<RiskyVaultsProps> = () =>
  // { pageSize }
  {
    const {
      blockTag,
      numberOfVaults,
      recoveryMode,
      totalCollateralRatio,
      usdlInStabilityPool,
      price,
    } = useLiquidLoansSelector(select);
    const { liquidLoans } = useLiquidLoans();
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [vaults, setVaults] = useState<UserVault[]>();
    const [transactionId, setTransactionId] = useState("");
    let transactionState = useMyTransactionState(transactionId);

    const [reload, setReload] = useState({});
    const forceReload = useCallback(() => setReload({}), []);

    const [page, setPage] = useState(0);
    const numberOfPages = Math.ceil(numberOfVaults / pageSize) || 1;
    const clampedPage = Math.min(page, numberOfPages - 1);

    const nextPage = () => {
      if (clampedPage < numberOfPages - 1) {
        setPage(clampedPage + 1);
      }
    };

    const previousPage = () => {
      if (clampedPage > 0) {
        setPage(clampedPage - 1);
      }
    };

    useEffect(() => {
      if (page !== clampedPage) {
        setPage(clampedPage);
      }
    }, [page, clampedPage]);

    useEffect(() => {
      let mounted = true;

      setLoading(true);

      liquidLoans
        .getVaults(
          {
            first: pageSize,
            sortedBy: "ascendingCollateralRatio",
            startingAt: clampedPage * pageSize,
          },
          { blockTag }
        )
        .then((vaults: React.SetStateAction<UserVault[] | undefined>) => {
          if (mounted) {
            setVaults(vaults);
            setLoading(false);
          }
        });

      return () => {
        mounted = false;
      };
      // Omit blockTag from deps on purpose
      // eslint-disable-next-line
    }, [liquidLoans, clampedPage, pageSize, reload]);

    useEffect(() => {
      forceReload();
    }, [forceReload, numberOfVaults]);

    const [copied, setCopied] = useState<string>();

    useEffect(() => {
      if (copied !== undefined) {
        let cancelled = false;

        setTimeout(() => {
          if (!cancelled) {
            setCopied(undefined);
          }
        }, 2000);

        return () => {
          cancelled = true;
        };
      }
    }, [copied]);

    useEffect(() => {
      if (transactionState.type === "confirmedOneShot") {
        forceReload();
      }
    }, [transactionState?.type]);

    return (
      <Card sx={{ width: "100%" }}>
        <Flex
          sx={{
            alignItems: ["flex-start", "flex-end"],
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            px: ["20px", 0, 0, 0],
          }}
        >
          <Heading>
            <Abbreviation short="Risky Vaults">Risky Vaults</Abbreviation>
          </Heading>
          <Flex sx={{ alignItems: "center" }}>
            <LiquidationManager />
            <Select
              defaultValue="Hello"
              sx={{
                minWidth: "80px",
                height: "52px",
                mt: "8px",
                mr: "4px",
                cursor: "pointer",
              }}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </Select>
          </Flex>
        </Flex>

        {!vaults || vaults.length === 0 ? (
          <Card sx={{ p: [2, 3] }} variant="main_card">
            <Box sx={{ p: 4, fontSize: 3, textAlign: "center" }}>
              {!vaults ? "Loading..." : "There are no Vaults yet"}
            </Box>
          </Card>
        ) : (
          <Card
            sx={{
              // p: [2, 3]
              fontFamily: "Titillium Web, sans-serif",
            }}
            variant="liquidation_card"
          >
            <Box
              as="table"
              sx={{
                mt: 2,
                // pl: [1, 4],
                width: "100%",
                // display: ["none", "inline"],
                display: ["none", "table"],
                textAlign: "center",
                lineHeight: 1.15,
              }}
            >
              <colgroup>
                <col style={{ width: "50px" }} />
                <col />
                <col />
                <col />
                <col style={{ width: rowHeight }} />
              </colgroup>

              <thead>
                <tr>
                  <th>Owner</th>
                  <th>
                    <Abbreviation short="Coll.">Collateral</Abbreviation>
                    <Box
                      sx={{
                        fontSize: [0, 1],
                        fontWeight: "body",
                        opacity: 0.5,
                      }}
                    >
                      PLS
                    </Box>
                  </th>
                  <th>
                    Debt
                    <Box
                      sx={{
                        fontSize: [0, 1],
                        fontWeight: "body",
                        opacity: 0.5,
                      }}
                    >
                      {COIN}
                    </Box>
                  </th>
                  <th>
                    Coll.
                    <br />
                    Ratio
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody
                style={{
                  background: `url("/bg/card-bg.png")`,
                }}
              >
                {vaults.map(
                  (vault) =>
                    !vault.isEmpty && ( // making sure the Vault hasn't been liquidated
                      // (TODO: remove check after we can fetch multiple Vaults in one call)
                      <tr key={vault.ownerAddress}>
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: rowHeight,
                            paddingLeft: "20px",
                          }}
                        >
                          <Tooltip message={vault.ownerAddress} placement="top">
                            <Text
                              variant="address"
                              sx={{
                                width: ["73px", "unset"],
                                overflow: "hidden",
                                position: "relative",
                                fontFamily: "Titillium Web, sans-serif",
                              }}
                            >
                              {shortenAddress(vault.ownerAddress)}
                              <Box
                                sx={{
                                  display: ["block", "none"],
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  width: "50px",
                                  height: "100%",
                                  background:
                                    "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
                                }}
                              />
                            </Text>
                          </Tooltip>

                          {/* <CopyToClipboard
                            text={vault.ownerAddress}
                            onCopy={() => setCopied(vault.ownerAddress)}
                          >
                            <Button
                              variant="icon"
                              sx={{
                                width: "24px",
                                height: "24px",
                                color: "grey",
                              }}
                            >
                              <Icon
                                name={
                                  copied === vault.ownerAddress
                                    ? "clipboard-check"
                                    : "clipboard"
                                }
                                size="sm"
                              />
                            </Button>
                          </CopyToClipboard> */}
                        </td>
                        <td>
                          <Abbreviation short={vault.collateral.shorten()}>
                            {vault.collateral.prettify(2)}
                          </Abbreviation>
                        </td>
                        <td>
                          <Abbreviation short={vault.debt.shorten()}>
                            {vault.debt.prettify()}
                          </Abbreviation>
                        </td>
                        <td>
                          {((collateralRatio) => (
                            <Text
                              color={
                                collateralRatio.gt(CRITICAL_COLLATERAL_RATIO)
                                  ? "success"
                                  : collateralRatio.gt(1.2)
                                  ? "warning"
                                  : "danger"
                              }
                            >
                              {new Percent(collateralRatio).prettify()}
                            </Text>
                          ))(vault.collateralRatio(price))}
                        </td>
                        <td style={{ paddingRight: "20px" }}>
                          <div
                            onMouseEnter={() => {
                              setTransactionId(
                                `liquidate-${vault.ownerAddress}`
                              );
                            }}
                          >
                            <Transaction
                              id={`liquidate-${vault.ownerAddress}`}
                              tooltip="Liquidate"
                              requires={[
                                recoveryMode
                                  ? liquidatableInRecoveryMode(
                                      vault,
                                      price,
                                      totalCollateralRatio,
                                      usdlInStabilityPool
                                    )
                                  : liquidatableInNormalMode(vault, price),
                              ]}
                              send={liquidLoans.send.liquidate.bind(
                                liquidLoans.send,
                                vault.ownerAddress
                              )}
                            >
                              <Button
                                sx={{
                                  background: "grey",
                                  px: 3,
                                  py: 2,

                                  fontSize: "13px",
                                }}
                              >
                                Liquidate
                              </Button>
                            </Transaction>
                          </div>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Box>
            <Flex sx={{ display: ["flex", "none"], flexDirection: "column" }}>
              {vaults.map(
                (vault, i) =>
                  !vault.isEmpty && (
                    <Fragment key={i}>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          p: "10px",
                          background: `url("/bg/card-bg.png")`,
                          // borderBottom: "1px solid violet",
                          gap: "10px",
                        }}
                      >
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text>Owner</Text>
                          <Flex>
                            {" "}
                            <Tooltip
                              message={vault.ownerAddress}
                              placement="top"
                            >
                              <Text
                                variant="address"
                                sx={{
                                  width: ["73px", "unset"],
                                  overflow: "hidden",
                                  position: "relative",
                                  fontFamily: "Titillium Web, sans-serif",
                                }}
                              >
                                {shortenAddress(vault.ownerAddress)}
                                <Box
                                  sx={{
                                    display: ["block", "none"],
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    width: "50px",
                                    height: "100%",
                                    // background:
                                    //   "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
                                  }}
                                />
                              </Text>
                            </Tooltip>
                            {/* <CopyToClipboard
                              text={vault.ownerAddress}
                              onCopy={() => setCopied(vault.ownerAddress)}
                            >
                              <Button
                                variant="icon"
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  color: "grey",
                                }}
                              >
                                <Icon
                                  name={
                                    copied === vault.ownerAddress
                                      ? "clipboard-check"
                                      : "clipboard"
                                  }
                                  size="sm"
                                />
                              </Button>
                            </CopyToClipboard> */}
                          </Flex>
                        </Flex>
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text>Collateral PLS</Text>

                          <Abbreviation short={vault.collateral.shorten()}>
                            {vault.collateral.prettify(2)}
                          </Abbreviation>
                        </Flex>
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text>Debt USDL</Text>

                          <Abbreviation short={vault.debt.shorten()}>
                            {vault.debt.prettify()}
                          </Abbreviation>
                        </Flex>
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text>Collateral Ratio</Text>

                          {((collateralRatio) => (
                            <Text
                              color={
                                collateralRatio.gt(CRITICAL_COLLATERAL_RATIO)
                                  ? "success"
                                  : collateralRatio.gt(1.2)
                                  ? "warning"
                                  : "danger"
                              }
                            >
                              {new Percent(collateralRatio).prettify()}
                            </Text>
                          ))(vault.collateralRatio(price))}
                        </Flex>
                        <Flex variant="layout.liquidation">
                          <div
                            onMouseEnter={() => {
                              setTransactionId(
                                `liquidate-${vault.ownerAddress}`
                              );
                            }}
                          >
                            <Transaction
                              id={`liquidate-${vault.ownerAddress}`}
                              tooltip="Liquidate"
                              requires={[
                                recoveryMode
                                  ? liquidatableInRecoveryMode(
                                      vault,
                                      price,
                                      totalCollateralRatio,
                                      usdlInStabilityPool
                                    )
                                  : liquidatableInNormalMode(vault, price),
                              ]}
                              send={liquidLoans.send.liquidate.bind(
                                liquidLoans.send,
                                vault.ownerAddress
                              )}
                            >
                              <Button variant="liquidate">Liquidate</Button>
                            </Transaction>
                          </div>
                        </Flex>
                      </Flex>
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          background:
                            "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
                        }}
                      />
                    </Fragment>
                  )
              )}
            </Flex>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "flex-end",
                width: ["30%", "20%"],
                mr: ["auto", "10px"],
                ml: "auto",
                color: "#C22EFF",
              }}
            >
              {numberOfVaults !== 0 && (
                <>
                  <Abbreviation
                    short={`${clampedPage + 1} / ${numberOfPages}`}
                    sx={{
                      mr: [0, 3],
                      fontWeight: "body",
                      fontSize: [1, 2],
                      letterSpacing: [-1, 0],
                      whiteSpace: "nowrap",
                    }}
                  >
                    {clampedPage * pageSize + 1}-
                    {Math.min((clampedPage + 1) * pageSize, numberOfVaults)} of{" "}
                    {numberOfVaults}
                  </Abbreviation>

                  <Button
                    variant="titleIcon"
                    onClick={previousPage}
                    disabled={clampedPage <= 0}
                  >
                    <Icon name="chevron-left" size="lg" />
                  </Button>

                  <Button
                    variant="titleIcon"
                    onClick={nextPage}
                    disabled={clampedPage >= numberOfPages - 1}
                  >
                    <Icon name="chevron-right" size="lg" />
                  </Button>
                </>
              )}

              <Button
                variant="titleIcon"
                sx={{ opacity: loading ? 0 : 1, ml: [0, 3] }}
                onClick={forceReload}
              >
                <Icon name="redo" size="lg" />
              </Button>
            </Flex>
            <Box
              sx={{
                display: ["flex", "none"],
                width: "100%",
                height: "1px",
                background:
                  "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
              }}
            />
          </Card>
        )}

        {loading && <LoadingOverlay />}
      </Card>
    );
  };
