import React from "react";
import { Button } from "theme-ui";
import { Icon } from "./Icon";
import { Twirl as Hamburger } from "hamburger-react";

type Props = {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

function MenuButton({ isVisible, setIsVisible }: Props) {
  return (
    <Button
      sx={{
        position: isVisible ? "sticky" : "fixed",
        top: 3,
        right: 2,
        m: 0,
        display: ["flex", null, null, "none"],
      }}
      variant="menu_icon"
      onClick={() => setIsVisible(!isVisible)}
    >
      <Hamburger toggled={!isVisible} direction="right" />
    </Button>
  );
}

export default MenuButton;
