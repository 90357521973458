import axios, { AxiosError, AxiosResponse } from "axios";

interface SystemState {
  price: number;
}

interface PricePLSResponse {
  globals: [
    {
      currentSystemState: SystemState;
    }
  ];
}

interface Price24hAgoResponse {
  priceChanges: {
    systemStateAfter: SystemState;
    __typename: string;
  }[];
}

interface TokenData {
  LOAN2PLS: number;
  USDL2PLS: number;
  __typename: string;
}

interface GetPricesResponse {
  tokenDataLOANs: TokenData[];
  tokenDataUSDLs: TokenData[];
}

interface GetPrices24hAgoResponse {
  tokenDataLOANs: {
    timestamp: number;
    LOAN2PLS: number;
    __typename: string;
  }[];
  tokenDataUSDLs: {
    timestamp: number;
    USDL2PLS: number;
    __typename: string;
  }[];
}

interface StakingAPRResponse {
  loanstakingPRDataByDays: StakingAPRDataArray;
}

interface StabilityAPRResponse {
  usdlstabilityAPRDataByDays: StabilityAPRDataArray;
}

type StakingAPRData = {
  day: number;
  date: number;
  price: string;
  loanPrice: string;
  usdlPrice: string;
  totalBorrowingFeesPaid: string;
  totalRedemptionFeesPaid: string;
  totalLOANTokensStaked: string;
  __typename: string;
};

type StabilityAPRData = {
  day: number;
  date: number;
  price: string;
  loanPrice: string;
  usdlPrice: string;
  totalPLSFromLiquidations: string;
  totalUSDLTokensStaked: string;
  totalLOANTokensIssued: string;
  __typename: string;
};

type StakingAPRDataArray = StakingAPRData[];

type StabilityAPRDataArray = StabilityAPRData[];

const endpoint =
  "https://subgraph-prod.liquidloans.io/subgraphs/name/liquidloans/liquidloans";

// Function to make GraphQL API requests
async function makeGraphQlRequest<T>(
  operationName: string,
  query: string,
  variables?: any
): Promise<T> {
  try {
    const response: AxiosResponse<{ data: T }> = await axios.post(endpoint, {
      operationName,
      query,
      variables,
    });
    return response.data.data;
  } catch (error: any) {
    console.error("GraphQL API request error:", error.message);
    throw error;
  }
}

// Function to get the current price of PLS
async function getPriceOfPLS(): Promise<number> {
  const operationName = "PricePLS";
  const query = `query PricePLS {
    globals {
      currentSystemState {
        price
      }
    }
  }`;

  const response: PricePLSResponse = await makeGraphQlRequest<PricePLSResponse>(
    operationName,
    query
  );

  return response.globals[0].currentSystemState.price;
}

// Function to get the price of PLS 24hrs ago
async function getPriceOfPLS24hrsAgo(timestamp24hAgo: number): Promise<number> {
  const operationName = "Price24hAgo";
  const query =
    "query Price24hAgo($timestamp24hAgo: Int!) {\n  priceChanges(\n    where: {transaction_: {timestamp_lte: $timestamp24hAgo}}\n    orderBy: sequenceNumber\n    orderDirection: desc\n    first: 1\n  ) {\n    systemStateAfter {\n      price\n      __typename\n    }\n    __typename\n  }\n}";

  const variables = {
    timestamp24hAgo: timestamp24hAgo,
  };

  const response: Price24hAgoResponse =
    await makeGraphQlRequest<Price24hAgoResponse>(
      operationName,
      query,
      variables
    );
  // console.log(response)
  return response.priceChanges[0]?.systemStateAfter.price || 0;
}

// Function to get the price of LOAN2PLS & USDL2PLS
async function getPrices(): Promise<{ LOAN2PLS: number; USDL2PLS: number }> {
  const operationName = "getPrices";
  const query =
    "query getPrices {\n  tokenDataLOANs(orderBy: timestamp, orderDirection: desc, first: 1) {\n    LOAN2PLS\n    __typename\n  }\n  tokenDataUSDLs(orderBy: timestamp, orderDirection: desc, first: 1) {\n    USDL2PLS\n    __typename\n  }\n}";

  const response: GetPricesResponse =
    await makeGraphQlRequest<GetPricesResponse>(operationName, query);

  return {
    LOAN2PLS: response.tokenDataLOANs[0]?.LOAN2PLS || 0,
    USDL2PLS: response.tokenDataUSDLs[0]?.USDL2PLS || 0,
  };
}

// Function to get the price of LOAN & USDL 24hrs ago
async function getPrices24hrsAgo(
  timestamp24hAgo: number
): Promise<{ LOAN2PLS: number; USDL2PLS: number }> {
  const operationName = "Price24hAgo";
  const query =
    "query getPrices24hAgo($timestamp24hAgo: Int!) {\n  tokenDataLOANs(\n    where: {timestamp_lte: $timestamp24hAgo}\n    orderBy: timestamp\n    orderDirection: desc\n    first: 1\n  ) {\n    timestamp\n    LOAN2PLS\n    __typename\n  }\n  tokenDataUSDLs(\n    where: {timestamp_lte: $timestamp24hAgo}\n    orderBy: timestamp\n    orderDirection: desc\n    first: 1\n  ) {\n    timestamp\n    USDL2PLS\n    __typename\n  }\n}";

  const variables = {
    timestamp24hAgo: timestamp24hAgo,
  };

  const response: GetPrices24hAgoResponse =
    await makeGraphQlRequest<GetPrices24hAgoResponse>(
      operationName,
      query,
      variables
    );

    return {
      LOAN2PLS: response.tokenDataLOANs[0]?.LOAN2PLS || 0,
      USDL2PLS: response.tokenDataUSDLs[0]?.USDL2PLS || 0,
    };
}

async function getStakingAPR(
  timestamp24hAgo: number
): Promise<{ loanstakingPRDataByDays: StakingAPRDataArray }> {
  const operationName = "QueryStakingAPR";
  const query =
    "query QueryStakingAPR($first: Int!) {\n  loanstakingPRDataByDays(\n orderBy: day\n    orderDirection: desc\n first: $first\n ) {\n    day\n    date\n    price\n    loanPrice\n    usdlPrice\n    totalBorrowingFeesPaid\n    totalRedemptionFeesPaid\n    totalLOANTokensStaked\n    __typename\n  }\n}";

  const variables = {
    first: 365,
  };

  const response: StakingAPRResponse =
    await makeGraphQlRequest<StakingAPRResponse>(
      operationName,
      query,
      variables
    );

  return {
    loanstakingPRDataByDays: response.loanstakingPRDataByDays,
  };
}

async function getStabilityAPR(
  day_num: number
): Promise<{ usdlstabilityAPRDataByDays: StabilityAPRDataArray }> {
  const operationName = "QueryStabilityAPR";
  const query =
    "query ($first: Int!) {\n  usdlstabilityAPRDataByDays(orderBy: day, orderDirection: desc, first: $first) {\n    day\n    date\n    price\n    loanPrice\n    usdlPrice\n    totalPLSFromLiquidations\n    totalUSDLTokensStaked\n    totalLOANTokensIssued\n    __typename\n  }\n}";

  const variables = {
    first: 365,
  };

  const response: StabilityAPRResponse =
    await makeGraphQlRequest<StabilityAPRResponse>(
      operationName,
      query,
      variables
    );

  return {
    usdlstabilityAPRDataByDays: response.usdlstabilityAPRDataByDays,
  };
}

export async function fetchAllPriceData(): Promise<{
  plsPrice: number;
  plsChange: number;
  loanPrice: number;
  usdlPrice: number;
  loanChange: number;
  usdlChange: number;
}> {
  const currentTime = new Date();
  const twentyFourHoursAgo = new Date(
    currentTime.getTime() - 24 * 60 * 60 * 1000
  );
  const twentyFourHoursAgoTimeStamp = Math.floor(
    twentyFourHoursAgo.getTime() / 1000
  );
  const [currentPricePLS, pricePLS24hrsAgo, prices, prices24hrsAgo] =
    await Promise.all([
      getPriceOfPLS(),
      getPriceOfPLS24hrsAgo(twentyFourHoursAgoTimeStamp),
      getPrices(),
      getPrices24hrsAgo(twentyFourHoursAgoTimeStamp),
    ]);
  // console.log(await getPriceOfPLS())
  // console.log(await getPriceOfPLS24hrsAgo(1690958000))
  // console.log(await getPrices24hrsAgo(1690958000))

  return {
    plsPrice: Number(currentPricePLS),
    plsChange: (currentPricePLS - pricePLS24hrsAgo) / pricePLS24hrsAgo,
    loanPrice: prices.LOAN2PLS * currentPricePLS,
    usdlPrice: prices.USDL2PLS * currentPricePLS,
    loanChange:
      (prices.LOAN2PLS - prices24hrsAgo.LOAN2PLS) / prices24hrsAgo.LOAN2PLS,
    usdlChange:
      (prices.USDL2PLS - prices24hrsAgo.USDL2PLS) / prices24hrsAgo.USDL2PLS,
  };
}

export async function fetchStakingAPR(): Promise<{
  stakingAPR: number;
}> {
  var currentDate = new Date();
  var epochDate = new Date(0);

  var dayNumberEpoch = Math.floor(
    (currentDate.getTime() - epochDate.getTime()) / (24 * 60 * 60 * 1000)
  );

  const response = await getStakingAPR(dayNumberEpoch);
  let totalFees = 0;
  let aprLength = 0;
  let totalLoaninPoolLast = 0;

  for (
    let index = 0;
    index < response.loanstakingPRDataByDays.length;
    index++
  ) {
    const element = response.loanstakingPRDataByDays[index];
    if (Number(element.loanPrice) && Number(element.usdlPrice)) {
      aprLength++;
      let loanPriceUSD = Number(element.loanPrice) * Number(element.price);
      let usdlPriceUSD = Number(element.usdlPrice) * Number(element.price);
      let totalRedemptionFeesPaid = Number(element.totalRedemptionFeesPaid) * Number(element.price);
      let totalBorrowingFeesPaid = Number(element.totalBorrowingFeesPaid) * usdlPriceUSD;
      let totalLOANTokensStaked = Number(element.totalLOANTokensStaked) * loanPriceUSD;

      totalLoaninPoolLast = totalLOANTokensStaked;
      totalFees = totalFees + totalRedemptionFeesPaid + totalBorrowingFeesPaid;
    }
  }

  return {
    stakingAPR:
      totalLoaninPoolLast !== 0
        ? (((totalFees / totalLoaninPoolLast)/ aprLength) * 365 * 100) 
        : 0,
  };
}

export async function fetchStabilityAPR(): Promise<{
  stabilityAPR: number;
}> {
  var currentDate = new Date();
  var epochDate = new Date(0);

  var dayNumberEpoch = Math.floor(
    (currentDate.getTime() - epochDate.getTime()) / (24 * 60 * 60 * 1000)
  );

  const response = await getStabilityAPR(dayNumberEpoch);
  let totalFees = 0;
  let aprLength = 0;
  let totalUSDLinPoolLast = 0;

  for (
    let index = 0;
    index < response.usdlstabilityAPRDataByDays.length;
    index++
  ) {
    const element = response.usdlstabilityAPRDataByDays[index];
    if (Number(element.loanPrice) && Number(element.usdlPrice)) {
      aprLength++;
      let loanPriceUSD = Number(element.loanPrice) * Number(element.price);
      let usdlPriceUSD = Number(element.usdlPrice) * Number(element.price);
      let totalLiquidationFeesPaid = Number(element.totalPLSFromLiquidations) * Number(element.price);
      let totalIssuanceFees = Number(element.totalLOANTokensIssued) * loanPriceUSD;
      let totalUSDLinPool = Number(element.totalUSDLTokensStaked) * usdlPriceUSD;

      totalUSDLinPoolLast = totalUSDLinPool + totalUSDLinPoolLast;
      totalFees = totalFees + totalLiquidationFeesPaid + totalIssuanceFees;
    }
  }

  return {
    stabilityAPR:
      totalUSDLinPoolLast !== 0
        ? ((totalFees / totalUSDLinPoolLast / aprLength) * 365 * 100)
        : 0,
  };
}
