import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link, Paragraph } from "theme-ui";
import { useLiquidLoans } from "../../../../hooks/LiquidLoansContext";
import { Icon } from "../../../Icon";
import { InfoMessage } from "../../../InfoMessage";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLOAN } from "../RemainingLOAN";

const PLSXlink = (usdlAddress: string) =>
  `${process.env.REACT_APP_PULSEX_LINK}/add/PLS/${usdlAddress}`;

export const Inactive: React.FC = () => {
  const { dispatchEvent } = useFarmView();

  const {
    liquidLoans: {
      connection: { addresses },
    },
  } = useLiquidLoans();

  const handleStakePressed = useCallback(() => {
    dispatchEvent("STAKE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        PLSX Liquidity Farm
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLOAN />
        </Flex>
      </Heading>
      <Card sx={{ p: [2, 3] }} variant="main_card">
        <InfoMessage title="You aren't farming LOAN.">
          <Paragraph>
            You can farm LOAN by staking your PLSX PLS/USDL LP tokens.
          </Paragraph>

          <Paragraph sx={{ mt: 2 }}>
            You can obtain LP tokens by adding liquidity to the{" "}
            <Link href={PLSXlink(addresses["usdlToken"])} target="_blank">
              PLS/USDL pool on PLSX. <Icon name="external-link-alt" size="xs" />
            </Link>
          </Paragraph>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleStakePressed}>Stake</Button>
        </Flex>
      </Card>
    </Card>
  );
};
