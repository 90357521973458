import { Theme, ThemeUIStyleObject } from "theme-ui";
// import cardBgImage from '/bg/card-bg.png'
import { auto } from "@popperjs/core";

const baseColors = {
  blue: "#1542cd",
  purple: "#745ddf",
  cyan: "#2eb6ea",
  green: "#28c081",
  yellow: "#fd9d28",
  red: "#dc2c10",
  lightRed: "#ff755f"
};

const colors = {
  primary: baseColors.blue,
  secondary: baseColors.purple,
  accent: baseColors.cyan,
  pupleButton:'radial-gradient(50% 50% at 50% 50%, #651A91 0%, #5A1B7E 100%)',
  success: baseColors.green,
  warning: baseColors.yellow,
  danger: baseColors.red,
  dangerHover: baseColors.lightRed,
  info: baseColors.blue,
  invalid: "pink",
  main_bg:'#010001',
  text: "#FFFFFF",
  background: "black",
  muted: "#eaebed",
  barista_red: "#e80368",
  barista_blue: '#5578ff',
  barista_yellow: '#ffbb2c',
  barista_green:'#29cc61'
};

const buttonBase: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  ":enabled": { cursor: "pointer" }
};

const button: ThemeUIStyleObject = {
  ...buttonBase,

  px: "32px",
  py: "12px",

  color: "white",
  border: 1,

  fontWeight: "bold",

  ":disabled": {
    opacity: 0.5
  }
};

const buttonOutline = (color: string, hoverColor: string): ThemeUIStyleObject => ({
  color,
  borderColor: color,
  background: "none",

  ":enabled:hover": {
    color: "background",
    bg: hoverColor,
    borderColor: hoverColor
  }
});

const iconButton: ThemeUIStyleObject = {
  ...buttonBase,

  padding: 0,
  width: "40px",
  height: "40px",

  background: "none",

  ":disabled": {
    color: "text",
    opacity: 0.25
  }
};

const cardHeadingFontSize = 18.7167;

const cardGapX = [0, 3, 4];
const cardGapY = [3, 3, 4];

const card: ThemeUIStyleObject = {
  position: "relative",
  mt: cardGapY,
  border: 1,
  boxShadow: [1, null, 2]
};

const infoCard: ThemeUIStyleObject = {
  ...card,

  padding: 3,

  borderColor: "rgba(122,199,240,0.4)",
  background: "linear-gradient(200deg, #d4d9fc, #cae9f9)",

  h2: {
    mb: 2,
    fontSize: cardHeadingFontSize
  }
};

const formBase: ThemeUIStyleObject = {
  display: "block",
  width: "auto",
  flexShrink: 0,
  padding: 2,
  fontSize: 3
};

const formCell: ThemeUIStyleObject = {
  ...formBase,

  bg: "background",
  border: 1,
  borderColor: "muted",
  borderRadius: 0,
  boxShadow: [1, 2]
};

const overlay: ThemeUIStyleObject = {
  position: "absolute",

  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const modalOverlay: ThemeUIStyleObject = {
  position: "fixed",

  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh"
};

const headerGradient: ThemeUIStyleObject = {
  background: colors.main_bg
};

const theme: Theme = {
  breakpoints: ["48em", "52em", "74em"],

  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],

  fonts: {
    body: [
      "Iceland",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif"
    ].join(", "),
    heading: "inherit",
    monospace: "Menlo, monospace"
  },

  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],

  fontWeights: {
    body: 400,
    heading: 600,

    light: 200,
    medium: 500,
    bold: 600
  },

  lineHeights: {
    body: 1.5,
    heading: 1.25
  },

  colors,

  borders: [0, "1px solid", "2px solid"],

  shadows: ["0", "0px 4px 8px rgba(41, 49, 71, 0.1)", "0px 8px 16px rgba(41, 49, 71, 0.1)"],

  text: {
    address: {
      fontFamily: 'Iceland',
      fontSize: 1
    }
  },

  buttons: {
    primary: {
      ...button,
      // border:'none',
      border: "2px solid transparent",
      background: colors.pupleButton,
      // borderColor: "primary",
      borderRadius:'10px',
      ":enabled:hover": {
        // bg: "secondary",
        // borderColor: "secondary"
        background: [
          
          "linear-gradient(#2B093F, #2B093F) padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box",
        ],
        borderRadius: "10px",
        border: "2px solid transparent",
      },
      width:'100%'
    },
    connect_wallet: {
      ...button,
      // border:'none',
    
      border: "2px solid transparent",
      background: colors.pupleButton,
      // borderColor: "primary",
      borderRadius:'10px',
      ":enabled:hover": {
        // bg: "secondary",
        // borderColor: "secondary"
        background: [
          
          "linear-gradient(#2B093F, #2B093F) padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box",
        ],
        borderRadius: "10px",
        border: "2px solid transparent",
      },
    },
    liquidate: {
      ...button,
      border:'none',
      background: 'gray',
      // borderColor: "primary",
      borderRadius:'18px',
      ":enabled:hover": {
        bg: "red",
        borderColor: "red"
      }
    },

    outline: {
      ...button,
      ...buttonOutline("primary", "secondary")
    },

    cancel: {
      ...button,
      ...buttonOutline("text", "text"),

      opacity: 0.8
    },

    danger: {
      ...button,

      bg: "danger",
      borderColor: "danger",

      ":enabled:hover": {
        bg: "dangerHover",
        borderColor: "dangerHover"
      }
    },

    icon: {
      ...iconButton,
      color: "primary",
      ":enabled:hover": { color: "accent" }
    },

    menu_icon: {
      ...iconButton,
      color: "grey",
      ":enabled:hover": { color: "grey" }
    },

    dangerIcon: {
      ...iconButton,
      color: "danger",
      ":enabled:hover": { color: "dangerHover" }
    },

    titleIcon: {
      ...iconButton,
      mr: 0,
      ml:'auto',
      color: "text",
      ":enabled:hover": { color: "success" }
    }
  },

 

  grids: {
      protocol_performance: {
      background:
    `url("/bg/card-bg.png") `,
  borderRadius: "10px",
    
      py: '10px',
      px:'20px'
      
  },
  },

  cards: {
    primary: {
     
    },

    main_card: {
     
      position:'relative',
        background:
      `url("/bg/card-bg.png") padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box`,
    borderRadius: "10px",
    border: "1px solid transparent",
    },

    second_card: {
      pb: [2, 3],
      background:`url("/bg/card-bg.png") padding-box`,
      borderRadius: "10px",
      
    },

    liquidation_card: {
      my:'10px',
        background:
      ["black",`linear-gradient(black,black) padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box`],
    borderRadius: ["0px","10px"],
      border: ["inherit", "1px solid transparent"],
      borderTop:["none",'1px solid transparent'],
      boxShadow:"0px -10px 10px -10px rgba(128, 0, 128, 0.5)"
    // border: "1px solid transparent",
    },
    liquidation_second_card: {
      background:`url("/bg/card-bg.png") padding-box`,
    },

  


    

    info: {
      ...infoCard,

      display: ["none", "block"]
    },

    infoPopup: {
      ...infoCard,

      position: "fixed",
      top: 0,
      right: 3,
      left: 3,
      mt: "72px",
      height: "80%",
      overflowY: "scroll"
    },

    tooltip: {
      padding: '12px',
      wordWrap: 'break-word',
      // border: 1,
      // borderColor: "muted",
      borderRadius: "8px",
      background: '#200320',
    
boxShadow: '0px 2px 2px -2px rgba(16, 24, 40, 0.03), 0px 4px 4px -4px rgba(16, 24, 40, 0.08)',
     
border:'1px solid #4F0E75',
      fontSize: '12px',
      color: "white",
      fontWeight: "body",
      zIndex: 1
    }
  },

  forms: {
    label: {
      ...formBase
    },

    unit: {
      ...formCell,

      textAlign: "center",
      bg: "muted"
    },

    input: {
      ...formCell,

      flex: 1
    },

    editor: {
      // background:'red'
    }
  },

  layout: {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",

      position: ["relative", "relative"],
      width: "100%",
      top: 0,
      zIndex: 1,

      px: ['10px', null,null,'10px'],
      py: [2, "12px", "12px"],

      ...headerGradient,
      boxShadow: [1, "none"]
    },

    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      mt: cardGapY,
      px: 3,
      minHeight: "72px",

      bg: "muted"
    },

    main: {
      width: "100%",
      // maxWidth: "912px",
      mx: "auto",
      mt: [0, 0],
      // mb: ["40px", "40px"],
     
      // px: cardGapX,
      background: colors.main_bg,
     
    },

    columns: {
     
      display: "flex",
      flexWrap: "wrap",
      justifyItems: "center",
      height:'100%',
      pb:'20px'
    },

    left: {
      // pr: cardGapX,
      pt: [0, 3, '60px'],
      pr: [0, 3, '20px'],
      pl: [0, 3, '60px'],
      pb:[0, 3, '60px'],
      width: ["100%", "50%"]
    },

    right: {
      // px:[0, 3, '20px'],
      pt: [0, 3, '60px'],
      pr: [0, 3, '60px'],
      pl: [0, 3, '20px'],
      pb:[0, 3, '60px'],
      width: ["100%", "50%"]
    },
    center: {
      maxWidth: '1800px',
      mx:auto,
      
      pt: ['10px', '10px', '20px'],
      px: [0, 3, '60px'],
      
      width: ["100%", "100%"]
    },

    bottom: {
      maxWidth: '1800px',
      mx:auto,
      
      pt: ['20px', '10px'],
      px: ['20px', '10px', '60px'],
      pb:[0, 3, 0],
      width: ["100%", "100%"]
    },

    actions: {
      justifyContent: "center",
    
      mt: 2,

      button: {
        mx: 2
      }
    },
    liquidation: {
      justifyContent: "flex-end",
      mt: 2,
      ml: 'auto',
      mr:0,

      button: {
        ml: 'auto',
        mr:0
      }
    },

    disabledOverlay: {
      // ...overlay,
      position: "absolute",

  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
      zIndex:10,
      bg: "rgba(0, 0, 0, 0.8)"
    },

    modalOverlay: {
      ...modalOverlay,

      bg: "rgba(0, 0, 0, 0.7)",

      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },

    modal: {
      // background: 'rgba(51, 46, 46,1)',
      background:
      `url("/bg/card-bg.png") padding-box,linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%) border-box`,
    borderRadius: "10px",
    border: "1px solid transparent",
      padding: 3,
      width: ["100%", "40em"]
    },

    infoOverlay: {
      ...modalOverlay,

      display: ["block", null,null,"none"],

      bg: "rgba(0, 0, 0, 0.7)"
    },

    infoMessage: {
      display: "flex",
      justifyContent: "center",
      m: 3,
      alignItems: "center",
      minWidth: "128px"
    },

    sidenav: {
      display: ["flex", null,null,"none"],
      flexDirection: "column",
      // px: ['20px',0],
      m: 0,
      borderColor: "muted",
      // mr: "40vw",
      height: "100%",
      ...headerGradient
    },
    sidebar: {
      background: colors.main_bg,
      position: ['static',null,null,'fixed'],
      top: 0,
      left: 0,
      bottom:0,
      overflowY:'scroll'
      
    },

    badge: {
      border: 0,
      borderRadius: 3,
      p: 1,
      px: 2,
      backgroundColor: "muted",
      color: "slate",
      fontSize: 1,
      fontWeight: "body"
    }
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",

      height: "100%",

      "#root": {
        height: "100%"
      }
    },
   

    a: {
      color: "primary",
      ":hover": { color: "accent" },
      textDecoration: "none",
      fontWeight: "bold"
    }
  },

  links: {
    nav: {
      px: 2,
      py: 2,
      fontWeight: "medium",
      fontSize: 2,
      textTransform: "none",
      letterSpacing: "2px",
      width: ["100%", "auto"],
      mt: [3, "auto"],
      
      
    }
  }
};

export default theme;
