import { Box, Flex, Text, useThemeUI } from "theme-ui";

import { Icon } from "./Icon";

export const ActionDescription: React.FC = ({ children }) => {
  const theme = useThemeUI();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        fontFamily: "Titillium Web, sans-serif",
        my: [2, 3],
        // p: 3,
        color: theme.theme.colors?.barista_blue,
        // border: 1,
        borderRadius: "8px",
        borderColor: "accent",
        // boxShadow: 2,
        // bg: "rgba(46, 182, 234, 0.05)",
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Icon name="info-circle" size="lg" />
        <Text sx={{ ml: 2 }}>{children}</Text>
      </Flex>
    </Box>
  );
};

export const Amount: React.FC = ({ children }) => (
  <Text sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>{children}</Text>
);
