import React from "react";
import { Flex, Image } from "theme-ui";
import BaristaLogo from "../assets/images/barista-logo.png";
import MenuNavLinks from "./MenuNavLinks";
import { Link } from "react-router-dom";

type Props = {};

function SideBar({}: Props) {
  const spanStyle = {
    padding: '10px', // You can adjust the padding value as needed
  };
  return (
    <>
      <Flex
        variant="layout.sidebar"
        sx={{
          // width: [0, "20%"],
          // maxWidth: "240px",
          width: [0, "240px"],
          minHeight: "100%",
          display: ["none", "none", "none", "flex"],
          flexDirection: "column",
        }}
      >
        <Image src={BaristaLogo} />
        <MenuNavLinks />
      </Flex>
      <Flex
        sx={{
          // width: [0, "20%"],
          // minHeight: "100%",
          minWidth: [0, "240px"],
          display: ["none", "none", "none", "flex"],
          flexDirection: "column",
        }}
      ></Flex>
    </>
  );
}

export default SideBar;
