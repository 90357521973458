import React from "react";
import { Card, Heading, Link, Box, Text, Grid, Flex } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { Decimal, Percent, LiquidLoansStoreState } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";

import { useLiquidLoans } from "../hooks/LiquidLoansContext";
import { COIN, GT } from "../strings";
import { Statistic } from "./Statistic";
// import cardBgImage from "../assets/images/bg/card-bg.png";

const selectBalances = ({
  accountBalance,
  usdlBalance,
  loanBalance,
}: LiquidLoansStoreState) => ({
  accountBalance,
  usdlBalance,
  loanBalance,
});

const Balances: React.FC = () => {
  const { accountBalance, usdlBalance, loanBalance } =
    useLiquidLoansSelector(selectBalances);

  return (
    <Box sx={{ mb: 3 }}>
      <Heading>My Account Balances</Heading>
      <Statistic name="PLS"> {accountBalance.prettify(2)}</Statistic>
      <Statistic name={COIN}> {usdlBalance.prettify()}</Statistic>
      <Statistic name={GT}>{loanBalance.prettify()}</Statistic>
    </Box>
  );
};

const GitHubCommit: React.FC<{ children?: string }> = ({ children }) =>
  children?.match(/[0-9a-f]{40}/) ? (
    <Link
      href={`https://github.com/Liquid-Loans-Official/monorepo/commit/${children}`}
    >
      {children.substr(0, 7)}
    </Link>
  ) : (
    <>unknown</>
  );

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
};

const select = ({
  numberOfVaults,
  price,
  total,
  usdlInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLOAN,
  frontend,
  stabilityDeposit
}: LiquidLoansStoreState) => ({
  numberOfVaults,
  price,
  total,
  usdlInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLOAN,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null,
  stabilityDeposit
});

export const SystemStats: React.FC<SystemStatsProps> = ({
  variant = "prot_performance",
  showBalances,
}) => {
  const {
    liquidLoans: {
      connection: { version: contractsVersion, deploymentDate, frontendTag },
    },
  } = useLiquidLoans();

  const {
    numberOfVaults,
    price,
    usdlInStabilityPool,
    total,
    borrowingRate,
    totalStakedLOAN,
    kickbackRate,
    stabilityDeposit
  } = useLiquidLoansSelector(select);

  const usdlInStabilityPoolPct =
    total.debt.nonZero && new Percent(usdlInStabilityPool.div(total.debt));
  const usdlInBammPct = new Percent(stabilityDeposit.totalLusdInBamm.div(usdlInStabilityPool))

  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  const borrowingFeePct = new Percent(borrowingRate);
  const kickbackRatePct =
    frontendTag === AddressZero ? "100" : kickbackRate?.mul(100).prettify();

  return (
    <Card {...{ variant }}>
      {showBalances && <Balances />}

      <Heading
        sx={{
          fontSize: ["28px", null, null, "36px"],
          px: [0, 0, 0, 0],
          // my: [2, 2, 2, 2],
        }}
      >
        Protocol Performance
      </Heading>
      <Box
        sx={{
          height: "2px",
          width: ["86px", "100px", "118px", "128px"],
          mx: [0, 0, 0, 0],
          mt: "8px",
          mb: ["12px", "12px"],
          background:
            "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
        }}
      />

      <Flex
        sx={{
          minWidth: "100%",
          justifyContent: "space-around",
          display: ["none !important", null, null, "flex !important"],
          background: `url("/bg/card-bg.png") `,
          borderRadius: "10px",
          flexDirection: ["column", "row"],

          py: "16px",
          px: "20px",
        }}
        // columns={[2, 4, 6, 7]}
        variant="grids.protocol_performance"
      >
        <Statistic
          name="Total Value Locked"
          tooltip="The Total Value Locked (TVL) is the total value of Pulse locked as collateral in the system, given in PLS and USD."
        >
          <Flex sx={{ alignItems: "center" }}>
            {total.collateral.shorten()}{" "}
            <Text sx={{ fontSize: "18px" }}>&nbsp;PLS</Text>
          </Flex>
          <Text sx={{ fontSize: 1 }}>
            &nbsp;(${Decimal.from(total.collateral.mul(price)).shorten()})
          </Text>
        </Statistic>
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        <Statistic
          name="Borrowing Fee"
          tooltip="The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in USDL) and is part of a Vault's debt. The fee varies between 0.5% and 5% depending on USDL redemption volumes."
        >
          {borrowingFeePct.toString(2)}
        </Statistic>
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        <Statistic
          name="Vaults"
          tooltip="The total number of active Vaults in the system."
        >
          {Decimal.from(numberOfVaults).prettify(0)}
        </Statistic>
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        <Statistic
          name="USDL supply"
          tooltip="The total USDL minted by the Liquid Loans Protocol."
        >
          {total.debt.shorten()}
        </Statistic>
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
          />
          {usdlInStabilityPoolPct && (
          <Statistic
            name="USDL in Stability Pool"
            tooltip="The total USDL currently held in the Stability Pool, expressed as an amount and a fraction of the USDL supply."
          >
            {usdlInStabilityPool.shorten()}
            <Text sx={{ fontSize: 1 }}>
              ({usdlInStabilityPoolPct.toString(1)})
            </Text>
          </Statistic>
          )}

        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        {stabilityDeposit.totalLusdInBamm && (
        <Statistic
          name="USDL in Barista"
          tooltip="The total USDL currently held in the Barista Pool, expressed as an amount and a fraction of the USDL in the Stability Pool."
        >
          {stabilityDeposit.totalLusdInBamm.shorten()}
          <Text sx={{ fontSize: 1 }}>&nbsp;({usdlInBammPct.toString(1)})</Text>
        </Statistic>
        )}
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        <Statistic
          name="Staked LOAN"
          tooltip="The total amount of LOAN that is staked for earning fee revenue."
        >
          {totalStakedLOAN.shorten()}
        </Statistic>
        <Box
          sx={{
            minHeight: "81px",
            width: "1px",
            background: "#7823AA82",
            ml: 2,
          }}
        />
        {/* <Statistic
          name="Total Collateral Ratio"
          tooltip="The ratio of the Dollar value of the entire system collateral at the current PLS:USD price, to the entire system debt."
        >
          {totalCollateralRatioPct.prettify()}
        </Statistic> */}
        <Statistic
          name="Recovery Mode"
          tooltip="Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Vault can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Vault's debt. Operations are also restricted that would negatively impact the TCR."
        >
          {total.collateralRatioIsBelowCritical(price) ? (
            <Box color="danger">Yes</Box>
          ) : (
            "No"
          )}
        </Statistic>
        {}

        {/* <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
          Frontend
        </Heading>
        {kickbackRatePct && (
          <Statistic
            name="Kickback Rate"
            tooltip="A rate between 0 and 100% set by the Frontend Operator that determines the fraction of LOAN that will be paid out as a kickback to the Stability Providers using the frontend."
          >
            {kickbackRatePct}%
          </Statistic>
        )} */}
      </Flex>
      <Flex
        sx={{
          width: ["100%", "100%", "60%", "100%"],
          justifyContent: ["space-between", "center", "center", null],
          alignItems: "center",
          display: ["flex", "flex", "flex", "none"],
          flexDirection: "column",
          gap: "20px",
          py: ["20px", null, null, 0],
        }}
        // columns={[2, 4, 6, 7]}
        variant="grids.protocol_performance"
      >
        <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
          <Statistic
            name="Total Value Locked"
            tooltip="The Total Value Locked (TVL) is the total value of Pulse locked as collateral in the system, given in PLS and USD."
          >
            <Flex sx={{ alignItems: "center" }}>
              {total.collateral.shorten()}{" "}
              <Text sx={{ fontSize: "18px" }}>&nbsp;PLS</Text>
            </Flex>
            <Text sx={{ fontSize: 1 }}>
              &nbsp;(${Decimal.from(total.collateral.mul(price)).shorten()})
            </Text>
          </Statistic>
          <Box
            sx={{
              height: "81px",

              width: "1px",
              background: "#7823AA82",
              ml: 2,
            }}
          />
          <Statistic
            name="Borrowing Fee"
            tooltip="The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in USDL) and is part of a Vault's debt. The fee varies between 0.5% and 5% depending on USDL redemption volumes."
          >
            {borrowingFeePct.toString(2)}
          </Statistic>
        </Flex>
        <Flex sx={{ width: "100%", justifyContent: "space-around" }}>
          <Statistic
            name="Vaults"
            tooltip="The total number of active Vaults in the system."
          >
            {Decimal.from(numberOfVaults).prettify(0)}
          </Statistic>
          <Box
            sx={{
              height: "81px",
              width: "1px",
              background: "#7823AA82",
              ml: 2,
            }}
          />
          {stabilityDeposit.totalLusdInBamm && (
          <Statistic
            name="USDL in Barista"
            tooltip="The total USDL currently held in the Barista Pool, expressed as an amount and a fraction of the USDL in the Stability Pool."
          >
            {stabilityDeposit.totalLusdInBamm.shorten()}
            <Text sx={{ fontSize: 1 }}>&nbsp;({usdlInBammPct.toString(1)})</Text>
          </Statistic>
          )}{" "}
        </Flex>
        <Flex sx={{ width: "100%", justifyContent: "space-around" }}>
          {usdlInStabilityPoolPct && (
            <Statistic
              name="USDL in Stability Pool"
              tooltip="The total USDL currently held in the Stability Pool, expressed as an amount and a fraction of the USDL supply."
            >
              {usdlInStabilityPool.shorten()}
              <Text sx={{ fontSize: 1 }}>
                ({usdlInStabilityPoolPct.toString(1)})
              </Text>
            </Statistic>
          )}{" "}

          <Box
            sx={{
              height: "81px",
              width: "1px",
              background: "#7823AA82",
              ml: 2,
            }}
          />
          <Statistic
            name="Staked LOAN"
            tooltip="The total amount of LOAN that is staked for earning fee revenue."
          >
            {totalStakedLOAN.shorten()}
          </Statistic>
          {/* <Statistic
          name="Total Collateral Ratio"
          tooltip="The ratio of the Dollar value of the entire system collateral at the current PLS:USD price, to the entire system debt."
          >
            {totalCollateralRatioPct.prettify()}
          </Statistic> */}
        </Flex>

        <Flex sx={{ width: "100%", justifyContent: "space-around" }}>
        <Statistic
            name="USDL supply"
            tooltip="The total USDL minted by the Liquid Loans Protocol."
          >
            {total.debt.shorten()}
          </Statistic>

          <Box
            sx={{
              height: "81px",
              width: "1px",
              background: "#7823AA82",
              ml: 2,
            }}
          />
          <Statistic
            name="Recovery Mode"
            tooltip="Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Vault can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Vault's debt. Operations are also restricted that would negatively impact the TCR."
          >
            {total.collateralRatioIsBelowCritical(price) ? (
              <Box color="danger">Yes</Box>
            ) : (
              "No"
            )}
          </Statistic>

        </Flex>

        {}

        {/* <Heading as="h2" sx={{ mt: 3, fontWeight: "body" }}>
          Frontend
        </Heading>
        {kickbackRatePct && (
          <Statistic
            name="Kickback Rate"
            tooltip="A rate between 0 and 100% set by the Frontend Operator that determines the fraction of LOAN that will be paid out as a kickback to the Stability Providers using the frontend."
          >
            {kickbackRatePct}%
          </Statistic>
        )} */}
      </Flex>
    </Card>
  );
};
