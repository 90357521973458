import React, { useState } from "react";
import { Card, Box, Heading, Flex, Button, Label, Input } from "theme-ui";

import { useLiquidLoans } from "../hooks/LiquidLoansContext";

import { Icon } from "./Icon";
import { Transaction } from "./Transaction";

export const LiquidationManager: React.FC = () => {
  const {
    liquidLoans: { send: liquidLoans },
  } = useLiquidLoans();
  const [numberOfVaultsToLiquidate, setNumberOfVaultsToLiquidate] =
    useState("90");

  return (
    <Flex variant="layout.actions">
      <Transaction
        id="batch-liquidate"
        tooltip="Liquidate"
        tooltipPlacement="bottom"
        send={(overrides) => {
          if (!numberOfVaultsToLiquidate) {
            throw new Error("Invalid number");
          }
          return liquidLoans.liquidateUpTo(
            parseInt(numberOfVaultsToLiquidate, 10),
            overrides
          );
        }}
      >
        <Button>Liquidate All</Button>
      </Transaction>
    </Flex>
  );
};
