import { Box, Card, Heading, Paragraph, Text } from "theme-ui";

import { InfoMessage } from "../InfoMessage";
import { Icon } from "../Icon";

type DisabledRedemptionProps = {
  disabledDays: number;
  unlockDate: Date;
};

export const DisabledRedemption: React.FC<DisabledRedemptionProps> = ({
  disabledDays,
  unlockDate,
}) => (
  <Card>
    <Heading sx={{ px: ["20px", 0, 0, 0] }}>Redeem</Heading>

    <Card sx={{ p: [2, 3] }} variant="main_card">
      <InfoMessage
        title="Redemption is not enabled yet."
        icon={
          <Box sx={{ color: "warning" }}>
            <Icon name="exclamation-triangle" />
          </Box>
        }
      >
        <Paragraph>
          USDL redemption is disabled for the first {disabledDays} days after
          launch.
        </Paragraph>

        <Paragraph sx={{ mt: 3 }}>
          It will be unlocked at{" "}
          <Text sx={{ fontWeight: "medium" }}>
            {unlockDate.toLocaleString()}
          </Text>
          .
        </Paragraph>
      </InfoMessage>
    </Card>
  </Card>
);
