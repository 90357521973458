import { Decimal } from "@liquidloans/lib-base";
import React, { useState } from "react";
import { Box, Button, Flex, useThemeUI } from "theme-ui";

type TabProps = {
  value: Decimal;
  setFinalValue: (value: Decimal) => void;
};

const PercentageSelector: React.FC<TabProps> = ({ value, setFinalValue }) => {
  const handleButtonClick = (percentage: number) => {
    try {
  
      const calculatedValue: Decimal = value
        .mul(Decimal.from(percentage))
        .div(Decimal.from(100));
              
      setFinalValue(calculatedValue);
      setSelectedPer(percentage);
    } catch (error) {
    }
  };
  const [selectedPer, setSelectedPer] = useState(0);

  return (
    <Flex sx={{ justifyContent: "space-between", gap: "15px", my: 3 }}>
      {[25, 50, 75, 100].map((percentage) => (
        <Button
          key={percentage}
          variant="tab"
          sx={{
            width: "100%",
            borderRadius: "10px",
            cursor: "pointer",
            fontSize: "13px",
            opacity: percentage === selectedPer ? 1 : 0.7,
            backgroundColor:
              percentage === selectedPer ? "#4F0E7585" : "#4F0E7585",
            color: "white",
            "&:hover": {
              //   backgroundColor: "green",
              opacity: 1,
            },
          }}
          onClick={() => handleButtonClick(percentage)}
        >
          {percentage}%
        </Button>
      ))}
    </Flex>
  );
};

export default PercentageSelector;
