import React from "react";
import { Card, Flex, Text, useThemeUI } from "theme-ui";

import {
  PiBatteryVerticalEmptyBold,
  PiBatteryVerticalLowBold,
  PiBatteryVerticalMediumBold,
  PiBatteryVerticalHighBold,
  PiBatteryVerticalFullBold,
} from "react-icons/pi";
import { LiquidLoansStoreState, Percent } from "@liquidloans/lib-base";
import { useLiquidLoansSelector } from "@liquidloans/lib-react";
import { InfoIcon } from "./InfoIcon";

type Props = {};

var TCR = 190;

const batterySize = 30;

const select = ({
  numberOfVaults,
  price,
  total,
  usdlInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLOAN,
  frontend,
}: LiquidLoansStoreState) => ({
  numberOfVaults,
  price,
  total,
  usdlInStabilityPool,
  borrowingRate,
  redemptionRate,
  totalStakedLOAN,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null,
});

type BatteryProps = {
  _tcr: number;
};
const Battery: React.FC<BatteryProps> = ({ _tcr }) => {
  const theme = useThemeUI();

  if (_tcr > 200) {
    return (
      <PiBatteryVerticalFullBold
        fontSize={batterySize}
        color={`${theme.theme.colors?.barista_green || "green"}`}
      />
    );
  } else if (_tcr > 150) {
    return (
      <PiBatteryVerticalHighBold
        fontSize={batterySize}
        color={`${theme.theme.colors?.barista_yellow || "yellow"}`}
      />
    );
  } else if (_tcr > 120) {
    return (
      <PiBatteryVerticalMediumBold
        fontSize={batterySize}
        color={`${theme.theme.colors?.barista_yellow || "yellow"}`}
      />
    );
  } else if (_tcr > 50) {
    return (
      <PiBatteryVerticalLowBold
        fontSize={batterySize}
        color={`${theme.theme.colors?.barista_yellow || "yellow"}`}
      />
    );
  } else {
    return (
      <PiBatteryVerticalEmptyBold
        fontSize={batterySize}
        color={`${theme.theme.colors?.barista_red || "red"}`}
      />
    );
  }
};

function Tcr({}: Props) {
  const {
    numberOfVaults,
    price,
    usdlInStabilityPool,
    total,
    borrowingRate,
    totalStakedLOAN,
    kickbackRate,
  } = useLiquidLoansSelector(select);

  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  return (
    <Flex
      sx={{
        width: "fit-content",
        alignItems: "center",
        gap: 1.5,
        height: "fit-content",
        // display: ["none", "flex"],
      }}
    >
      <Battery _tcr={Number(total.collateralRatio(price)) * 100} />
      <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
        <Text sx={{ fontSize: "20px", lineHeight: "14px" }}>
          TCR{" "}
          <span style={{ color: "gray", fontSize: "12px" }}>
            {total.collateralRatioIsBelowCritical(price)
              ? "(Recovery Mode)"
              : "(Normal Mode)"}
          </span>
        </Text>
        <Flex sx={{ gap: 2, alignItems: "center" }}>
          <Text
            sx={{
              lineHeight: "12px",
              fontSize: "12px",
              fontFamily: "Titillium Web, sans-serif",
            }}
          >
            {totalCollateralRatioPct.prettify()}
          </Text>
          <InfoIcon
            size="xs"
            tooltip={
              <Card
                variant="tooltip"
                sx={{
                  fontSize: "12px",
                  wordWrap: "break-word",
                  maxWidth: "240px",
                  display: "inline-block",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              >
                TCR = Total Collateral Ratio and is shown as: <br />
                <br />
                1 red bar — less than 110% — recovery mode <br />2 orange —
                between 150% and 110% — recovery <br />3 yellow — between 200%
                and 150% — normal
                <br /> 4-6 green — above 200% — normal mode
                <br />
                <br />
                You can be liquidated below 150%. In order to help avoid
                liquidation in Normal Mode and Recovery Mode, it is strongly
                recommended that users keep their individual collateral ratio
                significantly above 150%.
              </Card>
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Tcr;
