import React, { useCallback, useEffect } from "react";
import { Button, Flex } from "theme-ui";

import {
  Decimal,
  Decimalish,
  LiquidLoansStoreState,
} from "@liquidloans/lib-base";
import {
  LiquidLoansStoreUpdate,
  useLiquidLoansReducer,
  useLiquidLoansSelector,
} from "@liquidloans/lib-react";

import { COIN } from "../../strings";

import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";

import { StabilityDepositEditor } from "./StabilityDepositEditor";
import { StabilityDepositAction } from "./StabilityDepositAction";
import { useStabilityView } from "./context/StabilityViewContext";
import {
  selectForStabilityDepositChangeValidation,
  validateStabilityDepositChange,
} from "./validation/validateStabilityDepositChange";

const select = ({ stabilityDeposit }: LiquidLoansStoreState) => ({
  stabilityDeposit,
});

const init = ({ stabilityDeposit }: LiquidLoansStoreState) => ({
  originalDeposit: stabilityDeposit,
  editedUSDL: stabilityDeposit.currentUSDL,
  changePending: false,
});

type StabilityDepositManagerState = ReturnType<typeof init>;
type StabilityDepositManagerAction =
  | LiquidLoansStoreUpdate
  | { type: "startChange" | "finishChange" | "revert" }
  | { type: "setDeposit"; newValue: Decimalish };

const reduceWith =
  (action: StabilityDepositManagerAction) =>
  (state: StabilityDepositManagerState): StabilityDepositManagerState =>
    reduce(state, action);

const finishChange = reduceWith({ type: "finishChange" });
const revert = reduceWith({ type: "revert" });

const reduce = (
  state: StabilityDepositManagerState,
  action: StabilityDepositManagerAction
): StabilityDepositManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalDeposit, editedUSDL, changePending } = state;

  switch (action.type) {
    case "startChange": {
      console.log("changeStarted");
      return { ...state, changePending: true };
    }

    case "finishChange":
      return { ...state, changePending: false };

    case "setDeposit":
      return { ...state, editedUSDL: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedUSDL: originalDeposit.currentUSDL };

    case "updateStore": {
      const {
        stateChange: { stabilityDeposit: updatedDeposit },
      } = action;

      if (!updatedDeposit) {
        return state;
      }

      const newState = { ...state, originalDeposit: updatedDeposit };

      const changeCommitted =
        !updatedDeposit.bammPoolShare.eq(originalDeposit.bammPoolShare) ||
        updatedDeposit.poolShare.gt(originalDeposit.poolShare) ||
        !updatedDeposit.initialUSDL.eq(originalDeposit.initialUSDL) ||
        updatedDeposit.currentUSDL.gt(originalDeposit.currentUSDL) ||
        updatedDeposit.collateralGain.lt(originalDeposit.collateralGain) ||
        updatedDeposit.loanReward.lt(originalDeposit.loanReward) ||
        updatedDeposit.totalEthInBamm.lt(originalDeposit.totalEthInBamm) ||
        updatedDeposit.totalLusdInBamm.lt(originalDeposit.totalLusdInBamm);

      if (changePending && changeCommitted) {
        return finishChange(revert(newState));
      }

      return {
        ...newState,
        editedUSDL: updatedDeposit.apply(
          originalDeposit.whatChanged(editedUSDL)
        ),
      };
    }
  }
};

const transactionId = "stability-deposit";

type StabilityManagerProps = {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (value: string) => void;
};

export const StabilityDepositManager: React.FC<StabilityManagerProps> = ({
  tabs,
  currentTab,
  setCurrentTab,
}) => {
  const { stabilityDeposit } = useLiquidLoansSelector(select);
  const [{ originalDeposit, editedUSDL, changePending }, dispatch] =
    useLiquidLoansReducer(reduce, init);
  const validationContext = useLiquidLoansSelector(
    selectForStabilityDepositChangeValidation
  );
  const { dispatchEvent } = useStabilityView();

  const handleCancel = useCallback(() => {
    dispatchEvent("CANCEL_PRESSED");
  }, [dispatchEvent]);

  const [validChange, description] = validateStabilityDepositChange(
    stabilityDeposit,
    editedUSDL,
    validationContext,
    undefined,
    undefined
  );

  const makingNewDeposit = stabilityDeposit.isEmpty;

  const myTransactionState = useMyTransactionState(transactionId);

  useEffect(() => {
    if (
      myTransactionState.type === "waitingForApproval" ||
      myTransactionState.type === "waitingForConfirmation"
    ) {
      dispatch({ type: "startChange" });
    } else if (
      myTransactionState.type === "failed" ||
      myTransactionState.type === "cancelled"
    ) {
      dispatch({ type: "finishChange" });
    } else if (myTransactionState.type === "confirmedOneShot") {
      dispatch({ type: "finishChange" });
      dispatchEvent("DEPOSIT_CONFIRMED");
    }
  }, [myTransactionState.type, dispatch, dispatchEvent]);

  return (
    <StabilityDepositEditor
      originalDeposit={stabilityDeposit}
      editedUSD={editedUSDL}
      changePending={changePending}
      dispatch={dispatch}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
    >
      {/* {description ??
        (makingNewDeposit ? (
          <ActionDescription>
            Enter the amount of {COIN} you'd like to deposit.
          </ActionDescription>
        ) : (
          <ActionDescription>
            Adjust the {COIN} amount to deposit or withdraw.
          </ActionDescription>
        ))} */}

      <Flex variant="layout.actions">
        {/* <Button variant="cancel" onClick={handleCancel}>
          Cancel
        </Button> */}

        {validChange ? (
          <StabilityDepositAction
            transactionId={transactionId}
            change={validChange}
          >
            Confirm
          </StabilityDepositAction>
        ) : (
          <Button disabled>Confirm</Button>
        )}
      </Flex>
    </StabilityDepositEditor>
  );
};
