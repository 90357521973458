import { useStakingView } from "./context/StakingViewContext";
import { ReadOnlyStake } from "./ReadOnlyStake";
import { StakingManager } from "./StakingManager";
import { NoStake } from "./NoStake";
import { useState } from "react";

export const Staking: React.FC = () => {
  const { view } = useStakingView();
  const [currentTab, setCurrentTab] = useState("Stake");
  const tabs = ["Stake", "Unstake"];
  switch (view) {
    case "ACTIVE":
      // return <ReadOnlyStake />;
      return (
        <StakingManager
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );

    case "ADJUSTING":
      return (
        <StakingManager
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );

    case "NONE":
      // return <NoStake />;
      return (
        <StakingManager
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      );
  }
};
