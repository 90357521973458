import React from "react";
import { Container, Card, Box, Paragraph, Heading } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
import { LiquidationManager } from "../components/LiquidationManager";
import { RiskyVaults } from "../components/RiskyVaults";
import { InfoMessage } from "../components/InfoMessage";
import bgImage from "../assets/images/bg/liquidation.png";

export const RiskyVaultsPage: React.FC = () => (
  <Container
    variant="columns"
    sx={{
      justifyContent: "flex-start",
      backgroundImage: `url("/bg/liquidation.png")`,
    }}
  >
    <Container variant="center">
      <Heading
        sx={{ fontSize: ["28px", null, null, "36px"], px: ["20px", 0, 0, 0] }}
      >
        Liquidations
      </Heading>
      <Box
        sx={{
          height: "2px",
          width: ["86px", "100px", "118px", "128px"],
          mx: ["20px", 0, 0, 0],
          mt: "8px",
          mb: "12px",
          background:
            "linear-gradient(90.21deg, #3E59A1 2.18%, #5E227D 20.74%, #C20C6E 38.5%, #EDC730 58.68%, #95C156 79.67%)",
        }}
      />
      {/* <Card>
        <Card sx={{ p: [2, 3] }} variant="main_card">
          <InfoMessage title="Bot functionality">
            <Paragraph>
              Liquidation is expected to be carried out by bots.
            </Paragraph>
            <Paragraph>
              Early on you may be able to manually liquidate Vaults, but as the
              system matures this will become less likely.
            </Paragraph>
          </InfoMessage>
        </Card>
      </Card> */}
      {/* <LiquidationManager /> */}
      <RiskyVaults
      // pageSize={10}
      />
    </Container>

    <Container variant="bottom">
      <SystemStats />
    </Container>
  </Container>
);
