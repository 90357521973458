import { Button } from "theme-ui";

import { Decimal, VaultChange } from "@liquidloans/lib-base";

import { useLiquidLoans } from "../../hooks/LiquidLoansContext";
import { useTransactionFunction } from "../Transaction";

type VaultActionProps = {
  transactionId: string;
  change: Exclude<VaultChange<Decimal>, { type: "invalidCreation" }>;
  maxBorrowingRate: Decimal;
  borrowingFeeDecayToleranceMinutes: number;
};

export const VaultAction: React.FC<VaultActionProps> = ({
  children,
  transactionId,
  change,
  maxBorrowingRate,
  borrowingFeeDecayToleranceMinutes
}) => {
  const { liquidLoans } = useLiquidLoans();

  const [sendTransaction] = useTransactionFunction(
    transactionId,
    change.type === "creation"
      ? liquidLoans.send.openVault.bind(liquidLoans.send, change.params, {
          maxBorrowingRate,
          borrowingFeeDecayToleranceMinutes
        })
      : change.type === "closure"
      ? liquidLoans.send.closeVault.bind(liquidLoans.send)
      : liquidLoans.send.adjustVault.bind(liquidLoans.send, change.params, {
          maxBorrowingRate,
          borrowingFeeDecayToleranceMinutes
        })
  );

  return <Button onClick={sendTransaction}>{children}</Button>;
};
