import React, { ReactNode } from "react";
import { Box, Flex, Image, Text } from "theme-ui";
import BaristaLogo from "../assets/images/barista-logo.png";
import SafeLocker from "../assets/images/safe-locker.png";
import barista1 from "../assets/images/barista-1.png";
import barista2 from "../assets/images/barista-2.png";
import barista3 from "../assets/images/barista-3.png";
import YieldLogo from "../assets/images/yield-farming.png";
import WeighingBalance from "../assets/images/weighing-balance.png";

type Props = {
  children: ReactNode;
};

function StaticHomePage({ children }: Props) {
  return (
    <Box
      sx={{
        backgroundImage: `url("/bg/static-bg.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        minHeight: "100vh",
        minWidth: "100vw",
        position: "absolute",
        zIndex: 1,
      }}
    >
      <Flex
        sx={{
          minHeight: "100vh",
          justifyContent: "flex-start",
          alignItems: "center",
          background: "rgba(1, 0, 1, 0.58)",
          flexDirection: "column",
          // gap: "20px",
        }}
      >
        <Image
          src={BaristaLogo}
          sx={{
            width: "300px",
            height: "auto",
            mx: "auto",
            transition: "width 0.35s",
          }}
        />

        <Text sx={{ textAlign: "center", fontSize: "32px", fontWeight: 500 }}>
          Automated Rebalancing <br /> for Liquid Loans
        </Text>
        {children}
        <Flex
          sx={{
            flexDirection: ["column", null, null, "row"],
            mx: "auto",
            width: ["95%", null, null, "80%"],
            mb: "30px",
            mt: "40px",
            gap: ["30px", null, null, "10px"],
          }}
        >
          <Flex
            variant="cards.main_card"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              p: "20px",
              transition: "all",
              transitionDuration: "0.1s",
              ":hover": {
                transform: "scale(1.02)",
                translate: "0px -8px",
              },
            }}
          >
            <Image
              src={barista3}
              sx={{
                width: "50px",
                height: "50px",
                // mx: "auto",
                transition: "width 0.35s",
              }}
            />
            <Text sx={{ fontSize: "24px" }}>
              Stabilize Liquid Loans Protocol
            </Text>
            <Text sx={{ fontSize: "18px", textAlign: "center" }}>
              Barista and its novel Backstop AMM B.AMM automates the rebalancing
              of Liquid Loans Stability Pool to maintain its strength.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Flex>
          <Flex
            variant="cards.main_card"
            sx={{
              flexDirection: "column",
              gap: "20px",
              p: "20px",
              alignItems: "center",
              transition: "all",
              transitionDuration: "0.1s",
              ":hover": {
                transform: "scale(1.02)",
                translate: "0px -8px",
              },
            }}
          >
            <Image
              src={barista1}
              sx={{
                width: "50px",
                height: "50px",
                // mx: "auto",
                transition: "width 0.35s",
              }}
            />
            <Text sx={{ fontSize: "24px" }}>
              Earn Passive Yield on Your USOL
            </Text>
            <Text sx={{ fontSize: "18px", textAlign: "center" }}>
              By using Barista to deposit your USDL into the Liquid Loans
              Stability Pool, you can save the manual operation of selling your
              accumulated PLS back to USDL every time a liquidation is taking
              place.
            </Text>
          </Flex>
          <Flex
            variant="cards.main_card"
            sx={{
              flexDirection: "column",
              gap: "20px",
              p: "20px",
              alignItems: "center",
              transition: "all",
              transitionDuration: "0.1s",
              ":hover": {
                transform: "scale(1.02)",
                translate: "0px -8px",
              },
            }}
          >
            <Image
              src={barista2}
              sx={{
                width: "50px",
                height: "50px",
                // mx: "auto",
                transition: "width 0.35s",
              }}
            />
            <Text sx={{ fontSize: "24px" }}>Using Barista Protocol</Text>
            <Text sx={{ fontSize: "18px", textAlign: "center" }}>
              The integration of Liquid Loans is a step forward towards a more
              stablized DeFi ecosystem. Barista helps the Liquid Loans Stability Pool 
              to maintain it's strength.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default StaticHomePage;
